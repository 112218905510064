export const FETCH_TREEVIEW = "FETCH_TREEVIEW"
export const NODE_TREEVIEW = "NODE_TREEVIEW"
export const LIST_TREEVIEW = "LIST_TREEVIEW"
export const INSERT_LIST_TREEVIEW = "INSERT_LIST_TREEVIEW"
export const DELETE_LIST_TREEVIEW = "DELETE_LIST_TREEVIEW"
export const CLEAR_LIST_TREEVIEW = "CLEAR_LIST_TREEVIEW"
export const KEYS_LIST_TREEVIEW = "KEYS_LIST_TREEVIEW"
export const KEYS_TREEVIEW = "KEYS_TREEVIEW"
export const INSERT_KEYS_TREEVIEW = "INSERT_KEYS_TREEVIEW"
export const CHECKED_KEYS_TREEVIEW = "CHECK_KEYS_TREEVIEW"
export const EXPENDED_KEYS_TREEVIEW = "EXPENDED_KEYS_TREEVIEW"
export const INSERT_EXPENDED_KEYS_TREEVIEW = "INSERT_EXPENDED_KEYS_TREEVIEW"
export const SELECT_KEYS_TREEVIEW = "KEYS_TREEVIEW"