
export const FETCH_COMMON_CODE_LIST = 'FETCH_COMMON_CODE_LIST'

export const FETCH_COMMON_CODE_DETAIL = 'FETCH_COMMON_CODE_DETAIL'

export const FETCH_LANGUAGE = 'FETCH_LANGUAGE'

export const EDIT_DETAIL = 'EDIT_DETAIL'

export const CREATE_COMMON_CODE = 'CREATE_COMMON_CODE'

export const TOGGLE_DIALOG_COMMON_CODE = 'TOGGLE_DIALOG_COMMON_CODE'

export const SORT_ITEM = 'SORT_ITEM'

export const SET_TASK_LIST = 'SET_TASK_LIST'

export const CHANGE_SEARCH_CONDITION = 'CHANGE_SEARCH_CONDITION'

export const SET_CODE_TYPE = 'SET_CODE_TYPE'