export const SW_FETCH_SCHEDULE_CLASSIFICATION =
  "SW_FETCH_SCHEDULE_CLASSIFICATION";

export const SW_FETCH_DAY_OF_WEEK = "SW_FETCH_DAY_OF_WEEK";

export const SW_FETCH_START_DAY_OF_WEEK = "SW_FETCH_START_DAY_OF_WEEK";

export const SW_FETCH_COMPANY_ATTENDANCE_TIME =
  "SW_FETCH_COMPANY_ATTENDANCE_TIME";

export const SW_SETTING_CALENDAR_HEADER = "SW_SETTING_CALENDAR_HEADER";

export const SW_CHANGE_MONTH = "SW_CHANGE_MONTH";

export const SW_TOGGLE_DIALOG_REGISTER_SCHEDULE =
  "SW_TOGGLE_DIALOG_REGISTER_SCHEDULE";

export const SW_TOGGLE_DIALOG_SELECT_TASK = "SW_TOGGLE_DIALOG_SELECT_TASK";

export const SW_TOGGLE_DIALOG_VIEW_CHART = "SW_TOGGLE_DIALOG_VIEW_CHART";

export const SW_FETCH_LIST_TASK_TAB = "SW_FETCH_LIST_TASK_TAB";

export const SW_FETCH_LIST_TASK_ITEM = "SW_FETCH_LIST_TASK_ITEM";

export const SW_FETCH_PERFORMANCE_TYPE = "SW_FETCH_PERFORMANCE_TYPE";

export const SW_FETCH_JOB_DECRIPTION = "SW_FETCH_JOB_DECRIPTION";

export const SW_CREATE_NEW_SCHEDULE = "SW_CREATE_NEW_SCHEDULE";

export const SW_ADD_MORE_SCHEDULE_DETAIL = "SW_ADD_MORE_SCHEDULE_DETAIL";

export const SW_DELETE_SCHEDULE_DETAIL = "SW_DELETE_SCHEDULE_DETAIL";

export const SW_SET_SCHEDULE_SELECT_TASK_INDEX =
  "SW_SET_SCHEDULE_SELECT_TASK_INDEX";

export const SW_ON_CHANGE_SCHEDULE = "SW_ON_CHANGE_SCHEDULE";

export const SW_FETCH_SCHEDULE_DETAIL = "SW_FETCH_SCHEDULE_DETAIL";

export const SW_SAVE_SCHEDULE = "SW_SAVE_SCHEDULE";

export const SW_DELETE_SCHEDULE = "SW_DELETE_SCHEDULE";

export const SW_FETCH_LIST_SCHEDULE = "SW_FETCH_LIST_SCHEDULE";

export const SW_RESET_ERROR = "SW_RESET_ERROR";

export const SW_FETCH_DEPARTMENT_LIST = "SW_FETCH_DEPARTMENT_LIST";

export const SW_CHANGE_DEPARTMENT = "SW_CHANGE_DEPARTMENT";

export const SW_FETCH_LIST_SCHEDULE_BY_DEPARTMENT =
  "SW_FETCH_LIST_SCHEDULE_BY_DEPARTMENT";

export const SW_CHANGE_MONTH_BY_DEPT = "SW_CHANGE_MONTH_BY_DEPT";
