import * as types from './menuType'

const initialState = [];
const menuPeducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_MENU:
            state = action.menus;
            return [...state];
    
        default: return [...state]
    }
}

export default menuPeducer