import * as types from './treeCommonCodeType'
import { sortList } from './../../../util/commonCodeSevice';
import _ from 'lodash';
const initialState = {
    lstTreeFirstLevel: [],
    lstTreeSubLevel: [],
    lstLanguage: [],
    isShowDialog: false,
    commonCode: null,
    firstDetailCode: '',
    lastDetailCode: '',
    possibleYN: [],
    useYN: [],
    selectRowFirstLevel: {},
    selectRowSubLevel: {},
    selectDetailRow: {},
    isModeNew: true

};

const treeCodeDefault = {
    commonCode: '',
    codeName: '',
    nLocaleCode: '',
    lstCodeName: '',
    detailCode: '',
    useYn: 'Y',
    parentDetCode: null,
    treeId: '',
    treeIdHier: '',
    treeLevel: '',
    childExstYn: 'N',
    enableDeleteYn: 'Y',
    enableUpdateYn: 'Y',
    minOrderSeqFirstLevel: null,
    maxOrderSeqFirstLevel: null,
}
const treeCommonCodeReducer = (state = { ...initialState }, action) => {
    switch (action.type) {

        case types.FETCH_LANGUAGE: {
            state.lstLanguage = action.payload;
            return { ...state };
        }
        case types.FETCH_FIRST_LEVEL_TREE_COMMON_CODE: {
            let { lstTreeFirstLevel, detailCodeSwap } = action.payload;
            state.lstTreeFirstLevel = lstTreeFirstLevel;
            if (!_.isEmpty(lstTreeFirstLevel)) {
                state.minOrderSeqFirstLevel = lstTreeFirstLevel[0].orderSeq;
                state.maxOrderSeqFirstLevel = lstTreeFirstLevel[lstTreeFirstLevel.length - 1].orderSeq;
            }
            let {selectRowFirstLevel} = state;
            let  index = lstTreeFirstLevel.findIndex(element => element.detailCode == selectRowFirstLevel.detailCode);
            if (index !== -1) state.selectRowFirstLevel = lstTreeFirstLevel[index];
            return { ...state };
        }
        case types.FETCH_USER_POSSIBLE: {
            state.possibleYN = action.payload.lstUserPossiable;
            return { ...state };
        }
        case types.FETCH_USER_ABILITY: {
            state.useYN = action.payload.lstUserAbility;
            return { ...state };
        }
        case types.SET_SELECT_ROW: {
            if (action.payload.isFirstLevel) {
                state.selectRowFirstLevel = action.payload.selectDetailRow;
                state.selectRowSubLevel = {};
            } else {
                state.selectRowSubLevel = action.payload.selectDetailRow;
            }
            return { ...state };
        }
        case types.TOGGLE_DIALOG_TREE_CODE: {
            state.isShowDialog = !state.isShowDialog;
            state.isModeNew = action.payload.isModeNew;
            return { ...state };
        }

        case types.FETCH_TREE_COMMON_CODE_DETAIL: {
            let { treeDetail } = action.payload;
            let { lstCodeName } = treeDetail;
            let { lstLanguage } = state;
            let tmpLanguage = _.cloneDeep(lstLanguage);
            for (const language of tmpLanguage) {
                for (const codeName of lstCodeName) {
                    if (language.detailCode === codeName.langCode) {
                        language['checked'] = true;
                        language['value'] = codeName.codeName;
                        break;
                    }
                }
            }
            treeDetail.lstCodeName = tmpLanguage;
            state.selectDetailRow = treeDetail;
            return { ...state };
        }

        case types.FETCH_SUB_LEVEL_TREE_COMMON_CODE: {
            state.lstTreeSubLevel = action.payload.lstTreeSubLevel;
            let {selectRowSubLevel} = state;
            let  index = state.lstTreeSubLevel.findIndex(element => element.detailCode == selectRowSubLevel.detailCode);
            if (index !== -1) state.selectRowSubLevel = state.lstTreeSubLevel[index];
            state = _.cloneDeep(state);
            return { ...state };
        }

        case types.CREATE_TREE_COMMON_CODE: {
            let treeCodeDetail = _.cloneDeep(treeCodeDefault);
            let lstLanguage = _.cloneDeep(state.lstLanguage);
            lstLanguage.forEach(item => {
                item['checked'] = false;
                item['value'] = '';
            })
            treeCodeDetail.commonCode = state.commonCode;
            treeCodeDetail.lstCodeName = lstLanguage;
            if (action.payload.isFirstLevel) {
                treeCodeDetail.treeLevel = 1;
            } else {
                treeCodeDetail.treeLevel = state.selectRowSubLevel.treeLevel + 1;
                treeCodeDetail.parentDetCode = state.selectRowSubLevel.detailCode;
                treeCodeDetail.treeIdHier = (state.selectRowSubLevel.treeIdHier ? state.selectRowSubLevel.treeIdHier : state.selectRowSubLevel.treeId) + '-';

            }
            state.selectDetailRow = treeCodeDetail;
            return { ...state };
        }
        case types.ON_CHANGE_TREE_DETAIL: {
            state.selectDetailRow = action.payload.treeDetail;
            return { ...state };
        }
        case types.SET_SELECT_COMMON_CODE_OF_TREE: {
            let { lstLanguage, possibleYN, useYN } = state;
            state = { ...initialState }
            state.lstLanguage = lstLanguage;
            state.possibleYN = possibleYN;
            state.useYN = useYN;
            state.commonCode = action.payload.commonCode;
            return { ...state };
        }
        case types.DELETE_TREE_FIRST_LEVEL: {
            state.lstTreeSubLevel = [];
            state.selectRowFirstLevel = {};
            return { ...state };
        }       
         case types.DELETE_TREE_SUB_LEVEL: {
            state.selectRowSubLevel = {};
            return { ...state };
        }
        case types.SORT_TREE_FIRST_LEVEL: {
            let { lstTreeFirstLevel } = state;
            state.lstTreeFirstLevel = [...sortList(lstTreeFirstLevel, action.payload.column, action.payload.sortOrder)];
            return { ...state };
        }
        case types.REMOVE_ALL_WHEN_CHANGE_COMMON_CODE: {
            let { lstLanguage, possibleYN, useYN } = state;
            state = { ...initialState }
            state.lstLanguage = lstLanguage;
            state.possibleYN = possibleYN;
            state.useYN = useYN;
            return { ...state };
        }
        default: return { ...state };
    }
}
export default treeCommonCodeReducer;