import * as types from "./treeViewType"
import * as utilFun from '../../util/findIndexUtilKey'
import _ from 'lodash'

const initialSate = []
const listTreeViewReducer = (state = initialSate, action) => {
    let index = -1;
    switch (action.type) {
        case types.LIST_TREEVIEW:
            state = action.payload;
            return [...state]
        case types.INSERT_LIST_TREEVIEW: {
            const item = _.find(state, element => element.targetId1 === action.node.targetId1);
            return _.isEmpty(item) ? [action.node, ...state] : [...state];
        }
        case types.DELETE_LIST_TREEVIEW:
            index = utilFun.findIndexKey(state, action.id);
            state.splice(index, 1);
            return [...state] 
        case types.CLEAR_LIST_TREEVIEW:
            state = [];
            return [...state]
        default: return state
    }
}
export default listTreeViewReducer;