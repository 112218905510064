import i18next from 'i18next';
import _ from 'lodash';
import { filter} from 'lodash';
import apiService from '../../util/apiService';
import * as types from "./treeViewType";

export const actGetTreeViewRequest = (apiURL, method, body, treeType, treeSubType, selectedOranizationList, callBack, bool, isSearch) => {
    return (dispacth, getState) => {
        return apiService(apiURL, method, body).then(res => {
            if (bool) {
                selectedOranizationList = _.cloneDeep(getState().listTreeView);
            }
            if (isSearch === true) {
                res.data.data.organizationList.unshift({
                    parentOrganizationSeq: 0,
                    organizationSeq: 0,
                    organizationName: i18next.t('empGrid.optAll.label')
                });
            }

            const treeData = buildTree(res.data.data, treeType, treeSubType, selectedOranizationList, dispacth, bool);

            if (callBack) {
                callBack(treeData);
            }
            dispacth(actGetTreeView(treeData));

        })
    }
}
export const actGetTreeView = (treeview) => {
    return {
        type: types.FETCH_TREEVIEW,
        treeview
    }
}
export const actGetNodeTreeView = (node) => {
    return {
        type: types.NODE_TREEVIEW,
        node
    }
}
export const buildTree = (data, treeType, treeSubType, selectedOranizationList, dispacth, bool) => {
    let resultData = data;
    let list = resultData.organizationList;
    let employeeList = [];
    if (resultData.employeeList != undefined) {
        employeeList = resultData.employeeList;
    }

    let map = {};
    let map2 = {};
    let roots = [],
        i, j,
        node, nodeE;

    let keys = [];
    let mapKey = [];
    let mapSelectedLList = [];
    if (selectedOranizationList != undefined && selectedOranizationList.length > 0) {
        for (i = 0; i < selectedOranizationList.length; i++) {
            mapSelectedLList[selectedOranizationList[i].targetId1] = selectedOranizationList[i];
        }
    }

    //if (_.isEmpty(list)) return;
    if (list.length > 0) {
        for (i = 0; i < list.length; i += 1) {
            map2["OR" + "_" + list[i].organizationSeq] = i;
            map["OR" + "_" + list[i].organizationSeq] = i;
            //list[i]['key'] = list[i].parentOrganizationSeq;
            //list[i]['title'] = list[i].workplaceName;
            list[i].children = []
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            node['key'] = "OR" + "_" + node.organizationSeq;
            node['title'] = node.organizationName;
            node['organizationSeq'] = node.organizationSeq;
            node['targetKeyId'] = node.organizationSeq;
            node['targetId1'] = node.organizationSeq;
            node['targetId2'] = node.organizationSeq;
            node['targetName'] = node.organizationName;
            node['nodeType'] = "";
            node['targetType'] = "OR";
            if (mapSelectedLList[node.organizationSeq] != undefined) {
                node['checked'] = true;
                dispacth(actInsertListTreeView(node));
            }
            if (treeType === "TYPE5" && treeSubType === "EMPLOYEE") {
                node['checkable'] = false;
            }
            if (node.parentOrganizationSeq !== 0) {
                list[map["OR" + "_" + node.parentOrganizationSeq]].children.push(node);
            } else {
                roots.push(node)
            }

            let organizationEmployeeList = filter(employeeList, {departmentOrganizationSeq:node.organizationSeq});

            if (organizationEmployeeList.length > 0) {
                for (j = 0; j < organizationEmployeeList.length; j += 1) {
                    nodeE = organizationEmployeeList[j];
                    nodeE['checkable'] = false;
                    nodeE['key'] = "EM" + "_" + nodeE.employeeSeq;
                    nodeE['employeeSeq'] = nodeE.employeeSeq;
                    nodeE['employeeName'] = nodeE.employeeName;
                    nodeE['targetKeyId'] = nodeE.employeeSeq;
                    nodeE['targetId1'] = nodeE.employeeSeq;
                    nodeE['targetId2'] = nodeE.currentWorkRecordSeq;
                    nodeE['targetName'] = nodeE.employeeName + " " + nodeE.positionName + "(" + nodeE.roleName + ")";
                    nodeE['positionName'] = nodeE.positionName;
                    nodeE['roleName'] = nodeE.roleName;
                    nodeE['title'] = nodeE.employeeName + " " + nodeE.positionName + "(" + nodeE.roleName + ")";
                    nodeE['nodeType'] = "2";
                    nodeE['targetType'] = "EM";
                    if (mapKey["OR" + "_" + nodeE.departmentOrganizationSeq] === undefined) {
                        keys.push("OR" + "_" + nodeE.departmentOrganizationSeq);
                        mapKey["OR" + "_" + nodeE.departmentOrganizationSeq] = "OR" + "_" + nodeE.departmentOrganizationSeq;
                    }

                    if (nodeE.departmentOrganizationSeq !== 0) {
                        list[map["OR" + "_" + nodeE.departmentOrganizationSeq]].children.push(nodeE);

                    } else {
                        roots.push(nodeE)
                    }

                }

            }


        }

    }

 
    // if (employeeList.length > 0) {
    //     for (i = 0; i < employeeList.length; i += 1) {
    //         node = employeeList[i];
    //         node['checkable'] = false;
    //         node['key'] = "EM" + "_" + node.employeeSeq;
    //         node['employeeSeq'] = node.employeeSeq;
    //         node['employeeName'] = node.employeeName;
    //         node['targetKeyId'] = node.employeeSeq;
    //         node['targetId1'] = node.employeeSeq;
    //         node['targetId2'] = node.currentWorkRecordSeq;
    //         node['targetName'] = node.employeeName + " " + node.positionName + "(" + node.roleName + ")";
    //         node['positionName'] = node.positionName;
    //         node['roleName'] = node.roleName;
    //         node['title'] = node.employeeName + " " + node.positionName + "(" + node.roleName + ")";
    //         node['nodeType'] = "2";
    //         node['targetType'] = "EM";
    //         if (mapKey["OR" + "_" + node.departmentOrganizationSeq] === undefined) {
    //             keys.push("OR" + "_" + node.departmentOrganizationSeq);
    //             mapKey["OR" + "_" + node.departmentOrganizationSeq] = "OR" + "_" + node.departmentOrganizationSeq;
    //         }

    //         if (node.departmentOrganizationSeq !== 0) {
    //             list[map["OR" + "_" + node.departmentOrganizationSeq]].children.push(node);

    //         } else {
    //             roots.push(node)
    //         }

    //     }

    // }
    if (!bool) {
        keys = [];
    }
    dispacth(actKeysTreeView(keys));

    return roots;
}

export const actListTreeView = (payload) => {
    return {
        type: types.LIST_TREEVIEW,
        payload
    }
}
export const actInsertListTreeView = (node) => {
    return {
        type: types.INSERT_LIST_TREEVIEW,
        node
    }
}
export const actDeleteListTreeView = (id) => {
    return {
        type: types.DELETE_LIST_TREEVIEW,
        id
    }
}

export const actClearListTreeView = () => {
    return {
        type: types.CLEAR_LIST_TREEVIEW
    }
}

export const actKeysTreeView = (payload) => {
    return {
        type: types.KEYS_TREEVIEW,
        payload
    }
}
export const actInsertKeysTreeView = (payload) => {
    return {
        type: types.INSERT_KEYS_TREEVIEW,
        payload
    }
}
export const actcheckedKeysTreeView = (payload) => {
    return {
        type: types.CHECKED_KEYS_TREEVIEW,
        payload
    }
}
export const actSelectKeysTreeView = (payload) => {
    return {
        type: types.SELECT_KEYS_TREEVIEW,
        payload
    }
}
export const actExpendedKeysTreeView = (payload) => {
    return {
        type: types.EXPENDED_KEYS_TREEVIEW,
        payload
    }
}
export const actInsertExpendedKeysTreeView = (payload) => {
    return {
        type: types.INSERT_EXPENDED_KEYS_TREEVIEW,
        payload
    }
}