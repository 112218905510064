export const MN_FETCH_LIST_MENU = 'MN_FETCH_LIST_MENU';

export const MN_FETCH_MENU_DETAIL = 'MN_FETCH_MENU_DETAIL';

export const MN_CREATE_NEW_MENU = 'MN_CREATE_NEW_MENU';

export const MN_FETCH_USE_YN = 'MN_FETCH_USE_YN';

export const MN_FETCH_DISPLAY_YN = 'MN_FETCH_DISPLAY_YN';

export const MN_FETCH_LANGUAGE = 'MN_FETCH_LANGUAGE';

export const MN_EDIT_MENU_DETAIL = 'MN_EDIT_MENU_DETAIL';

