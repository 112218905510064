import * as types from "./tableRightType"
import * as utilFun from '../../util/findIndexUtil'

const initialSate = {}
const getDetailRightReducer = (state = initialSate, action) =>{
    switch(action.type){
        case types.GET_DETAIL_RIGHT: 
            return action.detail
        default: return state
    }
}
export default getDetailRightReducer;