import * as types from "./tableLeftType"

const initialSate = {
    totalCount: 0
}
const totalTableLeftReducer = (state = initialSate, action) => {
    switch (action.type) {
        case types.TOTAL_COUNT_GRID:
            state.totalCount = action.payload
            return { ...state }
        default: return state
    }
}
export default totalTableLeftReducer;