import i18next from 'i18next';
import _ from 'lodash';
import { PAGE_SIZE, PAGE_SIZES } from './../../../../util/config';
import * as types from './attendanceStatusActionTypes';

const currentDate = new Date();
const initialState = {
    isCheckAll: false,
    lstAttendanceState: [],
    lstCompany: [],
    searchOption: {
        statusGroupCode: null,
        organizationSeq: null,
        organizationName: null,
        startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        endDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
        rangeList: [],
        page: 0,
        pageSize: PAGE_SIZE
    },
    lastSearchOption: null,
    lstAttendance: [],
    headerStartDate: null,
    headerEndDate: null,
    isOpenModifyDialog: false,
    pageSizes: PAGE_SIZES,
    lstAttendanceDates: [],
    isEnableModifyButton: false,
}

const attendanceStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AT_FETCH_ATTENDANCE_STATE: {
            state.lstAttendanceState = action.payload.lstAttendanceState;
            state.lstAttendanceState.unshift({
                detailCode: '0',
                codeName: i18next.t('empGrid.optAll.label')
            })
            state.searchOption.statusGroupCode = '0';
            return _.cloneDeep(state);
        }

        case types.AT_FETCH_COMPANY_LIST: {
            let { lstCompany, isTeamAttendanceStatus } = action.payload;
            state.lstCompany = lstCompany;

            const defaultOrganization = _.find(lstCompany, element => element.organizationSeq == localStorage.getItem("organizationSeq"));
            let defaultDepartment;
            if (isTeamAttendanceStatus) {
                defaultDepartment = {
                    organizationSeq: localStorage.getItem("departmentSeq"),
                    organizationName: localStorage.getItem("departmentName"),
                }
            } else {
                defaultDepartment = defaultOrganization;
            }
            if (!_.isUndefined(defaultDepartment)) {
                const defaultRangeItem = {
                    targetType: "OR",
                    targetSeq: defaultDepartment.organizationSeq,
                    orderSeq: 1,
                    organizationName: defaultDepartment.organizationName
                }
                state.searchOption.rangeList = [defaultRangeItem];
            }
            state.searchOption.organizationSeq = defaultOrganization?.organizationSeq;
            state.searchOption.organizationName = defaultOrganization?.organizationName;
            return { ...state };
        }

        case types.AT_UPDATE_ATTENDANCE_REDUCER_STATE: {
            state = action.payload.newState;
            return _.cloneDeep(state);
        }

        case types.AT_CREATE_SEARCH_OPTION_DEFAULT: {
            state.isOpenModifyDialog = false;
            state.headerStartDate = state.searchOption.startDate;
            state.headerEndDate = state.searchOption.endDate;
            state.lstAttendance = [];
            return { ...state };
        }

        case types.AT_SEARCH_ATTENDANCE_STATUS: {
            state.headerStartDate = state.searchOption.startDate;
            state.headerEndDate = state.searchOption.endDate;
            state.isCheckAll = false;
            const { resultMap } = action.payload;
            if (!_.isEmpty(resultMap)) {
                const { lstAttendanceDates } = resultMap;
                state.lstAttendanceDates = lstAttendanceDates;
                state.lstAttendance = resultMap.lstEmployeeInfo;
            } else {
                state.lstAttendance = [];
                state.lstAttendanceDates = [];
            }
            const { searchOption } = state;
            searchOption.page = 0;
            return { ...state };
        }

        case types.AT_TOGGLE_MODIFY_DIALOG: {
            state.isOpenModifyDialog = !state.isOpenModifyDialog;
            return { ...state };
        }
        default:
            return { ...state };
    }
}

export default attendanceStatusReducer;
