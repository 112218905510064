import _ from "lodash";
import * as utilFun from '../../util/findIndexUtil';
import * as types from "./dataObserveTableType";

const initialSate = []
const dataObserveTableReducer = (state = initialSate, action) => {
    let { id, item, payload } = action;
    let index = -1;
    switch (action.type) {
        case types.FETCH_OBSERVE_TABLE:
            state = action.items
            return [...state]
        case types.INSERT_OBSERVE_TABLE:
            state.push(item)
            return [...state]
        case types.DELETE_OBSERVE_TABLE: {
            index = _.findIndex(state, element => element.key === id)
            state.splice(index, 1);
            return [...state]
        }
        case types.UPDATE_OBSERVE_TABLE:
            index = utilFun.findIndex(state, id);
            state[index] = item;
            return [...state];
        case types.MOVE_OBSERVE_TABLE:
            let f = state.splice(payload.from, 1)[0];
            // insert stored item into position `to`
            state.splice(payload.to, 0, f);
            return [...state];
        default: return state
    }
}
export default dataObserveTableReducer;