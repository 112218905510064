import Popover from "@material-ui/core/Popover";
import _ from "lodash";
import React, { Fragment } from "react";
import { Translation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import btn_gmail from "../../images/btn_gmail.png";
import icon_workup from "../../images/icon_workup.png";
import btn_service_request from "../../images/btn_service_request.png";
import btn_slack from "../../images/btn_slack.png";
// import poly from "../../images/ico_POLY.png";
import poly from "../../images/new_Poly_logo.png";
import avatar_default from "../../images/user_avatar-default.svg";
import apiService from "../../util/apiService";
import { maskPage, popupCenter, unMaskPage } from "../../util/jqueryFun";
import {
  actFetchLanguageRequest,
  actSetSelectedLanguage,
  actShowHideLogout,
  actTimeOutRedirectToLogin,
} from "./../../redux/authentication/authenticationAction";
import { actFetchMenus } from "./../../redux/menu/menuAction";
import { FILE_SERVER_URL, SLACK_URL } from "./../../util/config";
import { menuSetting, menuSettingBody } from "./../../util/menuUtil";
const LangugePage = (props) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMobile, setAnchorElMobile] = React.useState(null);

  const logout = () => {
    requestLogout();
  };

  const requestLogout = () => {
    maskPage();
    apiService("/authen/logout", "POST", null)
      .then((respone) => {})
      .catch(() => {})
      .finally(() => {
        localStorage.clear();
        history.push("/logout");
        props.fetchMenus([]);
        props.showHideLogout(false);
        props.timeOutRedirectToLogin();
        deleteAllCookies();
        unMaskPage();
      });
  };

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("authorization")) {
      props.fetchLanguage();
    }
    menuSettingBody();
    // eslint-disable-next-line
  }, []);

  const menuSettingClick = (e) => {
    menuSetting(e);
  };

  const getAvatarURL =
    !_.isUndefined(props.userInfo.lstAttachFile) &&
    !_.isUndefined(props.userInfo.lstAttachFile[0])
      ? `${FILE_SERVER_URL}${props.userInfo.lstAttachFile[0]?.filePath}${props.userInfo.lstAttachFile[0]?.serverFileName}`
      : avatar_default;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMobile = (event) => {
    setAnchorElMobile(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElMobile(null);
  };

  const open = Boolean(anchorEl);
  const openMobile = Boolean(anchorElMobile);
  const id = open ? "languge-popover" : undefined;
  const idMobile = openMobile ? "languge-popover" : undefined;
  const nameLan = (key) => {
    const lisLan = props.lstLanguage;
    let name = "";
    for (let i = 0; i < lisLan.length; i++) {
      if (key.toLowerCase() === lisLan[i].detailCode.toLowerCase()) {
        name = lisLan[i].codeName;
      }
    }
    return name;
  };

  const changeLanguage = (name) => {
    const langCode = name;
    i18n.changeLanguage(langCode.toLowerCase());
    props.setSelectedLanguage(langCode);
    localStorage.setItem("langCode", langCode);
    window.location.reload();
    handleClose();
  };

  const handleEmployeeName = (employeeName) => {
    if (_.includes(employeeName, "(")) {
      const names = employeeName.replace(")", "").split("(");
      return (
        <>
          <strong>{names[0]}</strong>
          <span>({names[1]})</span>
        </>
      );
    }
    return <strong>{employeeName}</strong>;
  };

  const openWorkup = () => {
    const authorization = localStorage.getItem("authorization");
    popupCenter({
      url: `https://po.edu-poly.com/isu/sso/ssoLogin?polyToken=${authorization}`,
      // url: `http://localhost:8080/isu/sso/ssoLogin?polyToken=${authorization}`,
      title: "Service request",
      w: 1024,
      h: 768,
    });
  };

  return (
    <Fragment>
      {!_.isEmpty(props.lstLanguage) && props.lstLanguage.length !== 0 ? (
        <div className="d-lg-none languge-select-mobile">
          <div className="languge-select">
            <div className="languge-wrap">
              <button
                aria-describedby={idMobile}
                className={open ? "open" : ""}
                onClick={handleClickMobile}
              >
                {!_.isEmpty(props.language) ? nameLan(props.language) : ""}
              </button>
              <Popover
                id={idMobile}
                open={openMobile}
                anchorEl={anchorElMobile}
                onClose={handleClose}
                className="menu-lan-poper"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ul className="menu-languge">
                  {props.lstLanguage?.map((element, index) => (
                    <li key={index}>
                      <button
                        className={
                          props.language === element.detailCode ? "active" : ""
                        }
                        onClick={() => changeLanguage(element.detailCode)}
                      >
                        {element.codeName}
                      </button>
                    </li>
                  ))}
                </ul>
              </Popover>
            </div>
          </div>
          <span
            onClick={(e) => menuSettingClick(e)}
            className="constrol-setting fas fa-ellipsis-v"
            data-id="menuSetting"
          ></span>
        </div>
      ) : null}
      <div id="menuSetting" className="list-nav-wrapper">
        <ul className="list-nav">
          <li className="first">
            <ul className="list-show">
              <li>
                <a onClick={openWorkup}>
                  <img
                    className="img-fluid"
                    src={icon_workup}
                    alt="WORK_UP"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: "40px",
                      margin: "8px 8px 0px 0px",
                    }}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://edu-poly.oneline.kr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="img-fluid" src={poly} alt="POLY" />
                </a>
              </li>
              <li>
                <a href={SLACK_URL} target="_blank" rel="noreferrer">
                  <img className="img-fluid" src={btn_slack} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://mail.google.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="img-fluid" src={btn_gmail} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://sr.koreapolyschool.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="img-fluid" src={btn_service_request} alt="" />
                </a>
              </li>
            </ul>
          </li>
          {!_.isEmpty(props.lstLanguage) && props.lstLanguage.length !== 0 ? (
            <li className="sm-hide-down">
              <div className="languge-select">
                <div className="languge-wrap">
                  <button
                    aria-describedby={id}
                    className={open ? "open" : ""}
                    onClick={handleClick}
                  >
                    {!_.isEmpty(props.language) ? nameLan(props.language) : ""}
                  </button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className="menu-lan-poper"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ul className="menu-languge">
                      {props.lstLanguage?.map((element, index) => (
                        <li key={index}>
                          <button
                            className={
                              props.language === element.detailCode
                                ? "active"
                                : ""
                            }
                            onClick={() => changeLanguage(element.detailCode)}
                          >
                            {element.codeName}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </Popover>
                </div>
              </div>
            </li>
          ) : null}
          {!_.isEmpty(props.userInfo) ? (
            <li>
              <span className="username">
                <span className="avatar">
                  <img src={getAvatarURL} alt="" />
                </span>
                {handleEmployeeName(props.userInfo?.employeeName)}
              </span>
            </li>
          ) : null}
          {history.location.pathname !== "/login" && (
            <li>
              {props.isShowLogOut ? (
                <span onClick={logout} className="btn-logout">
                  <i className="i-ico ico-logout"></i>{" "}
                  <Translation>
                    {(t) => <> {t("common.btn.logout.label")} </>}
                  </Translation>
                </span>
              ) : null}
            </li>
          )}
        </ul>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isShowLogOut: state.authentication.isShowLogOut,
    lstLanguage: state.authentication.lstLanguage,
    language: state.authentication.language,
    userInfo: state.authentication.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMenus: (menu) => {
      dispatch(actFetchMenus(menu));
    },
    showHideLogout: (isShowLogOut) => {
      dispatch(actShowHideLogout(isShowLogOut));
    },
    fetchLanguage: () => {
      dispatch(actFetchLanguageRequest());
    },
    setSelectedLanguage: (language) => {
      dispatch(actSetSelectedLanguage(language));
    },
    timeOutRedirectToLogin: () => {
      dispatch(actTimeOutRedirectToLogin());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LangugePage);
