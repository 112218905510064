import * as types from './DeptBusinessTypes'
import _ from 'lodash';
const initialState = {
    deptBusinessCooperationDetail : {}
}
export const deptBusinessCooperationReducer = (state = initialState,action)=>{
    switch(action.type){
        case types.GET_DEPT_BUSINESS_COOPERATION_DETAIL:
            state.deptBusinessCooperationDetail = _.cloneDeep(action.data);
            return{
                ...state
            }
        default: return state
    }
}
export default deptBusinessCooperationReducer;