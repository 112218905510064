import * as types from "./tableRightType"

const initialSate = {}
const getRowTableRightReducer = (state = initialSate, action) =>{
    switch(action.type){
        case types.GET_ROW_TABLE_RIGHT: 
            return action.row
        default: return state
    }
}
export default getRowTableRightReducer;