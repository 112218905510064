export const REPORT_TYPE = {
  WEEKLY: "WL",
  MONTHLY: "ML",
  DAILY: "DL",
};

export const REPORT_WORK_TYPE = {
  PERFORMANCE: "PE",
  PLAN: "PL",
};

export const TREE_ID = {
  DAILY_REPORT_FORM: "SC001",
  WEEKLY_REPORT_FORM: "SC002",
  MONTHLY_REPORT_FORM: "SC003",
};

export const REPORT_TYPE_MAP = {
  SC001: "DL",
  SC002: "WL",
  SC003: "ML",
};

export const APPROVAL_LINE_CLASSIFICATION = {
  AGREEMENT: "AG",
  APPROVAL: "AP",
};

export const FORM_FORMAT = {
  HORIZONTAL: "HO",
  VERTICAL: "VE",
};

export const APPROVAL_LINE_TYPE = {
  APPROVAL_AND_AGREEMENT_LINE_SEPARATION: "01",
  APPROVAL_AND_AGREEMENT_LINE_INTEGRATION: "02",
  ONLY_SHOW_APPROVAL_LINES: "03",
};

export const GENERAL_COMMON_CODE_TYPE = "CM";

export const TREE_COMMON_CODE_TYPE = "TR";

export const TASKS = "SY000005";

export const USER_POSSIBLE = "SY000006";

export const USER_AVAILIBILITY = "SY000004";

export const CODE_TYPE = "SY000002";

export const LANGUAGE = "SY000001";

export const BUSSINESS_PREIOD = "OR000007";

export const WEEK_DAY = "SY000003";

export const CALENDAR_START_DATE_WEEK_DATE = "SY000015";

export const DISPLAY_AVAILIBILITY = "SY000007";

export const PERFORMANCE_TYPE = "SC000001";

export const SCHEDULE_CLASSIFICATION = "SC000002";

export const EMPLOYEE_STATUS = "MB000004";

export const CONTRACT_STATUS = "OR000002";

export const CONTRACT_TYPE = "MB000016";

export const RECRUIMENT_NOTICE_REGISTRATION_TYPE = "MB000018";

export const POSITION_CODE = "MB000003";

export const ROLE_CODE = "MB000005";

export const VISA_TYPE = "MB000007";

export const LIVING_GBN = "MB000017";

export const NATION_GBN = "SY000009";

export const GENDER_GBN = "MB000001";

export const RECRUIMENT_CLASSIFICATION = "FO000001";

export const DRAFT_FORM_SEARCH_TYPE = "FO000023";

export const USER_FORM_APPROVAL_STATUS = "FO000024";

export const RECRUIMENT_PATH = "FO000002";

export const PROBATION_APPLY = "FO000003";

export const EXPLANATION_REASON = "FO000027";

export const APOLOGIZE_REASON = "FO000028";

export const Congratulations_Condolences = "FO000052";

export const FamilyEvent = "FO000011";

export const EXPENSE_BASIC_ITEM = "FO000009";

export const EXPENSE_PROOF_TYPE = "FO000010";

export const YEAR = "FO000013";

export const WARNING_TYPE = "FO000032";

export const ATTENDANCE_STATE = "FO000034";

export const BUSSINESS_TRIP_CLASSIFICATION = "FO000004";

export const PURPOSE_VEHICLE_USING = "FO000033";

export const WEEKDAY_FULLNAME = "SY000018";

export const APPROVAL_APPROVAL_STATUS = "FO000039";

export const POSITION_ANNUAL = "FO000005";

export const DISPLAY_REGISTER_CODE = "NT000001";

export const VEHICLE_USAGE_STATUS_SEARCH_DATE = "SY000019";

export const PAYMENT_STATUS = "FO000051";

//근무제 변경 신청서
export const CHANGE_SCHEDULE = "FO000053";
