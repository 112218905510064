
export const GET_LIST_PERMISSION_GROUPS = "GET_LIST_PERMISSION_GROUPS";
export const GET_LIST_MENUS_BY_PERMISSION = "GET_LIST_MENUS_BY_PERMISSION";
export const INSERT_PERMISSION_GROUP_SETTING = "INSERT_PERMISSION_GROUP_SETTING";
export const UPDATE_MENUCODE_BY_PERMISSION_GROUP = "UPDATE_MENUCODE_BY_PERMISSION_GROUP";
export const INSERT_PERMISSION_GROUP_DETAIL = "INSERT_PERMISSION_GROUP_DETAIL";
export const GET_PERMISSION_GROUP_BY_SEQ = "GET_PERMISSION_GROUP_BY_SEQ";
export const CHECK_EXISTED_PERMISION_GROUP = "CHECK_EXISTED_PERMISION_GROUP";
export const GET_PERMISION_GROUP_DETAIL = "GET_PERMISION_GROUP_DETAIL";
export const GET_LANGS = "GET_LANGS";
export const SAVE_PERMISSION_GROUP_DETAIL = "SAVE_PERMISSION_GROUP_DETAIL";
export const DELETE_PERMISSION_GROUP_DETAIL = "DELETE_PERMISSION_GROUP_DETAIL";
export const PAGING_LIST_PERMISSION_GROUP = "PAGING_LIST_PERMISSION_GROUP";
export const PAGING = "PAGING";
export const CHANGE_GPS_FORM_SEARCH_DATA = "CHANGE_GPS_FORM_SEARCH_DATA";