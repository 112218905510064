import { data, type } from 'jquery';
import * as types from './ExpenseAccSettingActionTypes';
import _ from "lodash"
const initialState = {
    expenseAccSettingDtl : {},
    totalCount :0,
    pageIndexSelection:0,
    isOpenModal: false,
    checkExpense:0,
    listCountries:[],
    listOrganizations:[],
    contextExpenseAccData : {}
}

const expenseAccSettingReducer = (state = initialState,action)=>{
    switch(action.type){
        case types.GET_EXPENSE_ACC_SETTING_DTL:
            return{
                ...state,
                expenseAccSettingDtl : action.data.expenseAccSettingDtl
            }
        case types.GET_LIST_EXPENSE_ACC_SETTINGS:
            return{
                ...state,
                listExpenseAccSetting : action.data.listExpenseAccSetting,
                totalCount: action.data.totalCount
            }
        case types.PAGING:
            return{
                ...state,
                pageIndexSelection: action.pageIndexSelection
            }
        case types.OPEN_MODAL:
            return{
                ...state,
                isOpenModal:action.isOpenModal
            }
        case types.CHECK_OPEN_EXPENSE:
            return{
                ...state,
                checkExpense:action.payload
            }
        case types.GET_LIST_COUNTRIES:
            state.listCountries=action.data.data;
            return{
                ...state,
            }
        case types.GET_LIST_ORGANIZATIONS:
            state.listOrganizations=action.data.listOrganizations;
            return{
                ...state,
            }
        case types.CHANGE_FORM_DATA_EXPENSE_ACC:
            state.contextExpenseAccData = _.cloneDeep(action.payload);
            return {
                ...state,
            }
        default : return state;
    }
}
export default expenseAccSettingReducer;