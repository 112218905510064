import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Zoom,
} from "@material-ui/core";
import $ from "jquery";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { Translation } from "react-i18next";
import icon_warning from "../../../images/icon_alert.svg";
import icon_alert_error from "../../../images/icon_alert_false.svg";
import icon_alert_success from "../../../images/icon_alert_true.svg";
import icon_confirm from "../../../images/ico_question.png";
import styles from "./ConfirmPopup.module.css";

const CONFIRM = "CONFIRM";
const WARNING = "WARNING";
const ALERT_SUCCESS = "ALERT_SUCCESS";
const ALERT_ERROR = "ALERT_ERROR";
const ALERT_NEXT = "ALERT_NEXT";
const ALERT_NEXT_STEP = "ALERT_NEXT_STEP";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom in={true} ref={ref} {...props} />;
});

class ConfirmPopup extends React.Component {
  handelContentMessage = (message, lastContentMessage) => {
    console.log(`message :: ${message}`);
    console.log(`lastContentMessage :: ${lastContentMessage}`);
    let listContent = _.split(message, "/n");
    let contentElement = [];
    listContent?.forEach((element, index) => {
      contentElement.push(
        <DialogContentText key={index}>
          {element} {index === listContent.length - 1 ? lastContentMessage : ""}
        </DialogContentText>
      );
    });
    return contentElement;
  };

  getIcon = () => {
    const { type, option } = this.props;
    switch (type) {
      case WARNING: {
        return icon_warning;
      }
      case CONFIRM: {
        return icon_confirm;
      }
      case ALERT_SUCCESS: {
        return icon_alert_success;
      }
      case ALERT_ERROR: {
        return icon_alert_error;
      }
      case ALERT_NEXT: {
        return icon_warning;
      }
      case ALERT_NEXT_STEP: {
        return icon_warning;
      }
      default:
        return;
    }
  };

  render() {
    const {
      type,
      proceedLabel,
      cancelLabel,
      title,
      contentMessage,
      extraContentMessage,
      show,
      proceed,
    } = this.props;
    let lastContentMessage = ` ${
      extraContentMessage ? extraContentMessage : ""
    } ${type === CONFIRM ? "?" : ""}`;
    let contentElement = (
      <Translation>
        {(t) => (
          <>
            {" "}
            {this.handelContentMessage(
              t(contentMessage),
              lastContentMessage
            )}{" "}
          </>
        )}
      </Translation>
    );
    const icon = this.getIcon();
    return (
      <Fragment>
        <Dialog
          className={`alert-wrap ALERT-${type}`}
          open={show}
          TransitionComponent={Transition}
          // onClose={() => proceed(false)}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle className="modal-header" className={styles.confirmTitle}>
            <Translation>{(t) => <> {t(title)} </>}</Translation>
            <button className="close" onClick={() => proceed(false)}>
              <Translation>
                {(t) => <> {t("common.btn.close.label")} </>}
              </Translation>
            </button>
          </DialogTitle>
          <DialogContent className={styles.confirmContent}>
            <div className="alert-group-icon text-center">
              <img src={icon} alt="" />
            </div>
            {/* <div className="alert-group-text text-center">{contentElement}</div> */}
            <div
              className="alert-group-text text-center"
              style={{ whiteSpace: "pre-line" }}
            >
              {contentElement}
            </div>
          </DialogContent>
          <DialogActions
            className={`justify-content-center ${styles.confirmControl}`}
          >
            <button
              autoFocus={true}
              className="btn btn-primarys wi-100"
              onClick={() => proceed(true)}
              onKeyPress={(e) => e.keyCode === 13 && proceed(true)}
              color="primary"
              variant="outlined"
              size="small"
            >
              <Translation>{(t) => <> {t(proceedLabel)} </>}</Translation>
            </button>
            {type === CONFIRM && (
              <button
                className="btn btn-default4 wi-100"
                onClick={() => proceed(false)}
                color="default"
                variant="outlined"
                size="small"
              >
                <Translation>{(t) => <> {t(cancelLabel)} </>}</Translation>
              </button>
            )}
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ConfirmPopup.propTypes = {
  type: PropTypes.string,
  okLabbel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  contentMessage: PropTypes.string,
  extraContentMessage: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  enableEscape: PropTypes.bool,
};

export function confirm(
  contentMessage,
  extraContentMessage,
  title,
  proceedLabel = "common.btn.yes.label",
  cancelLabel = "common.btn.no.label",
  options = {}
) {
  return createConfirmation(confirmable(ConfirmPopup))({
    type: CONFIRM,
    contentMessage,
    extraContentMessage,
    proceedLabel,
    cancelLabel,
    title,
    ...options,
  });
}

export function warning(
  contentMessage,
  extraContentMessage,
  title,
  proceedLabel = "common.btn.ok.label",
  options = {}
) {
  if (!_.isUndefined($(".ALERT-WARNING")[0])) return;
  return createConfirmation(confirmable(ConfirmPopup))({
    type: WARNING,
    contentMessage,
    extraContentMessage,
    proceedLabel,
    title,
    ...options,
  });
}

export function alertSuccess(
  contentMessage,
  extraContentMessage,
  title,
  proceedLabel = "common.btn.ok.label",
  options
) {
  if (
    !_.isUndefined($(".ALERT-ALERT_SUCCESS")[0]) ||
    !_.isUndefined($(".ALERT-ALERT_ERROR")[0])
  )
    return;
  return createConfirmation(confirmable(ConfirmPopup))({
    type: ALERT_SUCCESS,
    contentMessage,
    extraContentMessage,
    proceedLabel,
    title,
    ...options,
  });
}

export function alertError(
  contentMessage,
  extraContentMessage,
  title,
  proceedLabel = "common.btn.ok.label",
  options
) {
  if (
    !_.isUndefined($(".ALERT-ALERT_SUCCESS")[0]) ||
    !_.isUndefined($(".ALERT-ALERT_ERROR")[0])
  )
    return;
  return createConfirmation(confirmable(ConfirmPopup))({
    type: ALERT_ERROR,
    contentMessage,
    extraContentMessage,
    proceedLabel,
    title,
    ...options,
  });
}

export function alertNext(
  contentMessage,
  extraContentMessage,
  title,
  proceedLabel,
  options
) {
  return createConfirmation(confirmable(ConfirmPopup))({
    type: ALERT_SUCCESS,
    contentMessage,
    extraContentMessage,
    proceedLabel,
    title,
    ...options,
  });
}

export function alertNextStep(
  contentMessage,
  extraContentMessage,
  title,
  proceedLabel,
  options
) {
  return createConfirmation(confirmable(ConfirmPopup))({
    type: ALERT_NEXT_STEP,
    contentMessage,
    extraContentMessage,
    proceedLabel,
    title,
    ...options,
  });
}

// export function confirm(
//   contentMessage,
//   extraContentMessage,
//   title,
//   proceedLabel = "common.btn.yes.label",
//   cancelLabel = "common.btn.no.label",
//   options = {}
// ) {
//   return createConfirmation(confirmable(ConfirmPopup))({
//     type: CONFIRM,
//     contentMessage,
//     extraContentMessage,
//     proceedLabel,
//     cancelLabel,
//     title,
//     ...options,
//   });
// }
