import axios from 'axios'
import fileSaver from 'file-saver'
import i18next from 'i18next'
import _ from 'lodash'
import { alertError } from '../../components/common/popup/ConfirmPopup'
import apiService from '../../util/apiService'
import { getLanguageRequest } from '../../util/commonCodeSevice'
import { maskPage, unMaskPage } from '../../util/jqueryFun'
import { formatStringDate } from "./../../util/date"
import * as types from "./tableLeftType"

export const actGetDataTableLeftHoliday = (apiURL, params) => {
    return dispacth => {
        maskPage()
        return apiService(apiURL, "POST", params)
            .then(res => {
                dispacth(actGetDataTableLeft(res.data.data));
                dispacth(totalCountGrid(res.data.totalCount));
            })
            .finally(() => unMaskPage())
    }
}
export const actGetDataAuthGroupsRequest = (apiURL, params) => {
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res => {
            dispacth(actGetDataTableLeft(res.data.listPermissionGroups));
        })
    }
}
export const actGetDataTableLeftRequest = (apiURL, params) => {
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res => {
            dispacth(actGetDataTableLeft(res.data.data));
        })
    }
}
export const actGetDataTableLeft = (items) => {
    return {
        type: types.FETCH_TABLE_LEFT,
        items
    }
}
export const totalCountGrid = (payload) => {
    return {
        type: types.TOTAL_COUNT_GRID,
        payload
    }
}

export const actInsertDataTableLeftRequest = (item, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'POST', item).then(res => {
            dispacth(actInsertDataTableLeft(res.data.data));
        })
            .then(response => {
            })
            .catch(error => {
            })
    }

}
export const actInsertDataTableLeft = (item) => {
    return {
        type: types.INSERT_TABLE_LEFT,
        item
    }
}
export const actUpdateDataTableLeftRequest = (item, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, "PUT", item).then(res => {
            dispacth(actUpdateDataTableLeft(res.data));
        })
    }
}
export const actUpdateDataTableLeft = (item) => {
    return {
        type: types.UPDATE_TABLE_LEFT,
        item
    }
}
export const actDeleteDataTableLeftRequest = (id, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'DELETE', null).then(res => {
            dispacth(actDeleteDataTableLeft(id));
        })
    }
}
export const actDeleteDataTableLeft = (id) => {
    return {
        type: types.DELETE_TABLE_LEFT,
        id
    }
}
export const actGetRowTableLeftRequest = (rows, option) => {
    return dispacth => {
        const indexCon = (item) => item[option.nameID] === option.ID;
        let index = rows.findIndex(indexCon);///findRow.findIndexRows(rows, id);
        let row = rows[index];
        dispacth(actGetRowTableLeft(row));
    }
}
export const actGetRowTableLeft = (row) => {
    return {
        type: types.GET_ROW_TABLE_LEFT,
        row
    }
}
export const actGetDeTailLeftRequest = (apiURL, params, callBack, method) => {
    return dispacth => {
        let methodApi = "GET";
        let paramsApi = null;
        if (method) {
            methodApi = method;
        }
        if (params) {
            paramsApi = params;
        }
        if (!_.isUndefined(callBack)) {
            maskPage()
        }
        return apiService(apiURL, methodApi, paramsApi)
            .then(res => {
                const data = res.data.data;
                if (data && !_.isNull(data)) {
                    if (params) data.childExstYn = paramsApi.childExstYn;
                    dispacth(actGetDeTailLeft(data));
                    if (callBack) {
                        callBack(data);
                    }
                } else {
                    alertError('popup.message.getDataFail.label');
                }
            })
            .finally(() => unMaskPage())
    }
}
export const actGetDeTailLeft = (detail) => {
    return {
        type: types.GET_DETAIL_LEFT,
        detail
    }
}
export const actGetLangugeLeftRequest = (callback) => {
    return dispacth => {
        return getLanguageRequest().then(res => {
            let data = [...res.data.data];
            let lan = [];
            data.forEach(item => {
                const objLan = {
                    localeCode: 0,
                    langCode: item.detailCode,
                    checked: false,
                    codeName: ""
                }
                lan.push(objLan);
            })
            dispacth(actGetLangugeLeft(lan));
            if (callback) {
                callback();
            }
        })
    }
}
export const actGetLangugeLeft = (lan) => {
    return {
        type: types.GET_LANGUGE_LEFT,
        lan
    }
}
export const actExportExcelVehicle = (searchOption) => {
    const lables = {
        title: i18next.t("vehicleStt.title.label"),
        period: i18next.t("attStatus.period.label"),
        dateTimeOfUse: i18next.t("vehicleStt.dateTime.label"),
        start: i18next.t("vehicleStt.start.label"),
        end: i18next.t("vehicleStt.end.label"),
        vehicle: i18next.t("vehicleStt.vehicle.label"),
        mileage: i18next.t("vehicleStt.mileage.label"),
        purposeOfUse: i18next.t("vehicleStt.usePurpose.label"),
        vehicleUser: i18next.t("vehicleStt.vehicleUser.label"),
        state: i18next.t("vehicleStt.state.label"),
        afterUsing: i18next.t("vehicleStt.afterUsing.label"),
        company: i18next.t("attStatus.company.label"),
        createDate: i18next.t("vehicleStt.createDate.label"),
        cumulative: i18next.t("vehicleStt.cumulative.label"),
        onDeparture: i18next.t("vehicleStt.onDeparture.label"),
        arrival: i18next.t("vehicleStt.arrival.label"),
        usagePurposeDetails: i18next.t("vehicleStt.usagePurposeDetails.label"),
        notes: i18next.t("vehicleStt.notes.label"),
        reservation: i18next.t("vehicleStt.reservation.label"),
        titleForm: i18next.t("vehicleStt.titleForm.label"),
        purpose: i18next.t("vehicleStt.usePurpose.label"),
        vehicle: i18next.t("vehicleStt.vehicle.label"),
    }
    searchOption.startDate = searchOption.startDate;
    searchOption.endDate = searchOption.endDate;
    searchOption.createStartDate = searchOption.createStartDate;
    searchOption.createEndDate = searchOption.createEndDate;
    searchOption.organizationSeq = searchOption.organizationSeq;
    searchOption.vehicleName = searchOption.vehicleName;
    searchOption.purposeOfUse = searchOption.purposeOfUse;
    searchOption.vehicleUser = searchOption.vehicleUser;
    searchOption.data = searchOption.data;
    searchOption.type = searchOption.type;
    searchOption.companyName = searchOption.companyName;
    searchOption.vehicleName = searchOption.vehicleName;
    searchOption.purposeOfUse = searchOption.purposeOfUse;
    searchOption.vehicleNameExcel = searchOption.vehicleNameExcel;
    searchOption.purposeOfUseNameExcel = searchOption.purposeOfUseNameExcel;
    const startDate = formatStringDate(searchOption.startDate ? searchOption.startDate : searchOption.createStartDate, "-", true)
    const endDate = formatStringDate(searchOption.endDate ? searchOption.endDate : searchOption.createEndDate, "-", true)
    const fileName = `${i18next.t('vehicleStt.title.label')}_${startDate}_${endDate}.xlsx`
    let params = {
        lables,
        searchOption,
    }
    axios.post(`/vehicleStatus/exportExcel`, params, { responseType: 'blob' })
        .then(response => {
            if (response.data.size !== 0) {
                let contentDisposition = response.request.getResponseHeader('Content-Disposition')
                // fileSaver.saveAs(response.data, contentDisposition.split('=')[1]);
                fileSaver.saveAs(response.data, fileName);
            }
        })
        .catch(error => {
        })
}
export const actExportExcelContactList = (searchOption) => {
    const lables = {
        title: i18next.t("empGrid.titleExcel.label"),
        companyName: i18next.t("workspaceRegister.workspaceDetail.field.companyName.label"),
        departmentName: i18next.t("employeeRegister.employeeDetail.field.employeeDepart.label"),
        positionCode: i18next.t("employeeRegister.employeeDetail.field.employeePosition.label"),
        roleCode: i18next.t("employeeRegister.employeeDetail.field.employeeRole.label"),
        employeeName: i18next.t("empGrid.empName.label"),
        email: i18next.t("employeeRegister.employeeDetail.field.email.label"),
        birthdayYmd: i18next.t("employeeRegister.employeeDetail.field.employeeDoB.label"),
        companyPhone: i18next.t("employeeRegister.employeeDetail.field.CompanyPhoneNo.label"),
        homePhoneNo: i18next.t("empGrid.phoneNo.label"),
        telNo: i18next.t("employeeRegister.employeeDetail.field.employeePhoneNo.label"),
        address: i18next.t("myPage.detailInf.address.label")
    }
    searchOption.employeeSeq = searchOption.employeeSeq;
    searchOption.employeeName = searchOption.employeeName;
    searchOption.positionCode = searchOption.positionCode;
    searchOption.organizationSeq = searchOption.organizationSeq;
    searchOption.telNo = searchOption.telNo;
    searchOption.homePhoneNo = searchOption.homePhoneNo;
    searchOption.email = searchOption.email;
    searchOption.departmentOrganizationSeq = searchOption.departmentOrganizationSeq;
    searchOption.data = searchOption.data;
    searchOption.type = searchOption.type;
    const fileName = `${i18next.t('empGrid.titleExcel.label')}.xlsx`
    let params = {
        lables,
        searchOption,
    }
    axios.post(`/employee/exportExcelContactList`, params, { responseType: 'blob' })
        .then(response => {
            if (response.data.size !== 0) {
                let contentDisposition = response.request.getResponseHeader('Content-Disposition')
                // fileSaver.saveAs(response.data, contentDisposition.split('=')[1]);
                fileSaver.saveAs(response.data, fileName);
            }
        })
        .catch(error => {
        })
}