import { combineReducers } from 'redux'
import personalAttendanceStatusReducer from './myPage/personalAttendanceStatus/personalAttendanceStatusReducer'
import menuReducer from './menu/menuReducer'
import userLoginReducer from './userLogin/userLoginReducer'
import commonCodeReducer from './basicInformation/commonCode/commonCodeReducer'
import generalCommonCodeReducer from './basicInformation/generalCommonCode/generalCommonCodeReducer'
import treeCommonCodeReducer from './basicInformation/treeCommonCode/treeCommonCodeReducer'
import tableLeftReducer from './tableLeft/tableLeftReducer'
import totalTableLeftReducer from './tableLeft/totalTableLeftReducer'
import getRowTableLeftReducer from './tableLeft/getRowTableLeftReducer'
import getDetailLeftReducer from './tableLeft/getDetailLeftReducer'
import getLangugeLeftReducer from './tableLeft/getLangugeLeftReducer'
import tableRightReducer from './tableRight/tableRightReducer'
import getRowTableRightReducer from './tableRight/getRowTableRightReducer'
import getDetailRightReducer from './tableRight/getDetailRightReducer'
import paramsToSearchReducer from './paramsToSearch/paramsToSearchReducer'
import checkIsNewReducer from './checkIsNew/checkIsNewReducer'
import jobDescriptionReducer from './companyInfoManagement/jobDescriptionRegister/jobDescriptionReducer'
import getLangugeRightReducer from './tableRight/getLangugeRightReducer'
import groupPermissionSettingReducer from './permissionSetting/groupPermissionSetting/groupPermissionSettingReducer';
import treeViewReducer from './treeView/treeViewReducer'
import nodeTreeViewReducer from './treeView/nodeTreeViewReducer'
import listTreeViewReducer from './treeView/listTreeViewReducer'
import listKeysTreeViewReducer from './treeView/listKeysTreeViewReducer'
import menuManagementReducer from "./permissionSetting/menuManagement/menuManagementReducer";
import scheduleStatusViewReducer from "./schedule/scheduleManagement/scheduleStatusView/scheduleStatusViewReducer";
import dailyReportRegisterReducer from "./schedule/scheduleManagement/dailyReportRegister/dailyReportRegisterReducer";
import weeklyMonthlyReportRegisterReducer from "./schedule/scheduleManagement/weeklyMonthlyReportRegister/weeklyMonthlyReportRegisterReducer";
import reportStatusReducer from "./schedule/scheduleManagement/reportStatus/reportStatusReducer";
import generalFormReducer from './Approval/ApprovalRegistration/GeneralForm/GeneralFormReducer';
import expenseAccSettingReducer from './ExpenseAccSetting/ExpenseAccSettingReducer';
import corporateCardReducer from './CorporateradRegister/CorporateradRegisterReducer';
import asfReducer from './AttendanceSetting/AttendanceInforSetting/AttendanceInforSettingReducer';
import annualTypeSettingReducer from './AttendanceSetting/AnnualTypeSetting/AnnualTypeSettingReducer';
import annualLeaveCriteriSettingReducer from './AttendanceSetting/AnnualLeaveCriteriaSetting/AnnualLeaveCriteriaSettingReducer';
import dataApprovalTableReducer from './dataApprovalTable/dataApprovalTableReducer';
import dataObserveTableReducer from './dataObserveTable/dataObserveTableReducer';
import dataObserveListReducer from './dataObserveTable/dataObserveListReducer';
import approvalLineReducer from './Approval/ApprovalLineMngt/ApprovalLineRegistration/ApprovalLineRegistrationReducer';
import authenticationReducer from './authentication/authenticationReducer'
import homeReducer from './home/homeReducer'
import attendanceStatusReducer from './businessSupport/attendanceManagement/attendanceStatus/attendanceStatusReducer'
import attendanceStaticReducer from './businessSupport/attendanceManagement/attendanceStatic/attendanceStaticReducer'
import trainingReportReducer from './Approval/ApprovalRegistration/AttendanceForm/TrainingReport/TrainingReportReducer';
import overtimeReducer from './businessSupport/overtimeManagement/overtimeReducer'
import vehicleRequestReducer from './Approval/ApprovalRegistration/GeneralForm/VehicleRequest/VehicleRequesReducer';
import businessTripReducer from './Approval/ApprovalRegistration/AttendanceForm/BusinessTrip/businessTripReducer';
import corporateCardStatisticsReducer from './businessSupport/corporateCardManagement/corporateCardStatistics/corporateCardStatisticsReducer'
import trainingRequestReducer from './Approval/ApprovalRegistration/AttendanceForm/TrainingRequest/TrainingRequestReducer';
import resignationRequestReducer from './Approval/ApprovalRegistration/HRForm/ResignationRequest/ResignationRequestReducer'
import resignationDecisionReducer from './Approval/ApprovalRegistration/HRForm/ResignationDecision/ResignationDecisionReducer'
import deptBusinessCooperationReducer from './Approval/ApprovalRegistration/GeneralForm/DeptBusiness/DeptBusinessReducer';
import personalAnnualConfirmReducer from './myPage/personalAnnualConfirm/personalAnnualConfirmReducer';
import noticeFormReducer from './businessSupport/noticeBoxManagement/noticeForm/noticeFormReducer';
import teamOvertimeReducer from './businessSupport/teamOvertimeManagement/teamOvertimeReducer'
const appReducer = combineReducers({
    noticeForm: noticeFormReducer,
    attendanceStatic: attendanceStaticReducer,
    personalAttendanceStatus: personalAttendanceStatusReducer,
    menus: menuReducer,
    userLogin: userLoginReducer,
    commonCodes: commonCodeReducer,
    generalCodes: generalCommonCodeReducer,
    treeCodes: treeCommonCodeReducer,
    menuManagements: menuManagementReducer,
    jobDescriptions: jobDescriptionReducer,
    scheduleStatusView: scheduleStatusViewReducer,
    dailyReportRegister: dailyReportRegisterReducer,
    weeklyMonthlyReportRegister: weeklyMonthlyReportRegisterReducer,
    reportStatus: reportStatusReducer,
    tableLeft: tableLeftReducer,
    totalTable: totalTableLeftReducer,
    getLangugeLeft: getLangugeLeftReducer,
    getRowTableLeft: getRowTableLeftReducer,
    detailLeft: getDetailLeftReducer,
    tableRight: tableRightReducer,
    getRowTableRight: getRowTableRightReducer,
    detailRight: getDetailRightReducer,
    getLangugeRight: getLangugeRightReducer,
    getParams: paramsToSearchReducer,
    checkTypes: checkIsNewReducer,
    groupPermissionSettingData: groupPermissionSettingReducer,
    treeview: treeViewReducer,
    nodeTreeView: nodeTreeViewReducer,
    listTreeView: listTreeViewReducer,
    keysTreeView: listKeysTreeViewReducer,
    generalForm: generalFormReducer,
    expenseAccSettingData: expenseAccSettingReducer,
    corporateCardData: corporateCardReducer,
    attendanceInforSettingData: asfReducer,
    annualTypeSettingData: annualTypeSettingReducer,
    annualLeaveCriteriSettingData: annualLeaveCriteriSettingReducer,
    observeTable: dataObserveTableReducer,
    observeList: dataObserveListReducer,
    approvalTable: dataApprovalTableReducer,
    approvalLineData: approvalLineReducer,
    authentication: authenticationReducer,
    home: homeReducer,
    attendanceStatus: attendanceStatusReducer,
    trainingReportData: trainingReportReducer,
    overtime: overtimeReducer,
    teamOvertime: teamOvertimeReducer,
    vehicleRequestData: vehicleRequestReducer,
    businessTrip: businessTripReducer,
    corporateCardStatisticsData: corporateCardStatisticsReducer,
    trainingRequestData: trainingRequestReducer,
    resignationRequestData: resignationRequestReducer,
    resignationDecisionData: resignationDecisionReducer,
    deptBusinessCooperationData: deptBusinessCooperationReducer,
    personalAnnualConfirm: personalAnnualConfirmReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer