import _ from 'lodash';
import * as types from './noticeFormActionTypes';

const initialState = {
    lstDisplayRegisterCode: [],
    lstUserAvailibility: [],
}

const noticeFormReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.NTF_FETCH_DISPLAY_REGISTER_CODE: {
            state.lstDisplayRegisterCode = action.payload.lstDisplayRegisterCode;
            return { ...state };
        }
        case types.NTF_FETCH_USER_AVAILIBILITY: {
            state.lstUserAvailibility = action.payload.lstUserAvailibility;
            return { ...state };
        }

        default:
            return { ...state };
    }
}

export default noticeFormReducer;
