import * as types from "./checkIsNewType"

const initialSate = {
    checkIsNew: false,
    openDetail: false,
    checkDisable: true,
    checkGrap: true,
    socialId: "",
    staffName: "",
    parentOrganizationCode: "",
    setApprovalLineName: "",
    setApprovalLineNameError: false,
    setSearchSelect: 0,
    vehicleStatus: "",
}
const checkIsNewReducer = (state = initialSate, action) => {
    switch (action.type) {
        case types.CHECK_IS_NEW:
            return {
                ...state,
                checkIsNew: action.payload
            }
        case types.CHECK_OPEN:
            return {
                ...state,
                openDetail: action.payload
            }
        case types.CHECK_DISABLE:
            return {
                ...state,
                checkDisable: action.payload
            }
        case types.SET_SOCIAL:
            return {
                ...state,
                socialId: action.payload
            }
        case types.SET_STAFFNAME:
            return {
                ...state,
                staffName: action.payload
            }
        case types.SET_PARENT_ORG:
            return {
                ...state,
                parentOrganizationCode: action.payload
            }
        case types.SET_APPROVALINE_NAME:
            return {
                ...state,
                setApprovalLineName: action.payload
            }
        case types.SET_APPROVALINE_NAME_ERROR:
            return {
                ...state,
                setApprovalLineNameError: action.payload
            }
        case types.SET_SEARCH_SELECT:
            return {
                ...state,
                setSearchSelect: action.payload
            }
        case types.SET_VEHICLE_STATUS:
            return {
                ...state,
                vehicleStatus: action.payload
            }
        case types.CHECK_GRAP:
            return {
                ...state,
                checkGrap: action.payload
            }
        default: return state
    }
}
export default checkIsNewReducer;