import * as types from "./dataObserveTableType"

const initialSate = []
const dataObserveListReducer = (state = initialSate, action) => {
    switch (action.type) {
        case types.LIST_OBSERVE_TABLE:
            state = action.payload
            return [...state]
        default: return state
    }
}
export default dataObserveListReducer;