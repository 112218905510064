import * as types from "./homeActionTypes";

const initialState = {
  pageSize: 15,
  page: 0,
  lstApproval: [],
  lstNotice: [],
  workUpResponse: [],
  errorCode: "",
};
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HM_FETCH_LIST_APPOVAL: {
      state.lstApproval = action.payload.listApproval;
      return { ...state };
    }
    case types.HM_FETCH_LIST_NOTICE: {
      state.lstNotice = action.payload.lstNotice;
      return { ...state };
    }
    case types.HM_FETCH_WORK_UP_RESPONSE: {
      state.workUpResponse = action.payload;
      return { ...state };
    }
    default:
      return { ...state };
  }
};

export default homeReducer;
