import * as types from "./tableLeftType"

const initialSate = []
const getLangugeLeftReducer = (state = initialSate, action) =>{
    switch(action.type){
        case types.GET_LANGUGE_LEFT: 
            return [...action.lan]
        default: return state
    }
}
export default getLangugeLeftReducer;