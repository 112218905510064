
export const BT_FETCH_BUSSINESS_TRIP_CLASSIFICATION = 'BT_FETCH_BUSSINESS_TRIP_CLASSIFICATION'

export const BT_ON_CREATE_BUSSINESS_TRIP_RQUEST_FORM = 'BT_ON_CREATE_BUSSINESS_TRIP_RQUEST_FORM'

export const BT_ON_CREATE_BUSSINESS_TRIP_REQUEST_RESULT = 'BT_ON_CREATE_BUSSINESS_TRIP_REQUEST_RESULT'

export const BT_UPDATE_BUSSINESS_TRIP_DATA_ON_STATE = 'BT_UPDATE_BUSSINESS_TRIP_DATA_ON_STATE'

export const BT_FETCH_BUSSINESS_TRIP_REQUEST_FORM = 'BT_FETCH_BUSSINESS_TRIP_REQUEST_FORM'

export const BT_FETCH_LIST_BUSSINESS_TRIP_REQUEST_FORM = 'BT_FETCH_LIST_BUSSINESS_TRIP_REQUEST_FORM'

export const BT_FETCH_BUSSINESS_TRIP_RESULT_FORM_DETAIL = 'BT_FETCH_BUSSINESS_TRIP_RESULT_FORM_DETAIL'