import * as types from './generalCommonCodeType'
import { sortList } from './../../../util/commonCodeSevice';
import _ from 'lodash'
const initialState = {
    lstGeneralCommonCode: [],
    generalCodeDetail: {},
    lstLanguage: [],
    isShowDialog: false,
    commonCode: null,
    firstDetailCode: '',
    lastDetailCode: ''
};

const generalCodeDefault = {
    codeName: '',
    commonCode: '',
    enableDeleteYn: 'Y',
    enableUpdateYn: 'Y',
    lstCodeName: [],
    nLocaleCode: '',
    useYn: 'Y'
}
const generalCommonCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_GENERAL_COMMON_CODE_LIST: {
            let lstGeneralCommonCode = action.payload.lstGeneralCommonCode;
            state.lstGeneralCommonCode = lstGeneralCommonCode;
            if (lstGeneralCommonCode.length !== 0) {
                state.firstDetailCode = action.payload.lstGeneralCommonCode[0].detailCode;
                state.lastDetailCode = action.payload.lstGeneralCommonCode[lstGeneralCommonCode.length - 1].detailCode;
            }
            return { ...state };
        }
        case types.FETCH_LANGUAGE: {
            state.lstLanguage = action.payload;
            return { ...state };
        }
        case types.FETCH_GENERAL_COMMON_CODE_DETAIL: {
            let { generalCodeDetail } = action.payload;
            let { lstCodeName } = generalCodeDetail;
            let tmpLanguage = _.cloneDeep(state.lstLanguage);
            for (const language of tmpLanguage) {
                for (const codeName of lstCodeName) {
                    if (language.detailCode === codeName.langCode) {
                        language['checked'] = true;
                        language['value'] = codeName.codeName;
                        break;
                    }
                }
            }
            generalCodeDetail.lstCodeName = tmpLanguage;
            state.generalCodeDetail = generalCodeDetail;
            return { ...state };
        }
        case types.TOGGLE_DIALOG_GENERAL_CODE: {
            state.isShowDialog = !state.isShowDialog;
            return { ...state };
        }
        case types.CREATE_GENERAL_COMMON_CODE: {
            let generalCodeDetail = _.cloneDeep(generalCodeDefault);
            let lstLanguage = _.cloneDeep(state.lstLanguage);
            lstLanguage.forEach(item => {
                item['checked'] = false;
                item['value'] = '';
            })
            generalCodeDetail.commonCode = state.commonCode;
            generalCodeDetail.lstCodeName = lstLanguage;
            state.generalCodeDetail = generalCodeDetail;
            return { ...state };
        }

        case types.ON_CHANGE_GENERAL_DETAIL: {
            state.generalCodeDetail = action.payload.generalDetail;
            return { ...state };
        }
        case types.SET_SELECT_COMMON_CODE: {
            state.commonCode = action.payload.commonCode;
            return { ...state };
        }

        case types.SORT_GENERAL: {
            let { lstGeneralCommonCode } = state;
            state.lstGeneralCommonCode = [...sortList(lstGeneralCommonCode, action.payload.column, action.payload.sortOrder)];
            return { ...state };
        }

        case types.REMOVE_ALL_FROM_LIST_GENERAL_CODE: {
            let { lstLanguage } = state;
            state = initialState;
            state.lstLanguage = lstLanguage;
            return { ...state };
        }

        default: return { ...state };
    }
}
export default generalCommonCodeReducer;