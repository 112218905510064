import * as types from './ApprovalLineRegistrationTypes';
import _ from 'lodash';

const initialState = {
    listApprovalLines:[],
    totalCount: 0,
    isOpenModal : false,
    pageIndexSelection : 0,
    startIndex : 0
}
const approvalLineReducer = (state = initialState,action)=>{
    switch(action.type){
        case types.GET_LIST_APPROVALLINE:
            return{
                ...state,
                listApprovalLines : action.data.listApprovalLines,
                totalCount : action.data.totalCount
            }
        case types.OPEN_APPROVAL_LINE_MODAL:
            return{
                ...state,
                isOpenModal : action.payload
            }
        case types.PAGING:
            return{
                ...state,
                pageIndexSelection : action.pageIndexSelection,
                startIndex :action.startIndex
            }
            default: return state
    }
}
export default approvalLineReducer;