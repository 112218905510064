export const AU_SHOW_HIDE_LOGOUT = "AU_SHOW_HIDE_LOGOUT";

export const AU_FETCH_LANGUAGE = "AU_FETCH_LANGUAGE";

export const AU_SET_SELECTETD_LANGUAGE = "AU_SET_SELECTETD_LANGUAGE";

export const AU_TIME_OUT_REDIRECT_TO_LOGIN = "AU_TIME_OUT_REDIRECT_TO_LOGIN";

export const AU_SET_USER_INFO_AFTER_LOGIN = "AU_SET_USER_INFO_AFTER_LOGIN";

export const AU_GET_USER_INFO = "AU_GET_USER_INFO";

export const AU_UPDATE_PASSWORD_WAS_CHANGED = "AU_UPDATE_PASSWORD_WAS_CHANGED";

export const FIND_USER_PASSWORD = "FIND_USER_PASSWORD";
