import i18next from "i18next";
import _ from "lodash";

export const formatDate = (date, separator = '', isDisplay = false) => {
    if (!isValidDate(date)) return null;
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let orderDate = [year, month, day];
    if (isDisplay)
        orderDate = i18next.language === 'kr' ? [year, month, day] : [month, day, year];
    return orderDate.join(separator);
}

export const formatFullDate = (date, separator = '', isDisplay = false, isIncludeSeconds = false) => {
    if (!isValidDate(date)) return null;
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours(),
        min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
        seconds = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    const timeArr = [hours, min];
    if (isIncludeSeconds) {
        timeArr.push(seconds);
    }
    let orderDate = [year, month, day];
    if (isDisplay)
        orderDate = i18next.language === 'kr' ? [year, month, day] : [month, day, year];
    return `${orderDate.join(separator)} ${timeArr.join(':')}`;
}

export const formatDateToWeekday = date => {
    if (!isValidDate(date))
        date = stringToDate(date);
    switch (date.getDay()) {
        case 0: { date = 'SU'; break; }
        case 1: { date = 'MO'; break; }
        case 2: { date = 'TU'; break; }
        case 3: { date = 'WE'; break; }
        case 4: { date = 'TH'; break; }
        case 5: { date = 'FR'; break; }
        case 6: { date = 'SA'; break; }
        default: { date = '' }
    }
    return date;
}

export const formatDateToWeekdayMultiLanguage = date => {
    if (!isValidDate(date))
        date = stringToDate(date);
    const currentLanguage = i18next.language;
    switch (date.getDay()) {
        case 0: { return currentLanguage === 'en' ? 'SUN' : '일' }
        case 1: { return currentLanguage === 'en' ? 'MON' : '월' }
        case 2: { return currentLanguage === 'en' ? 'TUE' : '화' }
        case 3: { return currentLanguage === 'en' ? 'WED' : '수' }
        case 4: { return currentLanguage === 'en' ? 'THU' : '목' }
        case 5: { return currentLanguage === 'en' ? 'FRI' : '금' }
        case 6: { return currentLanguage === 'en' ? 'SAT' : '토' }
        default: { return '' }
    }
}

export const formatStringDate = (stringDate, separator, isDisplay = false) => {
    if (_.isEmpty(stringDate) || _.isNull(stringDate)) return null;
    let orderDate = [];
    if (stringDate?.toString().length === 8) {
        const year = stringDate.substring(0, 4);
        const month = stringDate.substring(4, 6);
        const day = stringDate.substring(6, 8);
        orderDate = [year, month, day];
        if (isDisplay)
            orderDate = i18next.language === 'kr' ? [year, month, day] : [month, day, year];
    }
    return orderDate.join(separator);
}

export const changeMonth = (date, month) => {
    return date.setMonth(date.getMonth() + month);
}

export const stringToDate = (dateString, hours = 0, mins = 0) => {
    if (_.isEmpty(dateString) || _.isNull(dateString)) return null;
    let result = dateString;
    if (dateString.toString().length === 8) {
        dateString = dateString.toString();
        let year = dateString.substring(0, 4);
        let month = dateString.substring(4, 6);
        let day = dateString.substring(6, 8);
        result = new Date(year, month - 1, day, hours, mins);
    }

    return result
}

export const checkWeekends = date => {
    date = stringToDate(date);
    return date.getDay() === 0 || date.getDay() === 6;
}

export const getCurrentHour = () => {
    let d = new Date();
    return d.getHours();
}

export const getDateAtStartOfMonth = () => {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), 1);
}

export const getDateAtEndOfMonth = () => {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
}


export const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
}

export const hanldeCreateDate = (strDate, isIncludeSeconds = false) => {
    if (_.isNull(strDate) || _.isEmpty(strDate)) return null;
    let date = new Date(strDate);
    if (!isValidDate(date)) {
        strDate = strDate.replace(/[-.]/g, '/');
    }
    date = new Date(strDate);
    return formatFullDate(date, '-', true, isIncludeSeconds);
}