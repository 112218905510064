import * as types from './GeneralFormType';
import { find, filter, cloneDeep, maxBy } from 'lodash';

const initialState = {
    mode: '',
    page: '',
    systemFormSeqCurrent: 0,
    formTypeCurrent: '',
    treeCommonFormType: [],
    formSetting: {},
    defaultObserverList: [],
    defaultApprovalList: [],
    permissionModifyList: [],
    classificationList: [],
    storageYearList: [],
    priorityList: [],
    companyLogoFile: {},
    employeeSeq: 0,
    submitClickCount: 0,
    tempSaveClickCount: 0,
    userFormBasicInfo: {
        formTitle: '',
        userFormSeq: 0,
        systemFormSeq: 0,
        attachFileCode: 0,
        priorityCode: 0,
        storageYearCode: 0,
        expireStorageDate: '',
        documentNo: '',
        createDate: '',
        companyName: '',
        organizationName: '',
        classificationCode: '',
        creatorName: '',
        creatorWorkRecordSeq: 0,
        approvalStatus: '',
        recentApprovalEmployeeSeq: 0,
        lastApprovalEmployeeSeq: 0,
        rootFormSeq: 0,
        parentFormSeq: 0,
        formConnectSeq: 0
    },
    // applicantUserDetail: {
    //     targetSeq: 0,
    //     employeeName: '',
    //     employeeNameEng: '',
    //     employeeOrganizationName: '',
    //     employeePosition: '',
    //     employeeRole: '',
    //     employeeWorkRecordSeq: 0
    // },
    userFormApplicants: [],
    userFormObservers: [],
    userFormApprovals: [],
    userFormFiles: [],
    userFormRefDocs: [],
    userFormComments: [],
    userFormReCreateHistory: []
};

const generalFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_FORM_SETTING: {
            console.log('UPDATE_FORM_SETTING')
            let formTypeCurrent = find(action.data.treeCommonFormType, {'detailCode': action.data.formSetting.formTypeLv2});
            formTypeCurrent = formTypeCurrent === undefined ? '':formTypeCurrent.treeId;

            let userFormApprovals = action.data.defaultApprovalList;
            if(action.data.formSetting.approvalLineType === '03') {
                userFormApprovals = filter(action.data.defaultApprovalList, {approvalGbn: 'AP'})
            }
            
            return {
                ...state,
                mode: 'create',
                page: 'create',
                systemFormSeqCurrent: action.data.systemFormSeqCurrent,
                formTypeCurrent: formTypeCurrent,
                treeCommonFormType: action.data.treeCommonFormType,
                formSetting: action.data.formSetting,
                defaultObserverList: action.data.defaultObserverList,
                defaultApprovalList: action.data.defaultApprovalList,
                permissionModifyList: [],
                storageYearList: action.data.storageYear,
                priorityList: action.data.priority,
                classificationList: action.data.classification,
                companyLogoFile: action.data.companyLogoFile,
                employeeSeq: action.data.employeeSeq,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    userFormSeq: 0,
                    systemFormSeq: action.data.systemFormSeqCurrent,
                    attachFileCode: 0,
                    priorityCode: action.data.formSetting.priorityCode,
                    storageYearCode: action.data.formSetting.storageYearCode,
                    expireStorageDate: '',
                    documentNo: action.data.userFormBasicInfo.documentNo,
                    createDate: action.data.userFormBasicInfo.createDate,
                    companyName: action.data.userFormBasicInfo.companyName,
                    organizationName: action.data.userFormBasicInfo.organizationName,
                    classificationCode: '',
                    creatorName: action.data.userFormBasicInfo.creatorName,
                    creatorWorkRecordSeq: action.data.userFormBasicInfo.creatorWorkRecordSeq,
                    approvalStatus: action.data.userFormBasicInfo.approvalStatus,
                    recentApprovalEmployeeSeq: action.data.userFormBasicInfo.recentApprovalEmployeeSeq,
                    lastApprovalEmployeeSeq: action.data.userFormBasicInfo.lastApprovalEmployeeSeq,
                    rootFormSeq: action.data.userFormBasicInfo.rootFormSeq,
                    parentFormSeq: action.data.userFormBasicInfo.parentFormSeq,
                    formConnectSeq: action.data.userFormBasicInfo.formConnectSeq
                },
                userFormApplicants: action.data.formCurrentUser,
                userFormObservers: action.data.defaultObserverList,
                userFormApprovals: userFormApprovals,
                userFormFiles: [],
                userFormRefDocs: [],
                userFormComments: [],
                userFormReCreateHistory: []
            }
        }
        case types.UPDATE_FORM_SETTING_PREVIEW: {
            let formTypeCurrent = find(action.data.treeCommonFormType, {'detailCode': action.data.formSetting.formTypeLv2});
            formTypeCurrent = formTypeCurrent === undefined ? '':formTypeCurrent.treeId;

            let userFormApprovals = action.data.defaultApprovalList;
            if(action.data.formSetting.approvalLineType === '03') {
                userFormApprovals = filter(action.data.defaultApprovalList, {approvalGbn: 'AP'})
            }
            
            return {
                ...state,
                mode: 'view',
                page: 'draft',
                systemFormSeqCurrent: action.data.systemFormSeqCurrent,
                formTypeCurrent: formTypeCurrent,
                treeCommonFormType: action.data.treeCommonFormType,
                formSetting: action.data.formSetting,
                defaultObserverList: action.data.defaultObserverList,
                defaultApprovalList: action.data.defaultApprovalList,
                permissionModifyList: [],
                storageYearList: action.data.storageYear,
                priorityList: action.data.priority,
                classificationList: action.data.classification,
                companyLogoFile: action.data.companyLogoFile,
                employeeSeq: action.data.employeeSeq,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    userFormSeq: 0,
                    systemFormSeq: action.data.systemFormSeqCurrent,
                    attachFileCode: 0,
                    priorityCode: action.data.formSetting.priorityCode,
                    storageYearCode: action.data.formSetting.storageYearCode,
                    expireStorageDate: '',
                    documentNo: action.data.userFormBasicInfo.documentNo,
                    createDate: action.data.userFormBasicInfo.createDate,
                    companyName: action.data.userFormBasicInfo.companyName,
                    organizationName: action.data.userFormBasicInfo.organizationName,
                    classificationCode: '',
                    creatorName: action.data.userFormBasicInfo.creatorName,
                    creatorWorkRecordSeq: action.data.userFormBasicInfo.creatorWorkRecordSeq,
                    approvalStatus: action.data.userFormBasicInfo.approvalStatus,
                    recentApprovalEmployeeSeq: action.data.userFormBasicInfo.recentApprovalEmployeeSeq,
                    lastApprovalEmployeeSeq: action.data.userFormBasicInfo.lastApprovalEmployeeSeq,
                    rootFormSeq: action.data.userFormBasicInfo.rootFormSeq,
                    parentFormSeq: action.data.userFormBasicInfo.parentFormSeq,
                    formConnectSeq: action.data.userFormBasicInfo.formConnectSeq
                },
                userFormApplicants: action.data.formCurrentUser,
                userFormObservers: action.data.defaultObserverList,
                userFormApprovals: userFormApprovals,
                userFormFiles: [],
                userFormRefDocs: [],
                userFormComments: [],
                userFormReCreateHistory: []
            }
        }
        case types.UPDATE_FORM_VIEW: {
            let formTypeCurrent = find(action.data.treeCommonFormType, {'detailCode': action.data.formSetting.formTypeLv2});
            formTypeCurrent = formTypeCurrent === undefined ? '':formTypeCurrent.treeId;

            return {
                ...state,
                mode: action.data.mode,
                page: action.data.page,
                systemFormSeqCurrent: action.data.systemFormSeqCurrent,
                formTypeCurrent: formTypeCurrent,
                treeCommonFormType: action.data.treeCommonFormType,
                formSetting: action.data.formSetting,
                defaultObserverList: action.data.defaultObserverList,
                defaultApprovalList: action.data.defaultApprovalList,
                permissionModifyList: action.data.permissionModifyList,
                storageYearList: action.data.storageYear,
                priorityList: action.data.priority,
                classificationList: action.data.classification,
                companyLogoFile: action.data.companyLogoFile,
                employeeSeq: action.data.employeeSeq,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    formTitle: action.data.userFormBasicInfo.formTitle,
                    userFormSeq: action.data.userFormBasicInfo.userFormSeq,
                    systemFormSeq: action.data.userFormBasicInfo.systemFormSeq,
                    attachFileCode: action.data.userFormBasicInfo.attachFileCode,
                    priorityCode: action.data.userFormBasicInfo.priorityCode,
                    storageYearCode: action.data.userFormBasicInfo.storageYearCode,
                    expireStorageDate: '',
                    documentNo: action.data.userFormBasicInfo.documentNo,
                    createDate: action.data.userFormBasicInfo.createDate,
                    companyName: action.data.userFormBasicInfo.companyName,
                    organizationName: action.data.userFormBasicInfo.organizationName,
                    classificationCode: action.data.userFormBasicInfo.classificationCode,
                    creatorName: action.data.userFormBasicInfo.creatorName,
                    creatorWorkRecordSeq: action.data.userFormBasicInfo.creatorWorkRecordSeq,
                    approvalStatus: action.data.userFormBasicInfo.approvalStatus,
                    recentApprovalEmployeeSeq: action.data.userFormBasicInfo.recentApprovalEmployeeSeq,
                    lastApprovalEmployeeSeq: action.data.userFormBasicInfo.lastApprovalEmployeeSeq,
                    rootFormSeq: action.data.userFormBasicInfo.rootFormSeq,
                    parentFormSeq: action.data.userFormBasicInfo.parentFormSeq,
                    formConnectSeq: action.data.userFormBasicInfo.formConnectSeq
                },
                userFormApplicants: action.data.formApplicantUser,
                userFormObservers: action.data.userFormObserverList,
                userFormApprovals: action.data.userFormApprovalList,
                userFormFiles: action.data.userFormFiles,
                userFormRefDocs: action.data.userFormRefDocs,
                userFormComments: action.data.userFormComments,
                userFormReCreateHistory: action.data.userFormReCreateHistory
            }
        }
        case types.UPDATE_FORM_VIEW_AFTER_APPROVE: {
            return {
                ...state,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    approvalStatus: action.data.userFormBasicInfo.approvalStatus,
                    recentApprovalEmployeeSeq: action.data.userFormBasicInfo.recentApprovalEmployeeSeq,
                    lastApprovalEmployeeSeq: action.data.userFormBasicInfo.lastApprovalEmployeeSeq
                },
                userFormApprovals: action.data.userFormApprovalList
            }
        }
        case types.UPDATE_FORM_RE_CREATE: {
            let newUserFormApprovals = cloneDeep(state.userFormApprovals);
            newUserFormApprovals.forEach(item => {
                item.approvalDateTime = '';
                item.approvalStatus = '';
            })

            let newFormConnectSeq = maxBy(state.userFormReCreateHistory, 'formConnectSeq').formConnectSeq + 1;

            let newDocumentNo = state.userFormBasicInfo.documentNo;
            if(state.userFormBasicInfo.formConnectSeq === 0) {
                newDocumentNo += '_'+newFormConnectSeq;
            } else {
                let underscoreIndex = newDocumentNo.indexOf('_');
                newDocumentNo = newDocumentNo.substring(0, underscoreIndex) + '_' + newFormConnectSeq;
            }

            let newUserFormFiles = cloneDeep(state.userFormFiles);
            newUserFormFiles.forEach(item => {
                item.attachFileCode = 0;
                item.notDelete = true;
                item.uploaded = false;
            })

            return {
                ...state,
                mode: 'create',
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    userFormSeq: 0,
                    documentNo: newDocumentNo,
                    createDate: action.data.userFormBasicInfo.createDate,
                    companyName: action.data.userFormBasicInfo.companyName,
                    organizationName: action.data.userFormBasicInfo.organizationName,
                    creatorName: action.data.userFormBasicInfo.creatorName,
                    creatorWorkRecordSeq: action.data.userFormBasicInfo.creatorWorkRecordSeq,
                    approvalStatus: '',
                    recentApprovalEmployeeSeq: 0,
                    lastApprovalEmployeeSeq: 0,
                    rootFormSeq: state.userFormBasicInfo.rootFormSeq,
                    parentFormSeq: state.userFormBasicInfo.userFormSeq,
                    formConnectSeq: newFormConnectSeq
                },
                userFormApprovals: newUserFormApprovals,
                userFormFiles: newUserFormFiles
            }
        }
        case types.UPDATE_FORM_CREATE_DRAFT: {
            let userFormApprovals = cloneDeep(state.defaultApprovalList);
            if(state.formSetting.approvalLineType === '03') {
                userFormApprovals = filter(state.defaultApprovalList, {approvalGbn: 'AP'})
            }

            let newUserFormFiles = cloneDeep(state.userFormFiles);
            newUserFormFiles.forEach(item => {
                item.attachFileCode = 0;
                item.notDelete = true;
                item.uploaded = false;
            })

            return {
                ...state,
                mode: 'create',
                companyLogoFile: action.data.companyLogoFile,
                employeeSeq: action.data.employeeSeq,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    userFormSeq: 0,
                    attachFileCode: 0,
                    documentNo: action.data.userFormBasicInfo.documentNo,
                    createDate: action.data.userFormBasicInfo.createDate,
                    companyName: action.data.userFormBasicInfo.companyName,
                    organizationName: action.data.userFormBasicInfo.organizationName,
                    creatorName: action.data.userFormBasicInfo.creatorName,
                    creatorWorkRecordSeq: action.data.userFormBasicInfo.creatorWorkRecordSeq,
                    approvalStatus: action.data.userFormBasicInfo.approvalStatus,
                    recentApprovalEmployeeSeq: action.data.userFormBasicInfo.recentApprovalEmployeeSeq,
                    lastApprovalEmployeeSeq: action.data.userFormBasicInfo.lastApprovalEmployeeSeq,
                    rootFormSeq: action.data.userFormBasicInfo.rootFormSeq,
                    parentFormSeq: action.data.userFormBasicInfo.parentFormSeq,
                    formConnectSeq: action.data.userFormBasicInfo.formConnectSeq
                },
                userFormApplicants: action.data.formCurrentUser,
                userFormObservers: cloneDeep(state.defaultObserverList),
                userFormApprovals: userFormApprovals,
                userFormFiles: newUserFormFiles,
                userFormRefDocs: [],
                userFormComments: [],
                userFormReCreateHistory: []
            }
        }
        case types.UPDATE_USER_FORM_BASIC_INFO: {
            return {
                ...state,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    [action.name]: action.value
                }
            }
        }
        case types.UPDATE_USER_FORM_APPROVAL_STATUS_ON_CLICK_SUBMIT: {
            // return {
            //     ...state,
            //     [action.submitType]: state[action.submitType] + 1,
            //     userFormBasicInfo: {
            //         ...state.userFormBasicInfo,
            //         approvalStatus: action.approvalStatus
            //     }
            // }

            state[action.submitType] = state[action.submitType] + 1;
            state.userFormBasicInfo.approvalStatus = action.approvalStatus;

            return cloneDeep(state)
        }
        case types.UPDATE_USER_FORM_FILES: {
            return {
                ...state,
                userFormFiles: action.files
            }
        }
        case types.UPDATE_USER_FORM_REF_DOCS: {
            return {
                ...state,
                userFormRefDocs: action.refDocs
            }
        }
        case types.UPDATE_USER_FORM_OBSERVER: {
            return {
                ...state,
                userFormObservers: action.observerList
            }
        }
        case types.UPDATE_USER_FORM_APPLICANT: {
            return {
                ...state,
                userFormApplicants: action.applicantList
            }
        }
        case types.UPDATE_USER_FORM_APPROVAL: {
            return {
                ...state,
                userFormApprovals: action.newApprovalList,
                userFormObservers: action.newObserverList,
                userFormBasicInfo: {
                    ...state.userFormBasicInfo,
                    recentApprovalEmployeeSeq: action.newApprovalList[0].employeeSeq
                }
            }
        }
        case types.UPDATE_FORM_MODE: {
            return {
                ...state,
                mode: action.mode,
            }
        }
        case types.UPDATE_USER_FORM_COMMENT: {
            return {
                ...state,
                userFormComments: action.userFormComments,
            }
        }
        case types.UPDATE_FORM_FORM_TYPE_CURRENT: {
            return {
                ...state,
                formTypeCurrent: '',
            }
        }
        case types.RESET_USER_FORM: {
            return initialState
        }
        default: {
            return state;
        }
    }
}

export default generalFormReducer