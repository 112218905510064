import $ from 'jquery';
let o =0;
export const menuJS = () => {

    let seti;
    seti = setInterval(function () {
        if ($(".menu > li").length > 0 ) {
            if(o===0){
                //console.log("aaa");
                $(".menu > li > .link-i").on("click", function () {
                    $(".menu > li").removeClass("current")
                    $(this).toggleClass("open").parent("li").addClass("current");
                    $(".menu > li").each(function () {
    
                        //if ($(this).hasClass("current") && $(this).find("> .link-i").hasClass("open")) {
                        if ($(this).find("> .link-i").hasClass("open")) {
                            $(this).find(".menu-sub").addClass("in");
                        } else {
                            $(this).find(".menu-sub").removeClass("in");
                            //$(this).find(".menu-sub").removeClass("in").parent("li").find("> .link-i").removeClass("open");
                        }
                    })
                });
                $(".menu .sub-li > .link-title").on("click", function () {
                    $(".menu .sub-li").removeClass("current")
                    $(this).toggleClass("open").parent("li").addClass("current");
                    $(".menu .sub-li").each(function () {
                        //if ($(this).hasClass("current") && $(this).find("> .link-title").hasClass("open")) {
                        if ($(this).find("> .link-title").hasClass("open")) {
                            $(this).find("> ul").addClass("in");
                        } else {
                            $(this).find("> ul").removeClass("in");
                            //$(this).find("> ul").removeClass("in").parent("li").find("> .link-title").removeClass("open");
                        }
                    })
                });
                menuActive();
                clearInterval(seti);
                //console.log("aaaa", o);
                o++;
            }
            
        }else{
            //console.log("bbb");
            o = 0;
            if($(".form-login").length > 0){
                clearInterval(seti);
            }else{
                if(o===0){
                    menuJS();
                }
            }
        }
    }, 300);
    ///menuActive();
}
export const menuShowJs = () => {
    $("body").addClass("opne-menu");
    $(".menu a").on("click", function () {
        $(".menu-mask").trigger("click");
    });
}
export const menuHideJs = () => {
    $("body").removeClass("opne-menu")
}
export const menuSetting = (e) => {
    e.preventDefault();
    e.stopPropagation();
    $(e.target).toggleClass("open");
    var id = $(e.target).attr("data-id");
    if ($(e.target).hasClass("open")) {
        //
        $("#" + id).toggleClass("in");
    } else {
        $("#" + id).removeClass("in");

    }

}
export const menuSettingBody = () => {

    setTimeout(function () {
        $('body').on("click", function (ev) {
            if (!$(this).hasClass("list-nav-wrapper") || !$(this).hasClass("constrol-setting")) {
                $(".list-nav-wrapper").removeClass("in");
                $(".constrol-setting").removeClass("open");

            }
        });

    }, 500)
}
export const menuActive = () => {
    let seti;
    seti = setInterval(function(){
        if($(".menu .active").length > 0){
            
            $(".menu li a").each(function(){
                if(!$(this).parent("li").hasClass("active")){
                    
                    $(this).parents(".menu-sub").removeClass("in").prev(".link-i").removeClass("open");
                    $(this).parents(".sub-li").removeClass("current").find(">ul").removeClass("in").prev(".link-title").removeClass("open");
                    $(this).parents("li").removeClass("active current");
                }
            })
            $(".menu .active").parents(".menu-sub").addClass("in").prev(".link-i").addClass("open").parent("li").addClass("active current");
            $(".menu .sub-menu .sub-li .active").parent("ul").addClass("in").prev(".link-title").addClass("open").parent(".sub-li").addClass("active current");
            //$(".menu .active").parents("li").addClass("active");
            clearInterval(seti);
        }
    }, 100)
}
export const getBreadcrumbs = () => {
    let seti;
    //$(".menu > li").removeClass("active").find(".sub-menu > li").removeClass("active");
    $(".menu .active").each(function () {
        if ($(this).find("> span").hasClass("link-i") || $(this).find("> span").hasClass("link-title")) {
            $(this).removeClass("active");
        }
    })
    seti = setInterval(function () {
        if ($(".menu .active").length > 0) {
            $(".menu .active").parents("li").addClass("active");
            var countLi = $(".menu .active").parents('li').length + 1;
            let breadcrumbs = '';
            $(".menu .active").each(function () {
                let textLi = $(this).find("> span, > a > span").text();
                breadcrumbs += '<li class="breadcrumb-item">' + textLi + '</li>';
            })
            $(".breadcrumb2").html(breadcrumbs);
            const textA = $(".menu .active > a > span").text();
            $(".breadcrumb-title").html(textA);
            if (countLi > 1) {
                clearInterval(seti);
            }
        }
    }, 100)
}