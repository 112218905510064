import * as types from "./checkIsNewType"

export const actCheckIsNew = (payload) =>{
    return {
        type: types.CHECK_IS_NEW,
        payload
    }
}
export const actCheckOpen = (payload) =>{
    return {
        type: types.CHECK_OPEN,
        payload
    }
}
export const actCheckDisbale = (payload) =>{
    return {
        type: types.CHECK_DISABLE,
        payload
    }
}
export const actSetSocialId = (payload) =>{
    return {
        type: types.SET_SOCIAL,
        payload
    }
}
export const actSetStaffName = (payload) =>{
    return {
        type: types.SET_STAFFNAME,
        payload
    }
}
export const actSetParentOrg = (payload) =>{
    return {
        type: types.SET_PARENT_ORG,
        payload
    }
}

export const actApprovalLineName = (payload) =>{
    return {
        type: types.SET_APPROVALINE_NAME,
        payload
    }
}
export const actApprovalLineNameError = (payload) =>{
    return {
        type: types.SET_APPROVALINE_NAME_ERROR,
        payload
    }
}
export const actSetSearchSelect = (payload) =>{
    return {
        type: types.SET_SEARCH_SELECT,
        payload
    }
}
export const actSetVehicleStatus = (payload) =>{
    return {
        type: types.SET_VEHICLE_STATUS,
        payload
    }
}
export const actCheckGrap = (payload) =>{
    return {
        type: types.CHECK_GRAP,
        payload
    }
}