import $ from 'jquery';
import _ from 'lodash';
import React, { Fragment, Suspense, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import HeaderPage from './../components/headerPage/HeaderPage';
import { actTimeOutRedirectToLogin } from './../redux/authentication/authenticationAction';
import { actFetchMenus } from './../redux/menu/menuAction';
import routes from './../routes';
import { DEFAULT_TIME_OUT } from './../util/config';

const AppComponentContainer = props => {
    const { state } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (state?.writeYn === 'N') {
            $('.permision-element').remove();
        }
        // eslint-disable-next-line
    });

    const handleOnIdle = event => {
        redirectToLoginPage();
    }

    const redirectToLoginPage = () => {
        props.fetchMenus([]);
        props.timeOutRedirectToLogin();
        if (history) history.push('/login');
    }

    const handleOnAction = event => {
        if (history && !localStorage.getItem('authorization') && history.location.pathname !== '/login' && !window.location.pathname.includes("IndividualForm")) {
            redirectToLoginPage();
        }
    }

    const isAuthorization = () => {
        return !_.isUndefined(state?.readYn) && state?.readYn === 'Y';
    }

    const PrivateRoute = ({ component: Component, ...rest }) => {
        return (
            <Route {...rest} render={(props) => (
                isAuthorization()
                    ? <Component {...props} />
                    : <Redirect to='/home' />
            )} />
        )
    }

    const showContentMenus = (routes) => {
        let result = null;
        if (routes.length > 0) {
            result = routes.map((route, index) => {
                return (
                    route.isPrivate ?
                        <PrivateRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.main}
                        />
                        : <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.main}
                        />

                );
            });
        }
        return (
            <Suspense fallback={<div className="fall-back-loading-page" ><div className="loader-inner"><div className="spinner" /></div></div>}>
                <Switch> {result} <Redirect to="/" /> </Switch>
            </Suspense>
        );
    }

    const mainScreen = !_.isEmpty(props.userInfo) ? 'main-page page-menu' : 'main-page'
    const checkIndividualForm = history.location.pathname.split("/")[1] !== 'IndividualForm' ;
    return (
        <Fragment>
            <IdleTimer
                timeout={DEFAULT_TIME_OUT}
                onIdle={handleOnIdle}
                onAction={handleOnAction}
                debounce={250}
            />
            {checkIndividualForm && <HeaderPage />}
            <div className={mainScreen}>
                <div className="main-inner">
                    {showContentMenus(routes)}

                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.authentication.userInfo,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        fetchMenus: (menu) => {
            dispatch(actFetchMenus(menu))
        },
        timeOutRedirectToLogin: () => {
            dispatch(actTimeOutRedirectToLogin())
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppComponentContainer);