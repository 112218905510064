import * as types from './AnnualLeaveCriteriaSettingTypes';
import _ from 'lodash';
const initialState = {
    listOrganizations: [],
    contextALCSData: {},
    listAnnualLeaveCriteriaSettings: [],
}
const annualLeaveCriteriSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_ALCS_FORM_DATA:
            state.contextALCSData = _.cloneDeep(action.payload);
            return {
                ...state
            }
        case types.GET_LIST_ORGANIZATIONS:
            return {
                ...state,
                listOrganizations: action.data.listOrganizations
            }
        case types.GET_LIST_ANNUAL_LEAVE_CRITERIA_SETTINGS:
            let { listAnnualLeaveCriteriaSettings } = action.data;
            listAnnualLeaveCriteriaSettings?.forEach(element => {
                if (_.isEmpty(element.durationGbn)) element.durationGbn = "Y";
                if (_.isEmpty(element.useYn)) element.useYn = "Y";
            })
            state.listAnnualLeaveCriteriaSettings = listAnnualLeaveCriteriaSettings;
            return { ...state }
        case types.ON_CHANGE_LIST_ANNUAL_LEAVE_CRITERIA_SETTINGS: {
            state.listAnnualLeaveCriteriaSettings = action.payload.newListAnnualLeaveCriteriaSettings
            return _.cloneDeep(state);
        }
        default: return state
    }
}
export default annualLeaveCriteriSettingReducer;