import * as types from './CorporateradRegisterTypes';
import _ from 'lodash';
const initialState = {
    isOpenModal:false,
    checkCorporateCard:0,
    listOrganizations:[],
    listCompanyCorporateCards : [],
    totalCount :0,
    pageIndexSelection:0,
    contextCorpCardData : {}
}
const corporateCardReducer = (state = initialState,action)=>{
    switch(action.type){
        case types.OPEN_MODAL:
            return{
                ...state,
                isOpenModal:action.isOpenModal
            }
        case types.CHECK_CORPORATE_CARD:
            return{
                ...state,
                checkCorporateCard:action.payload
        }
        case types.GET_LIST_ORGANIZATIONS:
            // let defaultOrganizatin = action.data.listOrganizations && action.data.listOrganizations[0] ? action.data.listOrganizations[0].organizationSeq:0;
            let listCompany = action.data.listOrganizations? action.data.listOrganizations:[];
            return{
                ...state,
                listOrganizations : listCompany
        }
        case types.GET_LIST_COMPANY_CORPORATE_CARD:
            return{
                ...state,
                listCompanyCorporateCards : action.data.listCompanyCorporateCards,
                totalCount : action.data.totalCount
        }
        case types.PAGING:
            return{
                ...state,
                pageIndexSelection:action.pageIndexSelection
        }
        case types.CHANGE_FORM_DATA_CORPORATE_CARD:
            // state.contextCorpCardData[action.payload]
            state.contextCorpCardData = _.cloneDeep(action.payload)
        return{
            ...state
        }
        default: return state
    }
}
export default corporateCardReducer;