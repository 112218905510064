import * as types from "./paramsToSearchType"

const initialSate = {}
const getParamsReducer = (state = initialSate, action) =>{
    switch(action.type){
        case types.GET_PARAMS: 
            return action.params
        default: return state
    }
}
export default getParamsReducer;