import * as types from './menuType'
import apiService from '../../util/apiService'
import { menuService } from '../../util/menuService'
import _ from 'lodash';


export const actFetchMenusRequest = (apiUrl) => {
    return dispatch => {
        return apiService(apiUrl, 'GET', null).then(res => {
            let resultMenu = res.data.data;
            let menuData = menuService(resultMenu);
            dispatch(actFetchMenus(menuData));
            actFetchApprovalCountNumberRequest().then(res => {
                resultMenu['lv3']?.forEach(element => {
                    const countNumberResult = res.data;
                    if (element.treeIdHier !== null) {
                        element.countNumber = countNumberResult[element.treeIdHier] ? countNumberResult[element.treeIdHier] : 0;
                    } else {
                        element.countNumber = 0;
                    }
                })
                let menuData = menuService(resultMenu);
                dispatch(actFetchMenus(menuData));
            })
        });
    };
}

export const actFetchMenus = (menus) => {
    return {
        type: types.FETCH_MENU,
        menus
    }
}

export const actUpdateMenuCountNumber = () => {
    return (dispatch, getState) => {
        actFetchApprovalCountNumberRequest().then(res => {
            const countNumberResults = res.data;
            let { menus } = getState();
            menus?.every(menuLevel1 => {
                if (menuLevel1.treeIdHier === "04") {
                    menuLevel1.children?.every(mernuLevel2 => {
                        if (mernuLevel2.treeIdHier === '04-04') {
                            mernuLevel2.children?.forEach(mernuLevel3 => {
                                if (mernuLevel3.treeIdHier !== null) {
                                    mernuLevel3.countNumber = countNumberResults[mernuLevel3.treeIdHier] ? countNumberResults[mernuLevel3.treeIdHier] : 0;
                                } else {
                                    mernuLevel3.countNumber = 0;
                                }
                            })
                            return false;
                        }
                        return true;
                    })
                    return false;
                }
                return true;
            })
            dispatch(actFetchMenus(_.cloneDeep(menus)));
        })
    };
}

export const actFetchApprovalCountNumberRequest = () => {
    return apiService('/main/getApprovalCountNumber', 'GET', null);
}