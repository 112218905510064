import apiService from '../../util/apiService'
import * as types from "./dataObserveTableType"

export const actGetDataObserveTableRequest = (apiURL, data, params, callback) => {
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res => {
            if (res.data.data) {
                dispacth(actGetDataObserveTable(res.data.data));
                if (callback) {
                    callback(res.data.data);
                }

            }
        })
    }
}
export const actGetDataObserveTable = (items) => {
    return {
        type: types.FETCH_OBSERVE_TABLE,
        items
    }
}
export const actInsertDataObserveTableRequest = (item, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'POST', item).then(res => {
            dispacth(actInsertDataObserveTable(res.data));
        })
    }

}
export const actInsertDataObserveTable = (item) => {
    return {
        type: types.INSERT_OBSERVE_TABLE,
        item
    }
}
export const actUpdateDataObserveTableRequest = (item, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, "PUT", item).then(res => {
            dispacth(actUpdateDataObserveTable(res.data));
        })
    }
}
export const actUpdateDataObserveTable = (item) => {
    return {
        type: types.UPDATE_OBSERVE_TABLE,
        item
    }
}
export const actDeleteDataObserveTableRequest = (id, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'DELETE', null).then(res => {
            dispacth(actDeleteDataObserveTable(id));
        })
    }
}
export const actDeleteDataObserveTable = (id) => {
    return {
        type: types.DELETE_OBSERVE_TABLE,
        id
    }
}
export const actListDataObserveTable = (payload) => {
    return {
        type: types.LIST_OBSERVE_TABLE,
        payload
    }
}

export const actMoveDataObserveTable = (payload) => {
    return {
        type: types.MOVE_OBSERVE_TABLE,
        payload
    }
}