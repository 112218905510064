export const UPDATE_FORM_SETTING = "UPDATE_FORM_SETTING"
export const UPDATE_FORM_SETTING_PREVIEW = "UPDATE_FORM_SETTING_PREVIEW"
export const UPDATE_USER_FORM_BASIC_INFO = "UPDATE_USER_FORM_BASIC_INFO"
export const UPDATE_USER_FORM_FILES = "UPDATE_USER_FORM_FILES"
export const UPDATE_USER_FORM_REF_DOCS = "UPDATE_USER_FORM_REF_DOCS"
export const UPDATE_USER_FORM_OBSERVER = "UPDATE_USER_FORM_OBSERVER"
export const UPDATE_USER_FORM_APPLICANT = "UPDATE_USER_FORM_APPLICANT"
export const UPDATE_USER_FORM_APPROVAL = "UPDATE_USER_FORM_APPROVAL"
export const UPDATE_FORM_VIEW = "UPDATE_FORM_VIEW"
export const UPDATE_FORM_MODE = "UPDATE_FORM_MODE"
export const UPDATE_USER_FORM_COMMENT = "UPDATE_USER_FORM_COMMENT"
export const UPDATE_FORM_VIEW_AFTER_APPROVE = "UPDATE_FORM_VIEW_AFTER_APPROVE"
export const UPDATE_FORM_FORM_TYPE_CURRENT = "UPDATE_FORM_FORM_TYPE_CURRENT"
export const UPDATE_FORM_RE_CREATE = "UPDATE_FORM_RE_CREATE"
export const RESET_USER_FORM = "RESET_USER_FORM"
export const UPDATE_FORM_CREATE_DRAFT = "UPDATE_FORM_CREATE_DRAFT"
export const UPDATE_USER_FORM_APPROVAL_STATUS_ON_CLICK_SUBMIT = "UPDATE_USER_FORM_APPROVAL_STATUS_ON_CLICK_SUBMIT"