import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import { menuHideJs, menuShowJs } from '../../util/menuUtil';
import Menu from "../menu/Menu";
import LangugePage from "./LangugePage";

const HeaderPage = (props) => {
    const [menu, setMenu] = useState('');

    const menuShow = (e) => {
        setMenu("in");
        menuShowJs();
    }
    const menuHide = (e) => {
        setMenu("");
        menuHideJs()
    }

    return (
        <Fragment>
            {
                !_.isEmpty(props.userInfo) &&
                <header className="header-container">
                    <div className="row">
                        <div className="col-auto item pos-static">
                            <span onClick={menuShow} className="control-menu menu-btn"><span className="lines"></span></span>
                            <Link className="logo" to='/home'>
                                <img src={logo} alt="" />
                            </Link>
                            <Menu menuClass={menu} handleClose={menuHide} />
                        </div>
                        <div className="col item text-right">
                            <LangugePage />
                        </div>
                    </div>
                </header>
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        userInfo: state.authentication.userInfo,
        menus: state.menus,
    };
}


export default connect(
    mapStateToProps, null
)(HeaderPage);