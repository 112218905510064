import * as types from './TrainingRequestTypes';
import _ from 'lodash';
const initialState = {
    trainingRequestDetail :{}
}
const trainingRequestReducer = (state=initialState,action)=>{
    switch(action.type){
        case types.GET_TRAINING_REQUEST_DETAIL:
            return{
                ...state,
                trainingRequestDetail : _.cloneDeep(action.data)
            }
       
        default: return state
    }
}
export default trainingRequestReducer;