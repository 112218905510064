import _ from "lodash";

export const currencyFormatDE = (num, separator, isDefaultVal) => {
    if (num && (typeof num == 'string')) {
        num = parseInt(num.replaceAll(separator, ""))
    } else {
        num = 0;
    }
    num = (num
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d)())/g, '$1' + separator))
    if (!isDefaultVal && num == 0) num = '';
    if (isDefaultVal && num == 0) num = 0;
    // check if user input not number then is NaN and must different from type 000,000,000
    if (isNaN(num) && num.indexOf(separator) == -1) num = '';
    return num;

}
export const formatNumberOfDate = (num, separator, isDefaultVal) => {
    var re = new RegExp("[0-9]")
    if (num.length > 0) {
        if (num.indexOf(separator) < 0) {
            num = num;
            if (!re.test(num.charAt(num.length - 1))) {
                num = num.substring(0, num.length - 1)
            }
        } else {
            if (num.indexOf(separator) == 0) {
                num = '';
            } else {

                let afterSeparator = num.substring(num.indexOf(separator) + 1);
                if (afterSeparator.length > 0) {

                    if (afterSeparator.length > 2) {
                        // the  decimal which is after separator must have length is 2
                        num = num.substring(0, num.indexOf(separator) + 1 + 2)
                    }
                    let arrAfterSeparator = afterSeparator.split('');
                    arrAfterSeparator.forEach((e, index) => {
                        if (e == separator || !re.test(e)) {
                            arrAfterSeparator.splice(index, 1)
                        }
                    })
                    num = num.substring(0, num.indexOf(separator) + arrAfterSeparator.length + 1)
                }
            }
        }
    } else {
        num = '';
    }
    return num;
}

export const formatPhoneNumber = (value) => {
    if (_.isUndefined(value)) return null;
    let newValue = '';
    if (value.length === 0) {
        return newValue;
    }
    if (value.length < 3 || value === 0) {
        newValue = value;
    } else if (value.length === 3) {
        newValue = value.substr(0, 3) + '-';
    } else if (value.length < 7) {
        newValue = value.substr(0, 3) + '-' + value.substr(3, value.length - 3);
    } else if (value.length === 7) {
        newValue = value.substr(0, 3) + '-' + value.substr(3, 4) + '-';
    } else if (value.length <= 11) {
        newValue = value.substr(0, 3) + '-' + value.substr(3, 4) + '-' + value.substr(7, value.length - 7);
    } else {
        newValue = value.substr(0, 3) + '-' + value.substr(3, 4) + '-' + value.substr(7, 4);
    }
    return newValue;
}