import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/droppable";
import { set } from "lodash";
import { isMobile } from "react-device-detect";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("jquery-ui-touch-punch");
export const widthSelect = (nameElement) => {
  var setW = setTimeout(function () {
    widthSelectFun(nameElement);
  }, 0);
  $(window).on("resize", function () {
    clearTimeout(setW);
    setW = setTimeout(function () {
      widthSelectFun(nameElement);
    }, 0);
  });
};
function widthSelectFun(nameElement) {
  const seflt = $(nameElement);
  const selftParent = seflt.parent(".field-search");
  let wP = selftParent.outerWidth();
  let left = selftParent.outerWidth() - seflt.outerWidth();
  const offset = seflt.offset();
  const leftOffset = offset.left;
  const wWin = $(window).width() - leftOffset - seflt.outerWidth();
  if (wP > wWin) {
    wP = wWin;
  }
  $(".popper-wrapper .MuiPopover-paper").css({
    width: wP + "px",
    "margin-left": "-" + left + "px",
  });
  //$(".popper-wrapper .MuiPopover-paper").css({'width': wP + "px"});
}
export const convertImgToBase64 = (url, callback, outputFormat) => {
  var proxyUrl = "https://cors-anywhere.herokuapp.com/";
  var targetUrl = proxyUrl + url;
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    var dataURL = canvas.toDataURL(outputFormat || "image/png");
    if (callback) {
      callback(dataURL);
    }
    canvas = null;
  };
  img.src = url;
  /*var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();*/
};
export const highlightText = (name, text) => {
  setTimeout(function () {
    var searchTxtBox = text;
    searchTxtBox = searchTxtBox.replace(/(\s+)/, "(<[^>]+>)*$1(<[^>]+>)*");
    let falg = true;
    $(name).each(function (e) {
      var seflt = $(this);
      //if ($(seflt).prev().find(".icon").hasClass("icon-user")) {
      // var searchTxtBox = text;
      // //searchTxtBox.val(searchTxtBox.val().replace(/(\s+)/,"(<[^>]+>)*$1(<[^>]+>)*"));
      // searchTxtBox = searchTxtBox.replace(/(\s+)/,"(<[^>]+>)*$1(<[^>]+>)*");
      var textarea = seflt;
      var enew = "";
      if (searchTxtBox !== "") {
        enew = textarea.html().replace(/(<mark>|<\/mark>)/gim, "");
        textarea.html(enew);

        var query = new RegExp("(" + searchTxtBox + ")", "gim");
        var newtext = textarea.html().replace(query, "<mark>$1</mark>");
        newtext = newtext.replace(
          /(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,
          "</mark><mark>"
        );

        textarea.html(newtext);
        //textarea.find("mark").addClass("mask");

        setTimeout(function () {
          if (falg && textarea.find("mark").text()) {
            let position = textarea.parents(".rc-tree-treenode").position();
            let top = position.top;
            $(".pt-search > .scroll-yxfull").scrollTop(top);
            falg = false;
          }
        }, 0);
      } else {
        enew = textarea.html().replace(/(<mark>|<\/mark>)/gim, "");
        textarea.html(enew);
      }

      //}
    });
  }, 300);
};

export const loginHeightFun = () => {
  loginHeight();
  var setHeight;
  $(window).on("resize", function () {
    clearTimeout(setHeight);
    setHeight = setTimeout(function () {
      loginHeight();
    }, 0);
  });
};
const loginHeight = () => {
  var hBox = $(".form-login").outerHeight();
  var hWin = $(window).height();
  if (hBox > hWin) {
    $(".form-login").removeClass("pos-h-relative");
  } else {
    $(".form-login").addClass("pos-h-relative");
  }
};
export const setScrollApproval = (name) => {
  setTimeout(function () {
    setScrollBox(name);

    const boxScroll = $(".content-group > .content-group-scroll");
    const boxHead = $(".approval-wrap .content-head .content-head-inner");
    boxScroll.scrollLeft();
    $(".content-group > .content-group-scroll").on("scroll", function () {
      var wleft = $(this).scrollLeft();
      boxHead.css("left", "-" + wleft + "px");
    });
  }, 0);
};
export const setScrollBox = (name) => {
  setScrollBoxFun(name);
  $(window).on("resize", function () {
    setScrollBoxFun(name);
  });
};
export const setScrollBoxFun = (name) => {
  const wWrap = $(".approval-wrap").width() - 2;
  const wBox = $(".approval-wrap .content-group-scroll > div").width();
  if (wBox < wWrap) {
    setScrollBarWidth(name);
  } else {
    $(name).css("padding-right", 0);
  }
};
export const setScrollBarWidth = (name) => {
  $(name).css("padding-right", getScrollBarWidth());
};
export const getScrollBarWidth = () => {
  var inner = document.createElement("p");
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement("div");
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = "scroll";
  var w2 = inner.offsetWidth;
  if (w1 === w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return w1 - w2;
};
export const getMarginBottonGird = (name) => {
  var setMargin;
  const wScroll = getScrollBarWidth();
  marginBottonGird(name, wScroll);
  $(window).on("resize", function () {
    clearTimeout(setMargin);
    setMargin = setTimeout(function () {
      marginBottonGird(name, wScroll);
    }, 0);
  });
};
export const marginBottonGird = (name, wScroll) => {
  $(name).each(function () {
    let tableFix = $(name).find(".table-fix");
    let hTableFix = 0;
    tableFix.each(function () {
      hTableFix += $(this).outerHeight();
    });
    let hBox =
      $(this).find(".grid-table-inner").innerHeight() -
      (hTableFix + $(this).find(".tbody ").outerHeight() + wScroll);
    if (hBox > 0) {
      $(this).find(".tbody ").css("margin-bottom", hBox);
    } else {
      $(this).find(".tbody ").css("margin-bottom", 0);
    }
  });
};
export const convertEditor = (name) => {
  name.each(function () {
    let datatext = $(this).find(".editor-text").text();
    $(this).find(".editor-html-inner").html(datatext);
    if (name.find(".editor-html-inner img").length > 0) {
      name.find(".editor-html-inner img").each(function () {
        $(this).removeAttr("srcset").removeAttr("sizes").removeAttr("width");
      });
      base64Img(".editor-html-inner");
    }
  });
};
export const convertInfo = (name) => {
  if ($(name).find("img").length > 0) {
    base64Img(name);
  }
};

export const base64Img = (name) => {
  $(name)
    .find("img")
    .each(function () {
      var seflt = $(this);
      if (!seflt.hasClass("base64Image")) {
        var urlSrc = seflt.attr("src");
        convertImgToBase64(urlSrc, function (base64Image) {
          seflt
            .attr("src", base64Image)
            .attr("crossorigin", "anonymous")
            .addClass("base64Image");
        });
      }
    });
};
export const maskPage = () => {
  if ($("#waitLoadingPDF").length === 0) {
    $("body").append(
      '<div class="loading-page" id="waitLoadingPDF"><div class="loader"></div></div>'
    );
  }
};
export const unMaskPage = () => {
  $("#waitLoadingPDF").fadeOut(300, function () {
    $("#waitLoadingPDF").remove();
  });
};

export const maxheightBottom = (parentNameTr, parentName, name) => {
  if ($(parentNameTr).find(name).length > 0) {
    maxheight(parentNameTr, parentName, name);
    let setResize;
    $(window).on("resize", function () {
      clearTimeout(setResize);
      setResize = setTimeout(function () {
        maxheight(parentNameTr, parentName, name);
      }, 0);
    });
  }
};
export const maxheight = (parentNameTr, parentName, name) => {
  $(parentNameTr).each(function () {
    var maxHeight = Math.max.apply(
      null,
      $(name, this)
        .map(function () {
          return $(this).height() + 5;
        })
        .get()
    );
    $(parentName, this).css({ "padding-bottom": maxHeight });
  });
};
export const countKey = (key) => {
  let count = 0;
  $(".approval-wrap tbody tr").each(function () {
    if ($(this).attr("data-key") === key) {
      count++;
    }
  });
  return count;
};
export const dbClickTree = (callBack) => {
  let setDbClick;
  let setDbClick1;
  let count = 0;
  let count1 = 0;
  clearTimeout(setDbClick1);
  clearTimeout(setDbClick);
  $(".treeview-icon .rc-tree-title").on("dblclick", function () {
    const seflt = $(this);
    count1 = 0;
    count = 0;
    if ($(this).prev(".rc-tree-iconEle").find(".icon").hasClass("icon-user")) {
      if ($(".approval-wrap").hasClass("table-box")) {
        if (count1 === 0) {
          setDbClick1 = setTimeout(function () {
            $(".rc-tree.treeview-icon").next(".btn-save").trigger("click");
          }, 0);
        }
      }
      if (
        !$(".approval-wrap").hasClass("table-box") &&
        $(".observe-wrap").hasClass("observe-wrap")
      ) {
        if (count1 === 0) {
          setDbClick1 = setTimeout(function () {
            $(".rc-tree.treeview-icon").next(".btn-save-2").trigger("click");
          }, 0);
        }
      }
    } else {
      if (count === 0) {
        setDbClick = setTimeout(function () {
          seflt
            .parents(".rc-tree-treenode-switcher-close")
            .find(".rc-tree-switcher_close")
            .trigger("click");
          count++;
        }, 0);
      }
    }
    //rc-tree-switcher
  });
  //makeDroppable();
};
export const makeDroppable = () => {
  //hModalResize();
  let setDrop;
  let flag = true;
  let bodyModal = ".modal-type-inner";
  // if(isMyMobie()){
  //     bodyModal = ".modal-type"
  // }
  clearTimeout(setDrop);
  setDrop = setTimeout(function () {
    /*$(".rc-tree.treeview-icon .rc-tree-treenode").each(function(){
            if($(this).find(".icon").hasClass("icon-user")){
                $(this).draggable({
                    helper: "clone", 
                    //handle: ".rc-tree-treenode-selected",
                    revertDuration: 0,
                    revert: true,
                    appendTo: 'body',
                    refreshPositions: true,
                    start: function( event, ui ) {
                        $(this).find(".rc-tree-title").trigger("click");
                        if($(this).find(".icon").hasClass("icon-user")){
                            flag = true;
                        }else{
                            flag = false;

                        }                            
                        if(flag){
                            $(".approval-wrap .grid-table-inner").droppable({                  
                                drop: function(event, ui){
                                    if($("body> .ui-draggable-dragging").length > 0){
                                            $(".rc-tree.treeview-icon").next(".btn-save" ).trigger("click" );    
                                    }
                                }
                            })  

                        }
                    },
                    stop:function( event, ui ) {
                    }
                });
    
            }else{
 
            }
        });*/
    $(
      ".rc-tree.treeview-icon .rc-tree-treenode .rc-tree-node-content-wrapper"
    ).each(function () {
      const $this = $(this);
      $this.draggable({
        helper: "clone",
        //handle: ".rc-tree-treenode-selected",
        revertDuration: 0,
        revert: true,
        appendTo: bodyModal,
        refreshPositions: true,
        start: function (event, ui) {
          if ($(bodyModal).find("> .ui-draggable-dragging").length > 0) {
            $this.find(".rc-tree-title").trigger("click");
            flag = boolFlag();
            if (flag) {
              $(".approval-wrap .grid-table-inner").droppable({
                drop: function (event, ui) {
                  if (boolFlag()) {
                    $(".rc-tree.treeview-icon")
                      .next(".btn-save")
                      .trigger("click");
                  }
                },
              });
            }

            $(".observe-wrap").droppable({
              drop: function (event, ui) {
                $(".btn-save-2").trigger("click");
              },
            });
          }
        },
        stop: function (event, ui) {},
      });
    });

    let boolFlag = () => {
      let boolFlag1 = true;
      if (
        $(bodyModal)
          .find("> .ui-draggable-dragging .icon")
          .hasClass("icon-user")
      ) {
        boolFlag1 = true;
      } else {
        boolFlag1 = false;
      }
      return boolFlag1;
    };
  }, 300);
  // if(isMyMobie()){

  // }
};

export const makeDroppable2 = () => {
  //hModalResize();
  let setDrop;
  let flag = true;
  let bodyModal = ".modal-type-inner";
  clearTimeout(setDrop);
  setDrop = setTimeout(function () {
    $(
      ".rc-tree.treeview-icon .rc-tree-treenode .rc-tree-node-content-wrapper"
    ).each(function () {
      const $this = $(this);
      $this.draggable({
        helper: "clone",
        revertDuration: 0,
        revert: true,
        appendTo: bodyModal,
        refreshPositions: true,
        start: function (event, ui) {
          if ($(bodyModal).find("> .ui-draggable-dragging").length > 0) {
            $this.find(".rc-tree-title").trigger("click");
            $(".observe-wrap").droppable({
              drop: function (event, ui) {
                $(".rc-tree.treeview-icon")
                  .next(".btn-save-2")
                  .trigger("click");
              },
            });
          }
        },
        stop: function (event, ui) {},
      });
    });
  }, 300);
};

function hModalResize() {
  let setRe;
  hModal();
  $(window).on("resize", function () {
    $(
      ".pt-search > .scroll-yxfull, .modal-type-inner .observe-wrap, .modal-type-inner .approval-wrap .grid-table-inner"
    ).outerHeight("auto");
    clearTimeout(setRe);
    setRe = setTimeout(function () {
      hModal();
    }, 500);
  });
}

function hModal() {
  let hModal = $(document).height() - 60;
  if ($(window).width() < 1360) {
    hModal = $(document).height() - 20;
  }
  let hModalBox =
    $(".modal-type-inner .modal-content .modal-header").outerHeight() +
    $(".modal-type-inner .modal-content .modal-footer").outerHeight() +
    $(".modal-type-inner .modal-content .group-search ").outerHeight() +
    50;
  let hbox = hModal - hModalBox;
  const htile = $(".modal-type-inner .w320  .h-title").outerHeight();
  let hleft = hbox - htile - 72;
  $(".pt-search > .scroll-yxfull").outerHeight(hleft);
  if (
    $(".modal-type-inner .approval-wrap").length > 0 &&
    $(".modal-type-inner .observe-wrap").length > 0
  ) {
    let hright = parseInt((hbox - htile * 2) / 2);
    $(".modal-type-inner .approval-wrap .grid-table-inner").outerHeight(hright);
    $(".modal-type-inner .observe-wrap").outerHeight(hright - 15);
  } else {
    let hright = hbox - htile;
    if ($(".modal-type-inner .approval-wrap").length > 0) {
      $(".modal-type-inner .approval-wrap .grid-table-inner").outerHeight(
        hright
      );
    }
    if ($(".modal-type-inner .observe-wrap").length > 0) {
      $(".modal-type-inner .observe-wrap").outerHeight(hright + 15);
    }
  }
}
export const isMyMobie = () => {
  /*let isMobile = false;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;
    return isMobile;*/
  let isMobileBool = false;
  if (isMobile) {
    isMobileBool = true;
  }
  return isMobileBool;
};
export const dbClickTreeViewSelect = () => {
  $(".rc-tree-title").on("dblclick", function () {
    $(".modal-dialog .btn-save, .wrapper-select .btn-save").trigger("click");
  });
};
export const hideBtn = () => {
  if (isMyMobie()) {
    $(".none-mobi").addClass("hide");
  } else {
    $(".none-mobi").removeClass("hide");
  }
};
export const showHideBtnSave = () => {
  if (isMyMobie()) {
    $(".modal-dialog .btn-save, .wrapper-select .btn-save").removeClass(
      "pos-hide"
    );
  } else {
    $(".modal-dialog .btn-save, .wrapper-select .btn-save").addClass(
      "pos-hide"
    );
  }
};
export const checkDeviceMain = () => {
  if (isMyMobie()) {
    $("body").addClass("page-ipad-mobile").removeClass("page-desktop");
  } else {
    $("body").addClass("page-desktop").removeClass("page-ipad-mobile");
  }
};
export const homePage = () => {
  let setM;
  setM = setTimeout(function () {
    homePageFun();
  }, 0);
  $(window).on("resize", function () {
    clearTimeout(setM);
    setM = setTimeout(function () {
      homePageFun();
    }, 0);
  });
};
export const homePageFun = () => {
  let wrap = $(".main-inner").outerWidth();
  let box = $(".home-page").outerWidth();
  let hRight = parseInt($(".header-container").css("padding-right"));
  if (wrap > box) {
    let paddingRight = hRight - getScrollBarWidth();
    $(".home-page .box-line").css("padding-right", paddingRight);
    //$(".home-page").addClass("scroll-17");
    //console.log(wrap, box, getScrollBarWidth(), $(".header-container").css("padding-right"));
  } else {
    $(".home-page .box-line").css("padding-right", hRight);
    //$(".home-page").removeClass("scroll-17");
    //console.log(wrap, box, getScrollBarWidth());
  }
};
export const dbClickTreeViewGrid = () => {
  let seti;
  let touchtime = 0;
  let countClick = 0;
  if (isMyMobie()) {
    if ($(".rc-tree-title").length > 0 && $(".dbClick").length > 0) {
      $(".rc-tree-title").on("touchend", function (event) {
        //event.preventDefault();
        const seflt = $(this);
        $(".rc-tree-treenode").removeClass("rc-tree-treenode-selected");
        seflt
          .parents(".rc-tree-treenode")
          .addClass("rc-tree-treenode-selected");
        if (touchtime == 0) {
          // set first click
          touchtime = new Date().getTime();
        } else {
          // compare first click to this click and see if they occurred within double click threshold
          const delta = new Date().getTime() - touchtime;
          if (delta < 400 && delta > 0) {
            // double click occurred
            // if(delta > 50){
            //     countClick++;

            // }else{
            //     countClick=1;

            // }
            countClick++;
            if (countClick > 1) {
              clearTimeout(seti);
              seti = setTimeout(function () {
                if (
                  countClick === 2 &&
                  !$(".dbClick").hasClass("dbClick-disbale")
                ) {
                  $(".dbClick").addClass("dbClick-disbale").trigger("click");
                  seflt
                    .parents(".rc-tree-treenode")
                    .addClass("rc-tree-treenode-selected");
                  countClick = 0;
                  touchtime = 0;
                }
              }, 0);
            }
          } else {
            clearTimeout(seti);
            $(".dbClick").removeClass("dbClick-disbale");
            // not a double click so set as a new first click
            touchtime = new Date().getTime();
          }
        }
        //clearTimeout(seti);
        /*var now = new Date().getTime();
                var lastTouch = $(this).data('lastTouch') || now + 1; //** the first time this will make delta a negative number ;
                var delta = now - lastTouch;
                if(delta<800 && delta>0){
                    if(delta > 150){
                        countClick++;
    
                    }else{
                        countClick=1;
    
                    }
                    // the second touchend event happened within half a second. Here is where we invoke the double tap code
                    clearTimeout(seti);
                    seti = setTimeout(function(){
                        if(countClick===2){
                            //$(".dbClick").trigger("click");
                            countClick = 0;
        
                        }
    
                    }, 0);
                }else{
                    countClick = 0;
                    clearTimeout(seti);
                        // A click/touch action could be invoked here but wee need to wait half a second before doing so.
                }
                $(this).data('lastTouch', now);*/
      });
    }
  } else {
    $(".rc-tree-title").on("click", function () {
      $(".rc-tree-treenode").removeClass("rc-tree-treenode-selected");
      $(this)
        .parents(".rc-tree-treenode")
        .addClass("rc-tree-treenode-selected");
    });
    $(".rc-tree-title").on("dblclick", function () {
      $(".dbClick").trigger("click");
    });
  }
};

export const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  if (window.focus) newWindow.focus();
};

export const popupCenterWithSso = ({ url, title, w, h, token }) => {
  const authorization = localStorage.getItem("authorization");
  console.log(url);
  console.log("authorization", authorization);

  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
        scrollbars=yes,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `
  );
  newWindow.document.getElementById("token").value = authorization;

  if (window.focus) {
    newWindow.focus();
  }
  if (token) {
    newWindow.postMessage({ token: authorization }, url);
  }

  return newWindow;
};

export const hPage = () => {
  let setHPage;
  hPageFun();
  $(window).on("resize", function () {
    clearTimeout(setHPage);
    setHPage = setTimeout(function () {
      hPageFun();
    }, 0);
  });
};
export const hPageFun = () => {
  if ($(window).height() > 760) {
    $("body").removeClass("page-mobi");
  } else {
    $("body").addClass("page-mobi");
    // var winh= $(window).height();
    // var hPage = $(".main-page").innerHeight();

    // var hMin = $(".main-page > .hm-sm-100").outerHeight();
    // if(hMin < hPage){
    //     $("body").removeClass("page-mobi");

    // }
  }
};
export const subjectMargintop = () => {
  let setsu = setInterval(() => {
    if ($(".subject-box").length > 0) {
      $(".subject-box").addClass("mt-15").removeClass("mta-1");
      clearInterval(setsu);
    }
  }, 100);
};
