import * as types from "./dataApprovalTableType"
import apiService from '../../util/apiService'

export const actGetDataApprovalTableRequest = (apiURL, params, callback) =>{
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res=>{
            if(res.data.data){
                dispacth(actGetDataApprovalTable(res.data.data));
                if(callback){
                    callback(res.data.data);
                }

            }
        })
    }
}
export const actGetDataApprovalTable = (items) =>{
    return {
        type: types.FETCH_APPROVE_TABLE,
        items
    }
}
export const actInsertDataApprovalTableRequest = (item, urlAPI) =>{
    return dispacth => {
        apiService(urlAPI, 'POST', item).then(res=>{
            dispacth(actInsertDataApprovalTable(res.data));
        })
    }

}
export const actInsertDataApprovalTable = (item) => {
    return {
        type: types.INSERT_APPROVE_TABLE,
        item
    }
}
export const actUpdateDataApprovalTableRequest = (item, urlAPI) =>{
    return dispacth => {
        apiService(urlAPI, "PUT", item).then(res=>{
            dispacth(actUpdateDataApprovalTable(res.data));
        })
    }
}
export const actUpdateDataApprovalTable = (item) => {
    return {
        type: types.UPDATE_APPROVE_TABLE,
        item
    }
}
export const actDeleteDataApprovalTableRequest = (id, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'DELETE', null).then(res=>{
            dispacth(actDeleteDataApprovalTable(id));
        })
    }
}
export const actDeleteDataApprovalTable = (id) => {
    return {
        type: types.DELETE_APPROVE_TABLE,
        id
    }
}
export const actMoveDataApprovalTable = (payload) => {
    return {
        type: types.MOVE_APPROVE_TABLE,
        payload
    }
}