import Axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '../src/components/common/language/i18next';
import './index.css';
import './css/fontawesome.min.css'
import './css/all.min.css'
import './css/brands.min.css'
import './css/solid.min.css'
import './css/bootstrap.min.css'
import './App.css';
import App from './App';
import './components/common/language/i18next';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { setConfigAxios, URL } from './util/config';

Axios.defaults.baseURL = URL;

setConfigAxios(store.dispatch);

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>
  , document.getElementById('root')
);


reportWebVitals();
