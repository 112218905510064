import * as types from './AnnualTypeSettingTypes';
import _ from 'lodash';
const initialState = {
    listOrganizations: [],
    contextATSFormData: {},
    listAnnualTypeSettings: [],
    listApplyRangesEmps: [],
    listApplyRangesOrganization: []
}
const annualTypeSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_ORGANIZATIONS:
            return {
                ...state,
                listOrganizations: action.data.listOrganizations
            }
        case types.CHANG_ATS_FORM_DATA:
            state.contextATSFormData = _.cloneDeep(action.payload);
            return {
                ...state
            }
        case types.GET_LIST_ANNUAL_TYPE_SETTINGS:
            let listAnnualTypeSettings = action.data;
            listAnnualTypeSettings?.forEach((element, index) => {
                element.rowNo = index + 1;
                if (_.isEmpty(element.useYn) || _.isNull(element.useYn)) {
                    element.useYn = 'Y';
                }
                if (_.isEmpty(element.limitAppliedDayTypeCode) || _.isNull(element.limitAppliedDayTypeCode)) {
                    element.limitAppliedDayTypeCode = 'NL';
                }
            })
            state.listAnnualTypeSettings = listAnnualTypeSettings;
            return { ...state }

        case types.ON_CHANGE_LIST_ANNUAL_TYPE_SETTINGS:
            state.listAnnualTypeSettings = action.payload.newListAnnualTypeSettings;
            return _.cloneDeep(state);

        case types.GET_LIST_APPY_RANGES:
            return {
                ...state,
                listApplyRangesEmps: action.data.listEmployees,
                listApplyRangesOrganization: action.data.listOrganizations
            }
        default: return state
    }
}
export default annualTypeSettingReducer;