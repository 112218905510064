import * as types from './VehicleRequestTypes';
import _ from 'lodash';
const initialState = {
    listVehicleReferences : [],
    vehicleReqestDetail :{},
    listVehicleOperator:[]
}
const vehicleRequestReducer = (state=initialState,action)=>{
    switch(action.type){
        case types.GET_LIST_VEHICLE_REFERENCE:
            return{
                ...state,
                listVehicleReferences : action.data.listVehicleReferences
            }
       
        case types.GET_VEHICLE_REQUESTS_DETAIL:
            state.vehicleReqestDetail = _.cloneDeep(action.data.vehicleReqestDetail);
            state.listVehicleOperator = _.cloneDeep(action.data.listVehicleOperator)
            return{
                ...state,
            }
           
        default: return state
    }
}
export default vehicleRequestReducer;