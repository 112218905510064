import * as types from "./tableRightType"

const initialSate = []
const getLangugeRightReducer = (state = initialSate, action) => {
    switch (action.type) {
        case types.GET_LANGUGE_RIGHT:
            return [...action.lan]
        default: return state
    }
}
export default getLangugeRightReducer;