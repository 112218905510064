
import * as types from './menuManagementTypes'
import _ from 'lodash';

const menuDetailDefault = {
    'menuCode': null,
    'parentMenuCode': null,
    'nLocaleCode': null,
    'menuName': null,
    'lstMenuName': null,
    'treeId': null,
    'treeIdHier': null,
    'menuBrn': null,
    'useYn': 'Y',
    'displayYn': 'Y',
    'treeLevel': null,
    'menuUrl': '',
    'note': '',
}

const initialState = {
    lstMenu: [],
    useYN: [],
    displayYN: [],
    lstLanguage: [],
    menuDetail: []

};


const menuManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MN_FETCH_LIST_MENU: {
            state.lstMenu = action.payload.lstMenu;
            return { ...state }
        }
        case types.MN_FETCH_USE_YN: {
            state.useYN = action.payload.useYN;
            return { ...state }
        }
        case types.MN_FETCH_DISPLAY_YN: {
            state.displayYN = action.payload.displayYN;
            return { ...state }
        }
        case types.MN_FETCH_LANGUAGE: {
            state.lstLanguage = action.payload.lstLanguage;
            return { ...state }
        }
        case types.MN_FETCH_MENU_DETAIL: {
            let { menuDetail } = action.payload;

            let tmpLanguage = _.cloneDeep(state.lstLanguage);
            for (const language of tmpLanguage) {
                for (const menuName of menuDetail.lstMenuName) {
                    if (language.detailCode === menuName.langCode) {
                        language['checked'] = true;
                        language['value'] = menuName.codeName;
                        break;
                    }
                }
                if (!language.checked) {
                    language['checked'] = false;
                    language['value'] = '';
                }
            }
            menuDetail.lstMenuName = tmpLanguage;
            state.menuDetail = menuDetail;
            return { ...state }
        }
        case types.MN_CREATE_NEW_MENU: {
            let selectedMenu = state.menuDetail;
            let menuDetail = _.cloneDeep(menuDetailDefault);
            if(!action.payload.isFirstLevel) {
                menuDetail.treeIdHier = selectedMenu.treeIdHier + '-';
                menuDetail.parentMenuCode = selectedMenu.menuCode;
                menuDetail.treeLevel = selectedMenu.treeLevel + 1;
            } else {
                menuDetail.treeLevel = 1;
                menuDetail.treeIdHier = null;
            }
            
            let tmpLanguage = _.cloneDeep(state.lstLanguage);
            for (const language of tmpLanguage) {
                language['checked'] = false;
                language['value'] = '';
            }
            menuDetail.lstMenuName = tmpLanguage;
            state.menuDetail = menuDetail;
            return { ...state }
        }
        case types.MN_EDIT_MENU_DETAIL:{
            state.menuDetail = _.cloneDeep(action.payload.menuDetail);
            return {...state};
        }            
        default: return { ...state }
    }

}
export default menuManagementReducer;

