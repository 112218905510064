export const findIndexKey = (listData, id) => {
    var result = -1;
    listData.forEach((listData, index) => {
        if (listData.key === id) {
            result = index;
        }
    });
    return result;
}
export const findIndexKeyArr = (listData, id) => {
    var result = -1;
    for(let i = 0; i < listData.length; i++){
        if (listData[i] === id) {
            result = i;
            break;
        }

    }
    return result;
}