import * as types from './ResignationDecisonTypes';
import _ from 'lodash';
const initialState = {
    resignationDecisionDetail :{}
}
const resignationDecisionReducer = (state=initialState,action)=>{
    switch(action.type){
        case types.GET_RESIGNATION_DECISION_DETAIL:
            return{
                ...state,
                resignationDecisionDetail : _.cloneDeep(action.data)
            }
        default: return state
    }
}
export default resignationDecisionReducer;