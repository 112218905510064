
export const WM_ON_CREATE_REPORT = 'WM_ON_CREATE_REPORT'

export const WM_TOGGLE_DIALOG_SELECT_TASK = 'WM_TOGGLE_DIALOG_SELECT_TASK'

export const WM_FETCH_JOB_DECRIPTION = 'WM_FETCH_JOB_DECRIPTION'

export const WM_SET_TYPE_WORK = 'WM_SET_TYPE_WORK'

export const WM_FETCH_PERFORMANCE_TYPE = 'WM_FETCH_PERFORMANCE_TYPE'

export const WM_ON_CHANGE_REPORT_INFO = 'WM_ON_CHANGE_REPORT_INFO'

export const WM_ON_SAVE_REPORT_INFO = 'WM_ON_SAVE_REPORT_INFO'

export const WM_IMPORT_SCHEDULE = 'WM_IMPORT_SCHEDULE'

export const WM_SET_DATA_FOR_MODIFY_PERIODIC_REPORT = 'WM_SET_DATA_FOR_MODIFY_PERIODIC_REPORT'

