import * as types from "./tableLeftType"

const initialSate = {}
const getRowTableLeftReducer = (state = initialSate, action) =>{
    switch(action.type){
        case types.GET_ROW_TABLE_LEFT: 
            return action.row
        default: return state
    }
}
export default getRowTableLeftReducer;