

export const RS_ON_CHANGE_SEARCH_OTPION = 'RS_ON_CHANGE_SEARCH_OTPION'

export const RS_FETCH_LIST_FORM_STATUS = 'RS_FETCH_LIST_FORM_STATUS'

export const RS_FETCH_LIST_DRAFT_FORM_SEARCH_TYPE = 'RS_FETCH_LIST_DRAFT_FORM_SEARCH_TYPE'

export const RS_FETCH_LIST_DAILY_REPORT_STATUS = 'RS_FETCH_LIST_DAILY_REPORT_STATUS'

export const RS_FETCH_DAILY_REPORT_DETAIL = 'RS_FETCH_DAILY_REPORT_DETAIL'

export const RS_FETCH_PERIODIC_REPORT_DETAIL = 'RS_FETCH_PERIODIC_REPORT_DETAIL'

export const RS_TOGGLE_MODIFY_REPORT_DIALOG = 'RS_TOGGLE_MODIFY_REPORT_DIALOG'

export const RS_SET_REPORT_TYPE = 'RS_SET_REPORT_TYPE'

export const RS_FETCH_PERFORMANCE_TYPE = 'RS_FETCH_PERFORMANCE_TYPE'

export const RS_UPDATE_REPORT_TYPE_FROM_APPROVAL = 'RS_UPDATE_REPORT_TYPE_FROM_APPROVAL'

export const RS_INIT_REPORT_DETAIL_PREVIEW = 'RS_INIT_REPORT_DETAIL_PREVIEW'
