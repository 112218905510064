import * as types from "./authenticationType";
import _ from "lodash";

const initialState = {
  isShowLogOut: false,
  lstLanguage: [],
  language: "",
  userInfo: {},
  //비밀번호찾기로인한 STATE추가
  findPasswordUserinfo: {},
};
const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AU_SHOW_HIDE_LOGOUT: {
      state.isShowLogOut = action.payload.isShow;
      return { ...state };
    }
    case types.AU_FETCH_LANGUAGE: {
      let langCode = localStorage.getItem("langCode");
      if (_.isEmpty(langCode)) {
        langCode = localStorage.getItem("useLangCode");
      }
      state.language = langCode;
      state.lstLanguage = action.payload.lstLanguage;
      return { ...state };
    }
    case types.AU_SET_SELECTETD_LANGUAGE: {
      state.language = action.payload.language;
      return { ...state };
    }
    case types.AU_TIME_OUT_REDIRECT_TO_LOGIN: {
      state.lstLanguage = [];
      state.isShowLogOut = false;
      state.language = "";
      state.userInfo = {};
      return { ...state };
    }
    case types.AU_SET_USER_INFO_AFTER_LOGIN: {
      state.userInfo = action.payload.userInfo;
      return { ...state };
    }
    case types.AU_GET_USER_INFO: {
      state.userInfo = action.payload.userInfo;
      if (_.isEmpty(localStorage.getItem("useLangCode"))) {
        localStorage.setItem("useLangCode", state.userInfo.useLangCode);
      }
      localStorage.setItem("organizationSeq", state.userInfo.cOrganizationSeq);
      localStorage.setItem(
        "departmentSeq",
        state.userInfo.departmentOrganizationSeq
      );
      localStorage.setItem("departmentName", state.userInfo.departmentName);
      return { ...state };
    }
    case types.AU_UPDATE_PASSWORD_WAS_CHANGED: {
      let { userInfo } = state;
      userInfo.requiredChangePasswordYn = "N";
      state.userInfo = _.cloneDeep(userInfo);
      return { ...state };
    }
    case types.FIND_USER_PASSWORD: {
      state.findPasswordUserinfo = action.payload.userInfo;
      return { ...state };
    }

    default:
      return { ...state };
  }
};

export default authenticationReducer;
