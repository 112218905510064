import * as types from "./treeViewType"
const initialSate = {
    keys: [],
    checkedKeys: [],
    expandedKeys: [],
    selectedKeys: []
}
const listKeysTreeViewReducer = (state = initialSate, action) => {
    switch (action.type) {
        case types.KEYS_TREEVIEW:
            return {
                ...state,
                keys: action.payload
            }

        case types.INSERT_KEYS_TREEVIEW:
            let listKeys = [...state.keys, action.payload]
            return {
                ...state,
                keys: listKeys
            }
        case types.EXPENDED_KEYS_TREEVIEW:
            return {
                ...state,
                expandedKeys: action.payload
            }
        case types.INSERT_EXPENDED_KEYS_TREEVIEW:
            let listExpandedKeys = [...state.expandedKeys, action.payload]
            return {
                ...state,
                expandedKeys: listExpandedKeys
            }
        case types.CHECKED_KEYS_TREEVIEW:
            return {
                ...state,
                checkedKeys: action.payload
            }
        case types.SELECT_KEYS_TREEVIEW:
            return {
                ...state,
                selectedKeys: action.payload
            }
        default: return state
    }
}
export default listKeysTreeViewReducer;