import _ from 'lodash';
import { sortList } from './../../../util/commonCodeSevice';
import * as types from './commonCodeType';
const initialState = {
    lstCommonCode: [],
    commonCodeDetail: {},
    lstLanguage: [],
    isShowDialog: false,
    selectTask: '',
    searchCondition: {}
};

const commonCodeDefaultObject = {
    codeName: '',
    codeType: 'CM',
    commonCode: '',
    enableDeleteYn: 'Y',
    enableUpdateYn: 'Y',
    lstCodeName: [],
    nLocaleCode: '',
    useYn: 'Y'
}
const commonCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LANGUAGE: {
            state.lstLanguage = action.payload;
            return { ...state };
        }

        case types.FETCH_COMMON_CODE_LIST: {
            state.lstCommonCode = action.payload;
            return { ...state };
        }

        case types.FETCH_COMMON_CODE_DETAIL: {
            let commonCodeDetail = action.payload;
            let { lstCodeName } = commonCodeDetail;
            let tmpLanguage = _.cloneDeep(state.lstLanguage)
            for (const language of tmpLanguage) {
                for (const codeName of lstCodeName) {
                    if (language.detailCode === codeName.langCode) {
                        language['checked'] = true;
                        language['value'] = codeName.codeName;
                        break;
                    }
                }
            }
            commonCodeDetail.lstCodeName = tmpLanguage;
            state.commonCodeDetail = commonCodeDetail;
            return { ...state };
        }

        case types.EDIT_DETAIL: {
            state.commonCodeDetail = action.payload;
            return { ...state };
        }
        case types.CREATE_COMMON_CODE: {
            let commonCodeDetailDefault = _.cloneDeep(commonCodeDefaultObject);
            let lstLanguage = _.cloneDeep(state.lstLanguage);
            lstLanguage.forEach(item => {
                item['checked'] = false;
                item['value'] = '';
            })
            commonCodeDetailDefault['categoryCode'] = state.selectTask;
            commonCodeDetailDefault.lstCodeName = lstLanguage;
            state.commonCodeDetail = commonCodeDetailDefault;
            return { ...state };
        }

        case types.TOGGLE_DIALOG_COMMON_CODE: {
            state.isShowDialog = !state.isShowDialog;
            return { ...state };
        }

        case types.SORT_ITEM: {
            let { lstCommonCode } = state;
            state.lstCommonCode = [...sortList(lstCommonCode, action.payload.column, action.payload.sortOrder)];
            return { ...state };
        }

        case types.SET_TASK_LIST: {
            state.lstTask = action.payload.lstTask
            return { ...state };
        }
        case types.CHANGE_SEARCH_CONDITION: {
            state.searchCondition = _.cloneDeep(action.payload.searchCondition)
            return { ...state };
        }

        case types.SET_CODE_TYPE: {
            state.searchCondition.codeType = action.payload.codeType
            return { ...state };
        }

        default: return { ...state };
    }
}

export default commonCodeReducer