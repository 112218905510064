export const menuService = (dataMenu) => {
    var treeData = [];
    var mapNode = {};
    buildNodeList(treeData, mapNode, dataMenu['lv1']);
    buildNodeList(treeData, mapNode, dataMenu['lv2']);
    buildNodeList(treeData, mapNode, dataMenu['lv3']);
    return treeData;
}

const buildNodeList = (treeData, mapNode, menuList) => {
    menuList.forEach(menuInfo => {
        var tempNode = {
            ...menuInfo,
            children: [],
        };
        mapNode[menuInfo.menuCode] = tempNode
        if (menuInfo.parentMenuCode !== 0 && mapNode[menuInfo.parentMenuCode]) {
            mapNode[menuInfo.parentMenuCode].children.push(tempNode);
        }
        if (tempNode.treeLevel === 1) {
            treeData.push(tempNode);
        }
    });
}