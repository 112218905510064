import { FormControl, IconButton, InputAdornment, Modal, OutlinedInput, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { actFetchUserInfoRequest, actShowHideLogout, actTimeOutRedirectToLogin, actUpdatePasswordWasChanged } from '../redux/authentication/authenticationAction';
import { actFetchMenus } from '../redux/menu/menuAction';
import apiService from '../util/apiService';
import { URL_BASE } from '../util/config';
import Crypto from '../util/crypto';
import { maskPage, unMaskPage } from '../util/jqueryFun';
import { alertError, alertSuccess, warning } from './common/popup/ConfirmPopup';

const PasswordChangeRequire = (props) => {
	const alertCapsLock = "<Caps Lock>이 켜져 있습니다"
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [focusPassword, setFocusPassword] = useState(true);
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);
	const [focusPasswordConfirm, setFocusPasswordConfirm] = useState(false);

	useEffect(() => {
		window.addEventListener('click', (event) => {
			if (event.getModifierState && isCapsLockOn !== event.getModifierState("CapsLock")) {
				setIsCapsLockOn(!isCapsLockOn);
			}
		});
		window.addEventListener('keydown', (event) => {
			if (event.getModifierState && isCapsLockOn !== event.getModifierState("CapsLock")) {
				setIsCapsLockOn(!isCapsLockOn);
			}
		});
		$("#password").bind("focusout", () => {
			setFocusPassword(false);
		});
		$("#password").bind("focusin", function (event) {
			setFocusPassword(true);
		});
		$("#passwordConfirm").bind("focusout", () => {
			setFocusPasswordConfirm(false);
		});
		$("#passwordConfirm").bind("focusin", function (event) {
			setFocusPasswordConfirm(true);
		});
	});

	const savePassword = () => {
		// let regex = /^(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{4,50}$/;
		let regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{4,50}$/;
		if (!regex.test(password)) return warning('empPopup.regexPassword.label');
		if (password === passwordConfirm) {
			const crypto = new Crypto();
			let apiUrl = '/employee/changePassword';
			let params = {
				employeeSeq: props.userInfo.employeeSeq,
				loginPassword: crypto.encrypt(password),
				isLoginPass: -1,
			}
			maskPage();
			apiService(apiUrl, "POST", params)
				.then(res => {
					props.updatePasswordWasChanged();
					alertSuccess('empPopup.changed.label');
				})
				.catch(() => {
					alertError('empPopup.changeFail.label');
				})
				.finally(() => unMaskPage())
		} else {
			warning('empPopup.matchPassword.label');
		}
	}

	const handelCancel = () => {
		maskPage();
		apiService("/authen/logout", "POST", null)
			.then(respone => { })
			.catch(() => { })
			.finally(() => {
				window.location.href = URL_BASE + '/front/login';
				localStorage.clear();
				props.fetchMenus([]);
				props.showHideLogout(false);
				props.timeOutRedirectToLogin();
				unMaskPage();
			})
	}

	return (
		<>
			<Modal
				open={props.userInfo?.requiredChangePasswordYn === 'Y'}
				aria-describedby="modal-description"
			>
				<div className="modal-dialog modalwm-825">
					<div className="modal-content modal2">
						<div className="modal-header modal-header-bg2">
							<h2 className="modal-title">
								Password change
							</h2>
						</div>
						<div className="modal-body">
							<div className="row row0 mta-1">
								<div className="item col-12">
									<div className="row row-form hmm-100 overflow-none">
										<div className="item col-auto name">
											<div className="h-center"><strong><Translation>{(t) => <> {t('employeeRegister.employeeDetail.field.employeePassword.label')} </>}</Translation></strong></div>
										</div>
										<div className="item col value overflow-none">
											<div className="h-center">
												<div className="form-ico">
													<i className="i-ico ico-key"></i>
													<FormControl className="tooltip-cap" variant="outlined" fullWidth>
														<Tooltip
															PopperProps={{
																disablePortal: true,
															}}
															open={isCapsLockOn && focusPassword}
															title={alertCapsLock}
														>
															<OutlinedInput
																autoFocus
																id='password'
																className={`form-controls ${password ? 'current' : ''}`}
																type={showPassword ? 'text' : 'password'}
																value={password}
																onChange={(e) => setPassword(e.target.value)}
																endAdornment={
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={(e) => setShowPassword(!showPassword)}
																			edge="end"
																		>
																			{showPassword ? <Visibility /> : <VisibilityOff />}
																		</IconButton>
																	</InputAdornment>
																}
															/>
														</Tooltip>
													</FormControl>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/**end row 1 */}
							<div className="row row0 mta-1">
								<div className="item col-12">
									<div className="row row-form hmm-100 overflow-none">
										<div className="item col-auto name">
											<div className="h-center"><strong><Translation>{(t) => <> {t('myPage.detailInf.confirmPassword.label')} </>}</Translation></strong></div>
										</div>
										<div className="item col value overflow-none">
											<div className="h-center">
												<div className="form-ico">
													<i className="i-ico ico-key"></i>
													<FormControl className="form-input tooltip-cap" variant="outlined" fullWidth>
														<Tooltip
															PopperProps={{
																disablePortal: true,
															}}
															open={isCapsLockOn && focusPasswordConfirm}
															title={alertCapsLock}
														>
															<OutlinedInput
																id='passwordConfirm'
																className={`form-controls ${passwordConfirm ? 'current' : ''}`}
																type={showPasswordConfirm ? 'text' : 'password'}
																value={passwordConfirm}
																onChange={(e) => setPasswordConfirm(e.target.value)}
																endAdornment={
																	<InputAdornment position="end">
																		<IconButton
																			aria-label="toggle password visibility"
																			onClick={(e) => setShowPasswordConfirm(!showPasswordConfirm)}
																			edge="end"
																		>
																			{showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
																		</IconButton>
																	</InputAdornment>
																}
															/>
														</Tooltip>
													</FormControl>

												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
							{/**end row 2 */}
							<ul className="list-note pt-10 pbb-5">
								<li><Translation>{(t) => <> {t('myPage.password.exp1.label')} </>}</Translation></li>
								<li><Translation>{(t) => <> {t('myPage.password.exp2.label')} </>}</Translation></li>
								<li><Translation>{(t) => <> {t('myPage.password.exp3.label')} </>}</Translation></li>
							</ul>

						</div>
						<div className="modal-footer justify-content-center">
							<button variant="contained" color="primary" className="btn btn-primarys wi-100"
								disabled={_.isEmpty(password) || _.isEmpty(passwordConfirm)}
								onClick={savePassword}
							>  <Translation>{(t) => <> {t('common.btn.ok.label')} </>}</Translation>
							</button>

							<button variant="contained" color="primary" className="btn btn-default4 wi-100 mlr-5"
								onClick={handelCancel}>
								<Translation>{(t) => <> {t('common.btn.cancel.label')} </>}</Translation>
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

const mapStateToProps = state => {
	return {
		userInfo: state.authentication.userInfo
	};
}

const mapDispatchToProps = dispatch => {
	return {
		getUserInfo: () => {
			dispatch(actFetchUserInfoRequest())
		},
		updatePasswordWasChanged: () => {
			dispatch(actUpdatePasswordWasChanged())
		},
		fetchMenus: (menu) => {
			dispatch(actFetchMenus(menu))
		},
		showHideLogout: (isShowLogOut) => {
			dispatch(actShowHideLogout(isShowLogOut))
		},
		timeOutRedirectToLogin: () => {
			dispatch(actTimeOutRedirectToLogin())
		},
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(PasswordChangeRequire);