import _ from "lodash";
import {
  alertError,
  alertSuccess,
} from "./../../../../components/common/popup/ConfirmPopup";
import * as types from "./scheduleStatusViewType";
import { formatDate, stringToDate } from "./../../../../util/date";

const initialState = {
  lstScheduleClass: [],
  selectedClass: "",
  lstDayOfWeek: [],
  isShowScheduleRegisterDialog: false,
  isShowSelectTaskDialog: false,
  isShowViewChartDialog: false,
  headerCalendar: [],
  startDayOfWeek: null,
  selectedMonth: new Date(),
  calendarRows: [],
  calendarStartEnd: {},
  lstTaskTab: [],
  tasktabSelected: {},
  taskSelected: {},
  lstTaskItem: [],
  lstPerformanceType: [],
  scheduleInfo: {},
  scheduleSelectTaskIndex: null,
  timeDefault: {},
  //20231215 departmentList
  departmentList: [],
  selectedDepartment: "",
};

const days = { 0: "SU", 1: "MO", 2: "TU", 3: "WE", 4: "TH", 5: "FR", 6: "SA" };

const scheduleStatusViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SW_FETCH_SCHEDULE_CLASSIFICATION: {
      state.lstScheduleClass = action.payload.lstScheduleClass;
      state.selectedClass = state.lstScheduleClass[0].detailCode;
      return { ...state };
    }
    case types.SW_FETCH_DAY_OF_WEEK: {
      state.lstDayOfWeek = action.payload.lstDayOfWeek;
      return { ...state };
    }
    case types.SW_FETCH_START_DAY_OF_WEEK: {
      state.startDayOfWeek = action.payload.startDayOfWeek;
      return { ...state };
    }
    case types.SW_FETCH_PERFORMANCE_TYPE: {
      state.lstPerformanceType = action.payload.lstPerformanceType;
      return { ...state };
    }
    case types.SW_SETTING_CALENDAR_HEADER: {
      let lstDayOfWeek = _.cloneDeep(state.lstDayOfWeek);
      let index = _.findIndex(
        lstDayOfWeek,
        (element) => element.detailCode === state.startDayOfWeek
      );
      let headerCalendar = lstDayOfWeek.slice(index, lstDayOfWeek.length);
      headerCalendar = headerCalendar.concat(lstDayOfWeek.slice(0, index));
      state.headerCalendar = headerCalendar;
      return { ...state };
    }
    case types.SW_CHANGE_MONTH: {
      let { selectedMonth } = action.payload;
      let { headerCalendar } = state;
      let firstDayOfMonth = new Date(
        selectedMonth.getFullYear(),
        selectedMonth.getMonth(),
        1
      );
      let lastDayOfMonth = new Date(
        selectedMonth.getFullYear(),
        selectedMonth.getMonth() + 1,
        0
      );
      let indexOfFirstDay = headerCalendar.findIndex(
        (item) => item.detailCode === days[firstDayOfMonth.getDay()]
      );
      let lstCalendarItem = [];
      let firstDateOfCalendar = firstDayOfMonth;
      if (indexOfFirstDay > 0) {
        firstDateOfCalendar = new Date(firstDayOfMonth);
        firstDateOfCalendar = new Date(
          firstDateOfCalendar.setDate(
            firstDateOfCalendar.getDate() - indexOfFirstDay
          )
        );
      }
      let n = lastDayOfMonth.getDate() + indexOfFirstDay > 35 ? 42 : 35;
      while (n > 0) {
        lstCalendarItem.push({
          scheduleDateYmd: new Date(firstDateOfCalendar),
        });
        firstDateOfCalendar.setDate(firstDateOfCalendar.getDate() + 1);
        n--;
      }

      let calendarStartEnd = {};
      calendarStartEnd.startDate = formatDate(
        lstCalendarItem[0].scheduleDateYmd
      );
      calendarStartEnd.endDate = formatDate(
        lstCalendarItem[lstCalendarItem.length - 1].scheduleDateYmd
      );
      let calendarRows = [];
      while (lstCalendarItem.length) {
        calendarRows.push(lstCalendarItem.splice(0, 7));
      }
      state.selectedMonth = selectedMonth;
      state.calendarRows = calendarRows;
      state.calendarStartEnd = calendarStartEnd;
      return { ...state };
    }

    case types.SW_TOGGLE_DIALOG_REGISTER_SCHEDULE: {
      state.isShowScheduleRegisterDialog = !state.isShowScheduleRegisterDialog;
      return { ...state };
    }
    case types.SW_TOGGLE_DIALOG_SELECT_TASK: {
      state.isShowSelectTaskDialog = !state.isShowSelectTaskDialog;
      return { ...state };
    }
    case types.SW_TOGGLE_DIALOG_VIEW_CHART: {
      state.isShowViewChartDialog = !state.isShowViewChartDialog;
      return { ...state };
    }
    case types.SW_FETCH_LIST_TASK_TAB: {
      state.lstTaskTab = action.payload.lstTaskTab;
      return { ...state };
    }
    case types.SW_FETCH_LIST_TASK_ITEM: {
      let { lstTaskItem, tasktabSelected } = action.payload;
      state.tasktabSelected = tasktabSelected;
      state.lstTaskItem = lstTaskItem;
      return { ...state };
    }

    case types.SW_FETCH_JOB_DECRIPTION: {
      let taskSelected = action.payload.jobDescription;
      let {
        scheduleSelectTaskIndex,
        scheduleInfo,
        tasktabSelected,
        lstPerformanceType,
      } = state;
      // let scheduleDetail = scheduleInfo.lstScheduleDetail[scheduleSelectTaskIndex];
      let scheduleDetail = _.cloneDeep(scheduleDetailObject);
      scheduleDetail.scheduleDetailName =
        tasktabSelected.bussinessName + " - " + taskSelected.bussinessName;
      scheduleDetail.jobSeq = taskSelected.jobSeq;
      scheduleDetail.lstJobPeriod = taskSelected.lstJobPeriod;
      taskSelected.lstJobChecklist?.forEach(
        (element) => (element.checked = false)
      );
      scheduleDetail.lstTaskDocument = taskSelected.lstAttachmentFile;
      scheduleDetail.performanceRateTypeCode = lstPerformanceType[0].detailCode;
      let lstTaskChecklist = [];
      let checkListObject;
      taskSelected.lstJobChecklist?.forEach((element, index) => {
        checkListObject = {
          checklistSeq: index + 1,
          orderSeq: index + 1,
          checklistName: element.checklistName,
          checkYn: "N",
        };
        lstTaskChecklist.push(checkListObject);
      });
      scheduleDetail.lstTaskChecklist = lstTaskChecklist;
      state.taskSelected = taskSelected;
      scheduleInfo.lstScheduleDetail[scheduleSelectTaskIndex] = scheduleDetail;
      state.scheduleInfo = _.cloneDeep(scheduleInfo);
      return { ...state };
    }
    case types.SW_CREATE_NEW_SCHEDULE: {
      state.isShowScheduleRegisterDialog = true;
      let scheduleInfo = _.cloneDeep(scheduleInfoObject);
      let lstScheduleDetail = [];
      lstScheduleDetail.push(_.cloneDeep(scheduleDetailObject));
      scheduleInfo.lstScheduleDetail = lstScheduleDetail;
      scheduleInfo.planTime = _.cloneDeep(state.timeDefault);
      scheduleInfo.completeTime = _.cloneDeep(state.timeDefault);
      scheduleInfo.scheduleDateYmd = formatDate(
        action.payload.selectedDate,
        "-"
      );
      state.scheduleInfo = scheduleInfo;
      state.taskSelected = {};
      return { ...state };
    }
    case types.SW_ADD_MORE_SCHEDULE_DETAIL: {
      let { scheduleInfo } = state;
      scheduleInfo.lstScheduleDetail.push(_.cloneDeep(scheduleDetailObject));
      state.scheduleInfo = _.cloneDeep(scheduleInfo);
      return { ...state };
    }
    case types.SW_DELETE_SCHEDULE_DETAIL: {
      let { index } = action.payload;
      let { scheduleInfo } = state;
      scheduleInfo.lstScheduleDetail.splice(index, 1);
      alertSuccess("popup.message.deleteSuccess.label");
      if (scheduleInfo.lstScheduleDetail.length === 0) {
        scheduleInfo.lstScheduleDetail.push(_.cloneDeep(scheduleDetailObject));
      }
      state.scheduleInfo = _.cloneDeep(scheduleInfo);
      return { ...state };
    }
    case types.SW_SET_SCHEDULE_SELECT_TASK_INDEX: {
      state.scheduleSelectTaskIndex = action.payload.scheduleIndex;
      return { ...state };
    }
    case types.SW_FETCH_COMPANY_ATTENDANCE_TIME: {
      let { timeDefault } = state;
      if (_.isEmpty(action.payload.companyAttendanceTime)) {
        timeDefault.startTimeHH = "00";
        timeDefault.startTimeMM = "00";
        timeDefault.endTimeHH = "00";
        timeDefault.endTimeMM = "00";
      } else {
        let { checkinHhmm, checkoutHhmm } =
          action.payload.companyAttendanceTime;
        let arrCheckinHhmm = checkinHhmm.split(":");
        let arrCheckoutHhmm = checkoutHhmm.split(":");
        timeDefault.startTimeHH = arrCheckinHhmm[0];
        timeDefault.startTimeMM = arrCheckinHhmm[1];
        timeDefault.endTimeHH = arrCheckoutHhmm[0];
        timeDefault.endTimeMM = arrCheckoutHhmm[1];
      }

      return { ...state };
    }
    case types.SW_ON_CHANGE_SCHEDULE: {
      state.scheduleInfo = _.cloneDeep(action.payload.scheduleInfo);
      return { ...state };
    }
    case types.SW_FETCH_SCHEDULE_DETAIL: {
      state.isShowScheduleRegisterDialog = true;

      let { scheduleInfo } = action.payload;

      let arrPlanStart = scheduleInfo.planStartttimeHhmm.split(":");
      let arrPlanEnd = scheduleInfo.planEndtimeHhmm.split(":");

      let arrcompleteStart = scheduleInfo.completeStarttimeHhmm.split(":");
      let arrcompleteEnd = scheduleInfo.completeEndtimeHhmm.split(":");

      scheduleInfo.planTime = {};
      scheduleInfo.planTime.startTimeHH = arrPlanStart[0];
      scheduleInfo.planTime.startTimeMM = arrPlanStart[1];
      scheduleInfo.planTime.endTimeHH = arrPlanEnd[0];
      scheduleInfo.planTime.endTimeMM = arrPlanEnd[1];

      scheduleInfo.completeTime = {};
      scheduleInfo.completeTime.startTimeHH = arrcompleteStart[0];
      scheduleInfo.completeTime.startTimeMM = arrcompleteStart[1];
      scheduleInfo.completeTime.endTimeHH = arrcompleteEnd[0];
      scheduleInfo.completeTime.endTimeMM = arrcompleteEnd[1];

      scheduleInfo.lstScheduleDetail?.forEach((element) => {
        let arrStartTime = element.starttimeHhmm.split(":");
        let arrEndTime = element.endtimeHhmm.split(":");
        element.startTimeHH = arrStartTime[0];
        element.startTimeMM = arrStartTime[1];
        element.endTimeHH = arrEndTime[0];
        element.endTimeMM = arrEndTime[1];
        element.error = _.cloneDeep(isScheduleDetailError);
        _.remove(
          element.lstJobPeriod,
          (element) => element.periodCode === null
        );
      });

      let ymd = scheduleInfo.scheduleDateYmd;
      scheduleInfo.scheduleDateYmd =
        ymd.substring(0, 4) +
        "-" +
        ymd.substring(4, 6) +
        "-" +
        ymd.substring(6, 8);
      scheduleInfo.error = _.cloneDeep(isScheduleError);
      state.scheduleInfo = scheduleInfo;
      return { ...state };
    }
    case types.SW_SAVE_SCHEDULE: {
      return { ...state };
    }
    case types.SW_DELETE_SCHEDULE: {
      state.isShowScheduleRegisterDialog = false;
      return { ...state };
    }
    case types.SW_FETCH_LIST_SCHEDULE: {
      let { lstSchedule, classification } = action.payload;
      state.selectedClass = classification;
      lstSchedule?.forEach(
        (element) =>
          (element.scheduleDateYmd = stringToDate(element.scheduleDateYmd))
      );
      let calendarRows = [];
      while (lstSchedule.length) {
        calendarRows.push(lstSchedule.splice(0, 7));
      }
      state.calendarRows = _.cloneDeep(calendarRows);
      return { ...state };
    }
    case types.SW_RESET_ERROR: {
      let { scheduleInfo } = state;
      scheduleInfo.error = _.cloneDeep(isScheduleError);
      scheduleInfo.lstScheduleDetail?.forEach((element) => {
        element.error = _.cloneDeep(isScheduleDetailError);
      });
      scheduleInfo.isError = false;
      state.scheduleInfo = scheduleInfo;
      return { ...state };
    }
    //20231215 부서별 리스트
    case types.SW_FETCH_DEPARTMENT_LIST: {
      let { departmentList } = action.payload;
      departmentList.unshift({
        workPlaceName: "전체",
        organizationCode: "AL",
      });
      state.departmentList = departmentList;
      state.selectedDepartment = state.departmentList[0].organizationCode;
      return { ...state };
    }
    case types.SW_CHANGE_DEPARTMENT: {
      state.selectedDepartment = action.payload;
      console.log(action.payload);
      return { ...state };
    }
    case types.SW_FETCH_LIST_SCHEDULE_BY_DEPARTMENT: {
      let { lstSchedule, classification, departmentCode } = action.payload;
      state.selectedClass = classification;
      state.selectedDepartment = departmentCode;
      if (lstSchedule.length <= 0) {
        alertError("해당 부서에 관련 요청자가 없습니다");
        return;
      }
      lstSchedule?.forEach(
        (element) =>
          (element.scheduleDateYmd = stringToDate(element.scheduleDateYmd))
      );
      let calendarRows = [];
      while (lstSchedule.length) {
        calendarRows.push(lstSchedule.splice(0, 7));
      }
      state.calendarRows = _.cloneDeep(calendarRows);
      return { ...state };
    }
    //
    default:
      return { ...state };
  }
};

export default scheduleStatusViewReducer;

const isScheduleError = {
  isScheduleNameError: false,
  isScheduedateYmdError: false,
  isPlanTimeError: false,
  isCompleteTime: false,
};
const isScheduleDetailError = {
  isScheduleDetailName: false,
  isTimeError: false,
  isPerformanceRateError: false,
};

const scheduleInfoObject = {
  scheduleSeq: null,
  scheduleName: "",
  employeeSeq: "",
  scheduleDateYmd: null,
  planTime: {},
  completeTime: {},
  deletedYn: "N",
  lstScheduleDetail: [],
  error: _.cloneDeep(isScheduleError),
  isError: false,
};

const scheduleDetailObject = {
  scheduleSeq: "",
  scheduleDetailSeq: "",
  scheduleDetailName: "",
  jobSeq: null,
  startTimeHH: "09",
  startTimeMM: "00",
  endTimeHH: "18",
  endTimeMM: "00",
  performanceRate: 0,
  performanceRateTypeCode: "",
  taskCycle: "",
  lstTaskDocument: [],
  lstTaskChecklist: [],
  description: "",
  lstAttachmentFile: [],
  error: _.cloneDeep(isScheduleDetailError),
};
