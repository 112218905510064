export const FETCH_GENERAL_COMMON_CODE_LIST = 'FETCH_GENERAL_COMMON_CODE_LIST'

export const FETCH_GENERAL_COMMON_CODE_DETAIL = 'FETCH_GENERAL_COMMON_CODE_DETAIL'

export const TOGGLE_DIALOG_GENERAL_CODE = 'TOGGLE_DIALOG_GENERAL_CODE'

export const FETCH_LANGUAGE = 'FETCH_LANGUAGE'

export const CREATE_GENERAL_COMMON_CODE = 'CREATE_GENERAL_COMMON_CODE'

export const ON_CHANGE_GENERAL_DETAIL = 'ON_CHANGE_GENERAL_DETAIL'

export const SORT_GENERAL = 'SORT_GENERAL'

export const SET_SELECT_COMMON_CODE = 'SET_SELECT_COMMON_CODE'

export const MOVE_UP_DOWN = 'MOVE_UP_DOWN'

export const REMOVE_ALL_FROM_LIST_GENERAL_CODE = 'REMOVE_ALL_FROM_LIST_GENERAL_CODE'