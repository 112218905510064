import * as types from "./authenticationType";
import { getLanguageRequest } from "./../../util/commonCodeSevice";
import apiService from "../../util/apiService";

export const actShowHideLogout = (isShow) => {
  return {
    type: types.AU_SHOW_HIDE_LOGOUT,
    payload: { isShow },
  };
};

export const actFetchLanguageRequest = () => {
  return (dispatch) => {
    return getLanguageRequest().then((res) => {
      dispatch(actFetchLanguage(res.data.data));
    });
  };
};

export const actFetchLanguage = (lstLanguage) => {
  return {
    type: types.AU_FETCH_LANGUAGE,
    payload: { lstLanguage },
  };
};

export const actSetSelectedLanguage = (language) => {
  return {
    type: types.AU_SET_SELECTETD_LANGUAGE,
    payload: { language },
  };
};

export const actTimeOutRedirectToLogin = () => {
  return {
    type: "USER_LOGOUT",
  };
};

export const actSetUserInfoAfterLogin = (userInfo) => {
  return {
    type: types.AU_SET_USER_INFO_AFTER_LOGIN,
    payload: { userInfo },
  };
};

export const actFetchUserInfoRequest = () => {
  return (dispatch) => {
    return apiService(`/authen/userInfo`, "GET", null).then((res) => {
      dispatch(actFetchUserInfo(res.data));
    });
  };
};

export const actFetchUserInfo = (userInfo) => {
  return {
    type: types.AU_GET_USER_INFO,
    payload: { userInfo },
  };
};

export const actUpdatePasswordWasChanged = () => {
  return {
    type: types.AU_UPDATE_PASSWORD_WAS_CHANGED,
  };
};

export const actFetchFindPasswordUserInfoRequest = (data) => {
  return (dispatch) => {
    return apiService(`/authen/checkAuthenticationCode`, "GET", { data }).then(
      (res) => {
        dispatch(actFetchUserInfo(res.data.userInfo));
      }
    );
  };
};

export const actUserFindPassword = (userInfo) => {
  console.log(userInfo);
  return {
    type: types.FIND_USER_PASSWORD,
    payload: { userInfo },
  };
};
