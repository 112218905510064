import * as types from "./dataApprovalTableType"
import * as utilFun from '../../util/findIndexUtil'
import _ from "lodash";

const initialSate = []
const dataApprovalTableReducer = (state = initialSate, action) =>{
    let{id, item, payload} = action;
    let index = -1;
    switch(action.type){
        case types.FETCH_APPROVE_TABLE: 
            state = action.items
            return [...state]
        case types.INSERT_APPROVE_TABLE:
            state.push(item)
            return [...state]
        case types.DELETE_APPROVE_TABLE:
            index = _.findIndex(state, element => element.key === id)
            state.splice(index,1);
            return [...state]
        case types.UPDATE_APPROVE_TABLE:
            index = utilFun.findIndex(state, id);
            state[index] = item;
            return [...state];
        case types.MOVE_APPROVE_TABLE:
            let f = state.splice(payload.from, 1)[0];
            // insert stored item into position `to`
            state.splice(payload.to, 0, f);
            return [...state];
        default: return state
    }
}
export default dataApprovalTableReducer;