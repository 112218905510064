import _ from 'lodash';
import { PAGE_SIZE, PAGE_SIZES } from '../../../../../util/config';
import { stringToDate } from "./../../../../../util/date";
import * as types from './businessTripActionTypes';

const initialState = {
    lstClassification: [],
    lstRequestForm: [],
    requestForm: {
        classification: 'DB',
        workSchedule: '',
        startDateYMD: new Date(),
        startTimeHH: '09',
        startTimeMM: '00',
        endDateYMD: new Date(),
        endTimeHH: '18',
        endTimeMM: '00',
        businessReason: '',
        other: '',
        error: {}
    },
    totalRow: 0,
    pageSizes: PAGE_SIZES,
    searchCondition: {
        title: '',
        startDate: null,
        endDate: null,
        page: 0,
        pageSize: PAGE_SIZE
    },
    resultForm: {
        requestUserFormSeq: null,
        workResult: '',
        other: ''
    }

}

const businessTripReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.BT_ON_CREATE_BUSSINESS_TRIP_RQUEST_FORM: {
            state.requestForm = _.cloneDeep(requestFormDefault);
            return { ...state };
        }
        case types.BT_ON_CREATE_BUSSINESS_TRIP_REQUEST_RESULT: {
            const currentDate = new Date();
            let { searchCondition } = state;
            searchCondition.endDate = currentDate;
            searchCondition.startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
            searchCondition.title = '';
            searchCondition.page = 0;
            searchCondition.pageSize = PAGE_SIZE;
            state.searchCondition = _.cloneDeep(searchCondition);
            state.requestForm = null;
            state.resultForm = _.cloneDeep(resultFormDefault);
            return { ...state };
        }
        case types.BT_FETCH_BUSSINESS_TRIP_CLASSIFICATION: {
            state.lstClassification = action.payload.lstClassification;
            return { ...state };
        }
        case types.BT_UPDATE_BUSSINESS_TRIP_DATA_ON_STATE: {
            state = action.payload.newState;
            return _.cloneDeep(state);
        }
        case types.BT_FETCH_BUSSINESS_TRIP_REQUEST_FORM: {
            let { requestForm } = action.payload;
            state.requestForm = _.cloneDeep(processRequestForm(requestForm));
            state.resultForm.requestUserFormSeq = requestForm.userFormSeq;
            return _.cloneDeep(state);
        }

        case types.BT_FETCH_LIST_BUSSINESS_TRIP_REQUEST_FORM: {
            state.lstRequestForm = action.payload.lstRequestForm;
            if (state.lstRequestForm.length === 0) {
                state.totalRow = 0;
            } else {
                state.totalRow = state.lstRequestForm[0].totalRow;
            }
            return { ...state };
        }

        case types.BT_FETCH_BUSSINESS_TRIP_RESULT_FORM_DETAIL: {
            let { resultFormDetail } = action.payload;
            const requestForm = processRequestForm(resultFormDetail);
            state.requestForm = requestForm;
            state.resultForm.userFormSeq = resultFormDetail.resultUserFormSeq;
            state.resultForm.requestUserFormSeq = resultFormDetail.requestUserFormSeq;
            state.resultForm.workResult = resultFormDetail.workResult;
            state.resultForm.other = resultFormDetail.resultOther;
            state.resultForm = _.cloneDeep(state.resultForm);
            return _.cloneDeep(state);
        }
        default: return { ...state }
    }
}

function processRequestForm(requestForm) {
    let arrStartDateHHMM = requestForm.startTimeHhmm.split(':');
    requestForm.startTimeHH = arrStartDateHHMM[0];
    requestForm.startTimeMM = arrStartDateHHMM[1];
    let arrEndDateHHMM = requestForm.endTimeHhmm.split(':');
    requestForm.endTimeHH = arrEndDateHHMM[0];
    requestForm.endTimeMM = arrEndDateHHMM[1];
    requestForm.startDateYMD = stringToDate(requestForm.startDateYMD);
    requestForm.endDateYMD = stringToDate(requestForm.endDateYMD);
    requestForm.error = _.cloneDeep(error);
    return requestForm;
}

const error = {
    classification: false,
    workSchedule: false,
    startDateYMD: false,
    endDateYMD: false,
    period: false,
    businessReason: false
}

const resultFormDefault = {
    requestUserFormSeq: null,
    workResult: '',
    other: ''
}
const requestFormDefault = {
    classification: 'DB',
    workSchedule: '',
    startDateYMD: new Date(),
    startTimeHH: '09',
    startTimeMM: '00',
    endDateYMD: new Date(),
    endTimeHH: '18',
    endTimeMM: '00',
    businessReason: '',
    other: '',
    error: _.cloneDeep(error)
}

export default businessTripReducer;