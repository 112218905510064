import * as types from './AttendanceInforSettingTypes';
import _, { templateSettings } from 'lodash';
const initialState = {
    listOrganizations:[],
    contextAFSData : {},
    checkAIS : 0,
    isOpenModal :false,
    listAttendanceInfoSetting : [],
    totalCount : 0,
    pageIndexSelection: 0,
    startIndex : 0,
    listWeekDays : []
}
const asfReducer = (state = initialState,action)=>{
    switch(action.type){
        case types.GET_LIST_ORGANIZATIONS:
            return{
                ...state,
                listOrganizations : action.data.listOrganizations
        }
        case types.CHANGE_AFS_FORM_DATA:
            state.contextAFSData = _.cloneDeep(action.payload);
            return{
                ...state
        }
        case types.CHECK_ATTENDANCE_INFOR_SETTING:
            return{
                ...state,
                checkAIS :action.payload
        }
        case types.OPEN_MODAL:
            return{
                ...state,
                isOpenModal : action.isOpenModal
            }
        case types.GET_LIST_ATTENDANCE_INFO_SETTING:
            return{
                ...state,
                listAttendanceInfoSetting : action.data.listAttendanceInfoSetting,
                totalCount : action.data.totalCount
            }
        case types.PAGING:
            return{
                ...state,
                pageIndexSelection: action.pageIndexSelection,
                startIndex : action.startIndex
            }
        case types.GET_LIST_WEEK_DAYS:
            return{
                ...state,
                listWeekDays : action.data
            }
        default : return state
    }
}
export default asfReducer;