
export const ATS_FETCH_ATTENDANCE_STATE = 'ATS_FETCH_ATTENDANCE_STATE'

export const ATS_FETCH_COMPANY_LIST = 'ATS_FETCH_COMPANY_LIST'

export const ATS_UPDATE_ATTENDANCE_REDUCER_STATE = 'ATS_UPDATE_ATTENDANCE_REDUCER_STATE'

export const ATS_CREATE_SEARCH_OPTION_DEFAULT = 'ATS_CREATE_SEARCH_OPTION_DEFAULT'

export const ATS_SEARCH_ATTENDANCE_STATIC = 'ATS_SEARCH_ATTENDANCE_STATIC'
