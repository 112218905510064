import apiService from './apiService';
import * as commonCode from './codeConstant';

export const getFamilyEventCodeByTreeCode = (commonCode, codeName) => {
    let params = {
        commonCode, useYn: 'Y'
    }
    if (codeName) {
        params.codeName = codeName
    }
  
    return apiService(`/main/getFamilyEventCodeByTreeCode.json`, 'GET', params);
}

export const getFamilyEventCodeByTreeCodeSecond = (commonCode, codeName) => {
    let params = {
        commonCode, useYn: 'Y'
    }
    if (codeName) {
        params.codeName = codeName
    }
    return apiService(`/main/getFamilyEventCodeByTreeCodeSecond.json`, 'GET', params);
}


export const getGeneralCodeByCommonCode = (commonCode, codeName) => {
    let params = {
        commonCode, useYn: 'Y'
    }
    if (codeName) {
        params.codeName = codeName
    }
    return apiService(`/main/getListGeneralCommonCode.json`, 'GET', params);
}

export const getCommonCodeRequestByCodeTypeAndCategory = (codeType, categoryCode, codeName) => {
    let params = {
        codeType, categoryCode
    }
    if (codeName) {
        params.codeName = codeName
    }
    return apiService(`/main/getListCommonCode.json`, 'GET', params);
}

export const getLanguageRequest = () => {
    return getGeneralCodeByCommonCode(commonCode.LANGUAGE);
}


export const sortList = (lstSort, column, sortOrder) => {
    let order = sortOrder === "asc" ? 1 : -1;
    return lstSort.sort((a, b) =>
        ((a[column] > b[column]) ? 1 : ((a[column] < b[column]) ? -1 : 0)) * order
    );
}