
import _ from 'lodash';
import * as types from './dailyReportRegisterType';
import { formatDate, formatStringDate } from "./../../../../util/date";

const initialState = {
    reportInfo: {}
};

const dailyReportRegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DL_FETCH_LIST_EMPLOYEE_SCHEDULE_FOR_DAILY_REPORT: {
            let { lstDailyReportFormSchedule } = action.payload;
            if (lstDailyReportFormSchedule.length === 0) return { ...state };
            let { reportInfo } = state;
            let firstStartPlanTime = '2350';
            let lastEndPlanTime = '0000';
            let firstStartCompleteTime = '2350';
            let lastEndCompleteTime = '0000';
            lstDailyReportFormSchedule.forEach(element => {
                let planStartttimeHhmm = element.planStartttimeHhmm.replace(":", '');
                if (planStartttimeHhmm < firstStartPlanTime) {
                    firstStartPlanTime = planStartttimeHhmm;
                }
                let planEndtimeHhmm = element.planEndtimeHhmm.replace(":", '');
                if (planEndtimeHhmm > lastEndPlanTime) {
                    lastEndPlanTime = planEndtimeHhmm;
                }
                let completeStarttimeHhmm = element.completeStarttimeHhmm.replace(":", '');
                if (completeStarttimeHhmm < firstStartCompleteTime) {
                    firstStartCompleteTime = completeStarttimeHhmm;
                }
                let completeEndtimeHhmm = element.completeEndtimeHhmm.replace(":", '');
                if (completeEndtimeHhmm > lastEndCompleteTime) {
                    lastEndCompleteTime = completeEndtimeHhmm;
                }
                element.lstScheduleDetail?.forEach(scheduleDetail => {
                    _.remove(scheduleDetail.lstJobPeriod, element => element.periodCode === null);
                })

            })

            reportInfo.planTime.startTimeHH = firstStartPlanTime.substring(0, 2);
            reportInfo.planTime.startTimeMM = firstStartPlanTime.substring(2, 4);
            reportInfo.planTime.endTimeHH = lastEndPlanTime.substring(0, 2);
            reportInfo.planTime.endTimeMM = lastEndPlanTime.substring(2, 4);
            reportInfo.completeTime.startTimeHH = firstStartCompleteTime.substring(0, 2);
            reportInfo.completeTime.startTimeMM = firstStartCompleteTime.substring(2, 4);
            reportInfo.completeTime.endTimeHH = lastEndCompleteTime.substring(0, 2);
            reportInfo.completeTime.endTimeMM = lastEndCompleteTime.substring(2, 4);

            reportInfo.lstDailyReportFormSchedule = lstDailyReportFormSchedule;
            state.reportInfo = _.cloneDeep(reportInfo);
            return { ...state };
        }
        case types.DL_CREATE_NEW_DAILY_REPORT: {
            state.reportInfo = _.cloneDeep(reportInfoObject);
            state.reportInfo.formTitle = [getDefaultTitle(), action.payload.localEmployeeName].join("_");
            return { ...state };
        }
        case types.DL_ON_CHANGE_DAILY_REPORT: {
            state.reportInfo = _.cloneDeep(action.payload.reportInfo);
            return { ...state };
        }
        case types.DL_MODIFY_DAILY_REPORT: {
            let reportInfo = _.cloneDeep(action.payload.reportInfo);
            if(_.isEmpty(reportInfo))  return { ...state };
            reportInfo.reportDateYmd = formatStringDate(reportInfo.reportDateYmd, '-');
            reportInfo.planTime = {};
            reportInfo.completeTime = {};
            reportInfo.planTime.startTimeHH = reportInfo.planStartttimeHhmm?.substring(0, 2);
            reportInfo.planTime.startTimeMM = reportInfo.planStartttimeHhmm?.substring(3, 5);
            reportInfo.planTime.endTimeHH = reportInfo.planEndtimeHhmm?.substring(0, 2);
            reportInfo.planTime.endTimeMM = reportInfo.planEndtimeHhmm?.substring(3, 5);
            reportInfo.completeTime.startTimeHH = reportInfo.completeStarttimeHhmm?.substring(0, 2);
            reportInfo.completeTime.startTimeMM = reportInfo.completeStarttimeHhmm?.substring(3, 5);
            reportInfo.completeTime.endTimeHH = reportInfo.completeEndtimeHhmm?.substring(0, 2);
            reportInfo.completeTime.endTimeMM = reportInfo.completeEndtimeHhmm?.substring(3, 5);
            reportInfo.error = _.clone(isDailyReportError);
            state.reportInfo = reportInfo;
            return { ...state };
        }
        default: return { ...state }
    }
}

const arrOfMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function getDefaultTitle() {
    let toDay = new Date();
    const langCode = localStorage.getItem('langCode');
    return langCode === 'KR'
        ? toDay.getFullYear() + '년 일일보고서 (' + (toDay.getMonth() + 1) + '월 ' + toDay.getDate() + '일)'
        : 'Daily Report for year ' + toDay.getFullYear() + ' (' + arrOfMonth[(toDay.getMonth())] + ' ' + toDay.getDate() + ')';
}

const isDailyReportError = {
    isReportDateYmdError: false,
    isPlanTimeError: false,
    isCompleteTimeError: false,
    isErrorFormTitle: false
}

const reportInfoObject = {
    isError: false,
    reportDateYmd: formatDate(new Date(), '-'),
    formTitle: getDefaultTitle(),
    planTime: {
        startTimeHH: '00',
        startTimeMM: '00',
        endTimeHH: '00',
        endTimeMM: '00'
    },
    completeTime: {
        startTimeHH: '00',
        startTimeMM: '00',
        endTimeHH: '00',
        endTimeMM: '00'
    },
    lstDailyReportFormSchedule: [],
    error: _.clone(isDailyReportError),
}

export default dailyReportRegisterReducer;

