import * as utilFunMenuCode from '../../util/findIndexRowsMenuCodeUtil'
import * as utilFun from '../../util/findIndexUtil'
import * as types from "./tableRightType"

const initialSate = []
const tableRightReducer = (state = initialSate, action) => {
    let { id, item } = action;
    let index = -1;
    switch (action.type) {
        case types.FETCH_TABLE_RIGHT:
            state = action.items
            return [...state]
        case types.INSERT_TABLE_RIGHT:
            state.unshift(item)
            return [...state]
        case types.DELETE_TABLE_RIGHT:
            index = utilFun.findIndex(state, id);
            state.splice(index, 1);
            return [...state]
        case types.UPDATE_TABLE_RIGHT:
            index = utilFun.findIndex(state, item.id);
            state[index] = item;
            return [...state];
        case types.UPDATE_MENUCODE_TABLE_RIGHT:
            index = utilFunMenuCode.findIndexMenuCode(state, item.menuCode);
            state[index] = item;
            return [...state];
        default: return state
    }
}
export default tableRightReducer;