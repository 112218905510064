import * as types from './corporateCardStatisticsTypes';
import _ from 'lodash';
const initialState = {
    contextCCSSearchFormData: {},
    listCorporateCardStatistics: [],
    totalCount: 0,
    pageIndexSelection: 0
}
const corporateCradStatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_CCS_SEARCH_FORM_DATA: {
            state.contextCCSSearchFormData = action.payload;
            return _.cloneDeep(state);
        }

        case types.GET_LIST_CORPORATE_CARD_STATISTICS: {
            state.totalCount = action.data.totalCount;
            state.listCorporateCardStatistics = action.data.listCorporateCardStatistics;
            return { ...state }
        }
        case types.PAGING_CARD_STATISTICS: {
            state.pageIndexSelection = action.pageIndexSelection;
            return { ...state }
        }
        case types.SET_TOTAL_AMOUNT: {
            const { totalAmount } = action.payload;
            state.contextCCSSearchFormData.totalAmountSum = totalAmount;
            return _.cloneDeep(state);
        }
        default: return state;
    }
}
export default corporateCradStatisticsReducer;