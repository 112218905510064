import i18next from "i18next"
import _ from 'lodash'
import { formatStringDate } from '../../util/date'
import * as utilFun from '../../util/findIndexUtil'
import { currencyFormatDE, formatPhoneNumber } from '../../util/numberFormatting'
import * as types from "./tableLeftType"

const initialSate = []
const tableLeftReducer = (state = initialSate, action) => {
    let { id, item } = action;
    let index = -1;
    switch (action.type) {
        case types.FETCH_TABLE_LEFT:
            if (_.isUndefined(action.items) || _.isEmpty(action.items) || _.isNull(action.items)) {
                state = [];
                return [...state]
            }
            state = action.items
            state?.forEach(element => {
                // element.birthdayYmd = formatStringDate(element.birthdayYmd, "-", true);
                // element.employeeStartDateYmd = formatStringDate(element.employeeStartDateYmd, "-", true);
                element.holidayYmd = formatStringDate(element.holidayYmd, "-", true);
                element.startDate = formatStringDate(element.startDate, "-", true) + ' ' + element.startTime;
                element.endDate = formatStringDate(element.endDate, "-", true) + ' ' + element.endTime;
                element.afterUsingInputYn = element.afterUsingInputYn === 'Y' ? i18next.t('vehicleStt.registered.label') : i18next.t('vehicleStt.notRegistered.label');
                element.totalMileage = currencyFormatDE(element.totalMileage + '', ',', true);
                
                // element.telNo = formatPhoneNumber(element.telNo);
                // element.homePhoneNo = formatPhoneNumber(element.homePhoneNo);
            })
            return [...state]

        case types.INSERT_TABLE_LEFT:
            state.unshift(item)
            return [...state]
        case types.DELETE_TABLE_LEFT:
            index = utilFun.findIndex(state, id);
            state.splice(index, 1);
            return [...state]
        case types.UPDATE_TABLE_LEFT:
            index = utilFun.findIndex(state, id);
            state[index] = item;
            return [...state];
        default: return state
    }
}
export default tableLeftReducer;