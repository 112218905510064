import axios from "axios";
import i18next from "i18next";
import { actUpdateMenuCountNumber } from "../redux/menu/menuAction";
import { alertError } from "./../components/common/popup/ConfirmPopup";
import Crypto from "./crypto";
import { unMaskPage } from "./jqueryFun";

const crypto = new Crypto();

// production(운영)
export const URL = "//po.edu-poly.com";
// export const URL = "http://localhost:8080";
export const URL_BASE = "";
export const FILE_SERVER_URL = "//po.edu-poly.com";
export const SLACK_URL = "https://edu-poly.slack.com/";
export const CRYPTO_SERCET_KEY = "IES_POLYON_PRODUCTION";
const ENABLE_ENCRYPT_REQUEST = false;
const ENABLE_ENCRYPT_RESPONSE = false;

// QA
// export const URL = "//uattest.indochinaedu.com/kpsGroupwareBack"
// export const URL_BASE = "/kpsGroupwareBack"
// export const FILE_SERVER_URL = "//uattest.indochinaedu.com/kpsGroupwareBack"
// export const SLACK_URL = "https://edu-poly.slack.com/"
// export const CRYPTO_SERCET_KEY = "IES_POLYON_UAT";
// const ENABLE_ENCRYPT_REQUEST = false
// const ENABLE_ENCRYPT_RESPONSE = false

// Dev
// export const URL = "http://localhost:8080";
// // export const URL = "//devpo.edu-poly.com";
// // export const URL = "http://192.168.50.138:8080";
// export const URL_BASE = "";
// export const FILE_SERVER_URL = "http://192.168.1.85:40016";
// export const SLACK_URL = "https://indochinaedu.slack.com/";
// export const CRYPTO_SERCET_KEY = "JARVIS_DEP_ZAI";
// // export const CRYPTO_SERCET_KEY = "IES_POLYON_UAT";
// const ENABLE_ENCRYPT_REQUEST = false;
// const ENABLE_ENCRYPT_RESPONSE = false;

// TESTER
// export const URL = "http://192.168.1.5:8080/kpsGroupwareBack"
// export const URL_BASE = "/kpsGroupwareBack"
// export const FILE_SERVER_URL = "http://192.168.1.85:40016"
// export const SLACK_URL = "https://indochinaedu.slack.com/"
// export const CRYPTO_SERCET_KEY = "JARVIS_DEP_ZAI";
// const ENABLE_ENCRYPT_REQUEST = false
// const ENABLE_ENCRYPT_RESPONSE = false

export const AUTHENTICATOR_SERCET_KEY = "3DPUMO5PLOFVBE64UKF7LM3BNAHXSMFJBJ";
export const TIME_ZONE_CODE = "GMT+09:00";
export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 15, 20, 30, 50, 100, 200];
export const DEFAULT_ORGANIZATION_SEQ_FOR_RANGE = 1;
export const DEFAULT_TIME_OUT = 1000 * 60 * 60 * 2;
export const FILE_TYPE_SUPPORT_PREVIEW = ["png", "jpg", "gif", "pdf", "jpeg"];
export const FILE_TYPE_SUPPORT_UPLOAD = [
  "doc",
  "docm",
  "docx",
  "dot",
  "pdf",
  "txt",
  "rtf",
  "csv",
  "xls",
  "xlsb",
  "xlsm",
  "xlsx",
  "pptx",
  "ppt",
  "one",
  "xps",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "zip",
  "rar",
  "tar",
  "7z",
  "gz",
];

export const getFormatDate = () => {
  return i18next.language === "kr" ? "yyyy-MM-dd" : "MM-dd-yyyy";
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const setConfigAxios = (dispatch) => {
  axios.interceptors.request.use(
    (request) => {
      let headers = request.headers;
      headers["Authorization"] = localStorage.getItem("authorization");
      headers["LANG-CODE"] = localStorage.getItem("langCode");
      Object.assign(request.headers, headers);
      if (
        ENABLE_ENCRYPT_REQUEST &&
        request.data &&
        request.method !== "get" &&
        request.data !== null
      ) {
        request.data = { data: crypto.encrypt(JSON.stringify(request.data)) };
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      try {
        if (response.config.url === "/GeneralForm/getUserFormInfo") {
          dispatch(actUpdateMenuCountNumber());
        }
        if (ENABLE_ENCRYPT_RESPONSE) {
          response.data = JSON.parse(crypto.decrypt(response.data.toString()));
        }
      } catch (error) {
      } finally {
        return response;
      }
    },
    async (error) => {
      unMaskPage();
      const originalRequest = error.config;
      let arrPath = window.location.pathname.split("/");
      if (!error.response) {
        alertError("popup.message.netWorkError.label");
      } else if (error.response.config.url === "/authen/refeshToken") {
        localStorage.clear();
        alertError("popup.message.sessionExpried.label");
      } else if (
        error.response.status === 403 &&
        !arrPath.includes("403") &&
        !arrPath.includes("home")
      ) {
        window.location.href = URL_BASE + "/front/403";
      } else if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        arrPath[arrPath.length - 1] !== "login"
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const loginInfo = {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        };
        return new Promise(function (resolve, reject) {
          axios
            .post("/authen/refeshToken", loginInfo)
            .then((res) => {
              localStorage.setItem("authorization", res.data);
              processQueue(null, res.data);
              resolve(axios(originalRequest));
            })
            .catch((error) => {
              processQueue(error, null);
              reject(error);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );
};

export const getListKeys = (listKey, data) => {
  for (let i = 0; i < data.length; i++) {
    const children = data[i].children;
    if (children && children.length > 0) {
      listKey.push(data[i].key);
      //dispacths(actInsertKeysTreeView(data[i].key))
      getListKeys(listKey, data[i].children);
    }
  }
  return listKey;
};
