import i18next from 'i18next';
import _ from 'lodash';
import * as types from './attendanceStaticActionTypes';

const currentDate = new Date();
const initialState = {
    lstAttendanceStatic: [],
    lstAttendanceStaticApply: [],
    lstCompany: [],
    searchOption: {
        statusGroupCode: null,
        organizationSeq: null,
        organizationName: null,
        startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        endDate: new Date(),
        rangeList: []
    },
    lastSearchOption: null,
    lstAttendance: [],
    isOpenModifyDialog: false,
}

const attendanceStaticReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ATS_FETCH_ATTENDANCE_STATE: {
            state.lstAttendanceStatic = action.payload.lstAttendanceStatic;
            state.lstAttendanceStatic?.unshift({
                detailCode: '0',
                codeName: i18next.t('empGrid.optAll.label')
            })
            state.searchOption.statusGroupCode = '0';
            state.lstAttendanceStaticApply = _.cloneDeep(state.lstAttendanceStatic);
            return _.cloneDeep(state);
        }

        case types.ATS_FETCH_COMPANY_LIST: {
            let {lstCompany } = action.payload;
            state.lstCompany = lstCompany;
            const defaultOrganization = _.find(lstCompany, element => element.organizationSeq == localStorage.getItem("organizationSeq"));
            if(!_.isUndefined(defaultOrganization)) {
                const defaultRangeItem = {
                    targetType: "OR",
                    targetSeq: defaultOrganization.organizationSeq,
                    orderSeq: 1,
                    organizationName: defaultOrganization.organizationName
                }
                state.searchOption.rangeList = [defaultRangeItem];
            }
            state.searchOption.organizationSeq = defaultOrganization?.organizationSeq;
            state.searchOption.organizationName = defaultOrganization?.organizationName;
            return _.cloneDeep(state);
        }

        case types.ATS_UPDATE_ATTENDANCE_REDUCER_STATE: {
            state = action.payload.newState;
            return _.cloneDeep(state);
        }

        case types.ATS_CREATE_SEARCH_OPTION_DEFAULT: {
            state.lstAttendance = [];
            return { ...state };
        }

        case types.ATS_SEARCH_ATTENDANCE_STATIC: {
            if (state.searchOption.statusGroupCode === '0') {
                state.lstAttendanceStaticApply = _.cloneDeep(state.lstAttendanceStatic);
            } else {
                state.lstAttendanceStaticApply = [_.find(state.lstAttendanceStatic, element => element.detailCode === state.searchOption.statusGroupCode)];
            }
            state.lstAttendance = action.payload.lstAttendance;
            return { ...state };
        }

        default:
            return { ...state };
    }
}

export default attendanceStaticReducer;
