
export const OT_FETCH_ATTENDANCE_STATE = 'OT_FETCH_ATTENDANCE_STATE'

export const OT_FETCH_COMPANY_LIST = 'OT_FETCH_COMPANY_LIST'

export const OT_UPDATE_ATTENDANCE_REDUCER_STATE = 'OT_UPDATE_ATTENDANCE_REDUCER_STATE'

export const OT_CREATE_SEARCH_OPTION_DEFAULT = 'OT_CREATE_SEARCH_OPTION_DEFAULT'

export const OT_SEARCH_OVER_TIME = 'OT_SEARCH_OVER_TIME'

export const OT_SET_START_DAY_OF_WEEK = 'OT_SET_START_DAY_OF_WEEK'

export const OT_SET_RANGE_DATE = 'OT_SET_RANGE_DATE'

export const OT_SEND_EMAIL = 'OT_SEND_EMAIL'

export const OT_SEARCH_OVER_TIME_TOTAL = 'OT_SEARCH_OVER_TIME_TOTAL'