import * as types from './ResignationRequestTypes';
import _ from 'lodash';
const initialState = {
    resignationDetail :{}
}
const resignationRequestReducer = (state=initialState,action)=>{
    switch(action.type){
        case types.GET_RESIGNATION_REQUEST_DETAIL:
            return{
                ...state,
                resignationDetail : _.cloneDeep(action.data)
            }
        default: return state
    }
}
export default resignationRequestReducer;