
export const JD_GET_TREE_DATA_ORGANIZATION_JOB = 'JD_GET_TREE_DATA_ORGANIZATION_JOB'

export const JD_FETCH_LANGUAGE = 'JD_FETCH_LANGUAGE'

export const JD_FETCH_USEAGE = 'JD_FETCH_USEAGE'

export const JD_FETCH_BUSSINESS_PREIOD= 'JD_FETCH_BUSSINESS_PREIOD'

export const JD_FETCH_JOB_DECRIPTION= 'JD_FETCH_JOB_DECRIPTION'

export const JD_ON_CHANGE_JOB_DECRIPTION= 'JD_ON_CHANGE_JOB_DECRIPTION'

export const JD_CREATE_NEW_JOB_DECRIPTION= 'JD_CREATE_NEW_JOB_DECRIPTION'

export const JD_SAVE_JOB_DECRIPTION= 'JD_SAVE_JOB_DECRIPTION'

export const JD_DELETE_JOB_DECRIPTION= 'JD_DELETE_JOB_DECRIPTION'

export const JD_TOGGLE_DIALOG_CHART= 'JD_TOGGLE_DIALOG_CHART'

export const JD_FETCH_DATA_CHART= 'JD_FETCH_DATA_CHART'

export const JD_UPDATE_LIST_EXPANNODE = 'JD_UPDATE_LIST_EXPANNODE'

export const JD_SET_LAST_SELECTED_NODE = 'JD_SET_LAST_SELECTED_NODE'

