import * as types from './TrainingReportTypes';
import _ from 'lodash';
const initialState = {
    isOpenModal : false,
    startIndex : 0,
    pageIndexSelection : 0,
    listTrainingRequest: [],
    totalCount : 0,
    contextTrainingReportFormData : {},
    trainingReportDetail : {}
}
const trainingReportReducer = (state=initialState,action)=>{
    switch(action.type){
        case types.IS_OPEN_MODAL:
            return{
                ...state,
                isOpenModal : action.payload
            }
        case types.PAGING:
            return{
                ...state,
                startIndex : action.startIndex,
                pageIndexSelection :action.pageIndexSelection
            }
        case types.GET_LIST_TRAINING_REQUEST_DETAIL:
            return{
                ...state,
                listTrainingRequest:action.data.listTrainingRequest,
                totalCount : action.data.totalCount && action.data.totalCount[0] ?action.data.totalCount[0].totalCount :0
            }
        case types.CHANGE_TRAINING_REPORT_FORM_DATA:
            state.contextTrainingReportFormData = _.cloneDeep(action.payload)
            return{
                ...state
            }
        case types.GET_TRAINING_REPORT_DETAIL:
            state.trainingReportDetail = _.cloneDeep(action.data)
            return{
                ...state
            }
        default: return initialState
    }
}
export default trainingReportReducer;