import * as types from './personalAttendanceStatusActionType'
import { formatDate, getDateAtStartOfMonth, getDateAtEndOfMonth } from '../../../util/date';

const initialSate = {
    startDate: getDateAtStartOfMonth(),
    endDate: getDateAtEndOfMonth()
}
const personalAttendanceStatusReducer = (state = initialSate, action) => {
    switch (action.type) {
        case types.PAS_UPDATE_STATE: {
            state = action.payload.newState;
            return { ...state };
        }
        case types.PAS_FETCH_LIST_PERSONAL_ATTENDANCE_STATUS: {
            state.lstPersonalAttendance = action.payload.lstPersonalAttendance;
            state.total = action.payload.total;
            return { ...state };
        }
        default:
            return { ...state };
    }
}

export default personalAttendanceStatusReducer;