
export const FETCH_FIRST_LEVEL_TREE_COMMON_CODE= 'FETCH_FIRST_LEVEL_TREE_COMMON_CODE'

export const FETCH_LANGUAGE = 'FETCH_LANGUAGE'

export const FETCH_USER_POSSIBLE = 'FETCH_USER_POSSIBLE'

export const FETCH_USER_ABILITY = 'FETCH_USER_ABILITY'

export const SET_SELECT_ROW = 'SET_SELECT_ROW'

export const TOGGLE_DIALOG_TREE_CODE = 'TOGGLE_DIALOG_TREE_CODE'

export const FETCH_TREE_COMMON_CODE_DETAIL = 'FETCH_TREE_COMMON_CODE_DETAIL'

export const FETCH_SUB_LEVEL_TREE_COMMON_CODE= 'FETCH_SUB_LEVEL_TREE_COMMON_CODE'

export const CREATE_TREE_COMMON_CODE = 'CREATE_TREE_COMMON_CODE'

export const ON_CHANGE_TREE_DETAIL = 'ON_CHANGE_TREE_DETAIL'

export const SET_SELECT_COMMON_CODE_OF_TREE = 'SET_SELECT_COMMON_CODE_OF_TREE'

export const SAVE_TREE_COMMON_CODE = 'SAVE_TREE_COMMON_CODE'

export const DELETE_TREE_FIRST_LEVEL = 'DELETE_TREE_FIRST_LEVEL'

export const DELETE_TREE_SUB_LEVEL = 'DELETE_TREE_SUB_LEVEL'

export const SORT_TREE_FIRST_LEVEL = 'SORT_TREE_FIRST_LEVEL'

export const REMOVE_ALL_WHEN_CHANGE_COMMON_CODE = 'REMOVE_ALL_WHEN_CHANGE_COMMON_CODE'

export const SET_SELECT_FIRST_LEVEL_DETAIL_AFTER_SWAP = 'SET_SELECT_FIRST_LEVEL_DETAIL_AFTER_SWAP'