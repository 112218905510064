import { isEmpty, isUndefined } from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AppComponentContainer from "./components/AppComponentContainer";
import PasswordChangeRequire from "./components/PasswordChangeRequire";
import {
  actFetchUserInfoRequest,
  actShowHideLogout,
} from "./redux/authentication/authenticationAction";
import { hPage } from "./util/jqueryFun";
import HttpsRedirect from "react-https-redirect";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      user: {},
    };
    if (
      ![
        "/kpsGroupwareBack/front/login",
        "/kpsGroupwareBack",
        "/kpsGroupwareBack/",
        "/front/login",
        "",
        "/",
      ].includes(window.location.pathname) &&
      !window.location.pathname.includes("IndividualForm")
    ) {
      this.reloadPage();
    }
  }

  reloadPage = () => {
    if (window.performance) {
      if (
        !isUndefined(
          [0, 1, 2],
          (element) => element === window.performance.navigation.type
        )
      ) {
        this.props.getUserInfo();
      }
    }
  };

  componentDidMount() {
    if (!isEmpty(localStorage.getItem("authorization"))) {
      this.props.showHideLogout(true);
    } else {
      this.props.showHideLogout(false);
    }
    hPage();
  }

  static getDerivedStateFromProps(props, state) {
    hPage();
    return null;
  }

  render() {
    return (
      // 운영배포시
      <HttpsRedirect>
        <>
          {this.props.userInfo?.requiredChangePasswordYn !== "Y" && (
            <Router basename={process.env.PUBLIC_URL + "/front"}>
              <AppComponentContainer />
            </Router>
          )}
          <PasswordChangeRequire />
        </>
      </HttpsRedirect>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.authentication.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showHideLogout: (isShowLogOut) => {
      dispatch(actShowHideLogout(isShowLogOut));
    },
    getUserInfo: () => {
      dispatch(actFetchUserInfoRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
