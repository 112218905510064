import * as types from './jobDescriptionType'
import _ from 'lodash';

const initialState = {
    lstTreeDataOrganizationJob: [],
    jobDescription: {},
    lstLanguage: [],
    lstUseYn: [],
    lstBussinessPreiod: [],
    isShowDialog: false,
    organizationSeq: null,
    lstExpanNode: [],
    lastSelectedNode: null
};

const jobDescriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.JD_FETCH_LANGUAGE: {
            state.lstLanguage = action.payload.lstLanguage
            return { ...state };
        }
        case types.JD_FETCH_USEAGE: {
            state.lstUseYn = action.payload.lstUseYn
            return { ...state };
        }
        case types.JD_FETCH_BUSSINESS_PREIOD: {
            state.lstBussinessPreiod = action.payload.lstBussinessPreiod;
            return { ...state };
        }
        case types.JD_FETCH_JOB_DECRIPTION: {
            let { jobDescription } = action.payload;
            if (_.isEmpty(jobDescription)) {
                state.jobDescription = {};
                return { ...state };
            }
            jobDescription['files'] = [];
            let { lstBusinessName, lstJobPeriod, lstJobChecklist } = jobDescription;
            let { lstLanguage, lstBussinessPreiod } = state;
            let tmpLanguage = _.cloneDeep(lstLanguage);

            for (const language of tmpLanguage) {
                for (const businessName of lstBusinessName) {
                    if (language.detailCode === businessName.langCode) {
                        language['checked'] = true;
                        language['value'] = businessName.codeName;
                        break;
                    }
                }
                if (!language.checked) {
                    language['checked'] = false;
                    language['value'] = '';
                }
            }
            jobDescription.lstBusinessName = tmpLanguage;

            let tmpPeriod = _.cloneDeep(lstBussinessPreiod);
            for (const periodCode of tmpPeriod) {
                for (const jobPeriod of lstJobPeriod) {
                    if (periodCode.detailCode === jobPeriod.periodCode) {
                        periodCode['checked'] = true;
                        periodCode['value'] = jobPeriod.periodCodeDetail;
                        break;
                    }
                }
                if (!periodCode.checked) {
                    periodCode['checked'] = false;
                    periodCode['value'] = '';
                }
            }
            jobDescription.lstJobPeriod = tmpPeriod;

            if (lstJobChecklist === null || lstJobChecklist.length === 0) {
                lstJobChecklist = [{ 'orderSeq': 1, 'checklistName': '', 'checked': false }];
            } else {
                lstJobChecklist.forEach(element => element['checked'] = false);
            }
            jobDescription.lstJobChecklist = lstJobChecklist;

            state.jobDescription = jobDescription;
            return { ...state };
        }
        case types.JD_ON_CHANGE_JOB_DECRIPTION: {
            let jobDescription = action.payload.jobDescription;
            state.jobDescription = { ...jobDescription }
            return { ...state };
        }

        case types.JD_CREATE_NEW_JOB_DECRIPTION: {
            let jobDescription = action.payload.defaultJobInfo;
            let { lstLanguage, lstBussinessPreiod } = state;
            let tmpLanguage = _.cloneDeep(lstLanguage);

            for (const language of tmpLanguage) {
                language['checked'] = false;
                language['value'] = '';
            }
            let tmpPeriod = _.cloneDeep(lstBussinessPreiod);
            for (const periodCode of tmpPeriod) {
                periodCode['checked'] = false;
                periodCode['value'] = '';
            }
            jobDescription['lstBusinessName'] = tmpLanguage;
            jobDescription['lstJobPeriod'] = tmpPeriod;
            jobDescription['lstJobChecklist'] = [{ 'orderSeq': 1, 'checklistName': '', 'checked': false }]
            jobDescription['jobSeq'] = null;
            jobDescription['bussinessCode'] = null;
            jobDescription['orderSeq'] = '';
            jobDescription['lstAttachmentFile'] = [];

            state.jobDescription = jobDescription;
            return { ...state };
        }
        case types.JD_SAVE_JOB_DECRIPTION: {
            return { ...state };
        }

        case types.JD_GET_TREE_DATA_ORGANIZATION_JOB: {
            state.lstTreeDataOrganizationJob = action.payload.treeDataOrganizationJob;
            state.organizationSeq = action.payload.organizationSeq;
            if(!_.isUndefined(action.payload.isChangeOragnization) && action.payload.isChangeOragnization === true) {
                state.lastSelectedNode = null;
                state.lstExpanNode = [];
            }
            return { ...state };
        }
        case types.JD_DELETE_JOB_DECRIPTION: {
            state.jobDescription = {};
            return { ...state };
        }
        case types.JD_TOGGLE_DIALOG_CHART: {
            state.isShowDialog = !state.isShowDialog;
            return { ...state };
        }
        case types.JD_UPDATE_LIST_EXPANNODE: {
            state.lstExpanNode = action.payload.lstExpanNode;
            return { ...state };
        }
        case types.JD_SET_LAST_SELECTED_NODE: {
            state.lastSelectedNode = action.payload.lastSelectedNode;
            return { ...state };
        }
        default: return { ...state }
    }
}

export default jobDescriptionReducer;
