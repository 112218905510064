import _ from "lodash";
import { stringToDate } from "../../../../util/date";
import * as code from "./../../../../util/codeConstant";
import * as types from "./weeklyMonthlyReportRegisterType";

Date.prototype.getWeekNumber = function () {
  var d = new Date(
    Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
  );
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

const initialState = {
  reportInfo: {},
  isShowSelectTaskDialog: false,
  workType: "",
  lstPerformanceType: [],
  reportType: "",
};

const weeklyMonthlyReportRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.WM_ON_CREATE_REPORT: {
      let { reportType } = action.payload;
      let reportInfo = _.cloneDeep(reportInfoObject);
      if (reportType === code.REPORT_TYPE.WEEKLY) {
        reportInfo = setWeeklyDefaultValue(reportInfo);
      } else if (reportType === code.REPORT_TYPE.MONTHLY) {
        reportInfo = setMonthlyDefaultValue(reportInfo);
      }
      state.reportInfo = reportInfo;
      state.reportType = reportType;
      return { ...state };
    }
    case types.WM_TOGGLE_DIALOG_SELECT_TASK: {
      state.isShowSelectTaskDialog = !state.isShowSelectTaskDialog;
      return { ...state };
    }
    case types.WM_FETCH_PERFORMANCE_TYPE: {
      state.lstPerformanceType = action.payload.lstPerformanceType;
      return { ...state };
    }
    case types.WM_FETCH_JOB_DECRIPTION: {
      let task = action.payload.jobDescription;
      let parentTask = action.payload.tasktabSelected;
      let { reportInfo, workType, lstPerformanceType } = state;
      let workObject = {
        checked: false,
        parentJobSeq: parentTask.jobSeq,
        parentBussinessName: parentTask.bussinessName,
        jobSeq: task.jobSeq,
        bussinessName: task.bussinessName,
        detailTask: "",
        deadlineYYYYMMDD: null,
        performanceRate: 0,
        performanceType: lstPerformanceType[0].detailCode,
        repairdPlanYn: "",
      };
      if (workType === PERFORMANCE_TYPE) {
        workObject.reportType = code.REPORT_WORK_TYPE.PERFORMANCE;
        reportInfo.lstWorkPerformance.push(workObject);
        reportInfo.isCheckAllWorkPerformance = false;
      } else {
        workObject.reportType = code.REPORT_WORK_TYPE.PLAN;
        reportInfo.lstWorkPlan.push(workObject);
        reportInfo.isCheckAllWorkPlan = false;
      }
      reportInfo.error.isErrorWorkPerformance = false;
      state.reportInfo = _.cloneDeep(reportInfo);
      return { ...state };
    }
    case types.WM_SET_TYPE_WORK: {
      state.workType = action.payload.workType;
      return { ...state };
    }
    case types.WM_ON_CHANGE_REPORT_INFO: {
      state.reportInfo = _.cloneDeep(action.payload.reportInfo);
      return _.cloneDeep(state);
    }
    case types.WM_IMPORT_SCHEDULE: {
      let lstWorkImport = action.payload.lstWorkImport;
      let { reportInfo } = state;
      lstWorkImport.forEach((element) => {
        element.checked = false;
        element.deadlineYYYYMMDD = stringToDate(element.deadlineYYYYMMDD);
        element.reportType = code.REPORT_WORK_TYPE.PERFORMANCE;
      });
      reportInfo.lstWorkPerformance = lstWorkImport;
      if (!_.isEmpty(lstWorkImport)) {
        reportInfo.error.isErrorWorkPerformance = false;
      }
      state.reportInfo = _.cloneDeep(reportInfo);
      return { ...state };
    }
    case types.WM_SET_DATA_FOR_MODIFY_PERIODIC_REPORT: {
      let { reportInfo } = action.payload;
      if (
        _.isEmpty(reportInfo) ||
        _.isNull(reportInfo) ||
        _.isUndefined(reportInfo)
      )
        return { ...state };
      reportInfo.error = _.clone(isReportError);
      reportInfo.formTitle = "";
      reportInfo.lstWorkPerformance?.forEach(
        (element) => (element.checked = false)
      );
      reportInfo.lstWorkPlan?.forEach((element) => (element.checked = false));
      state.reportInfo = _.cloneDeep(reportInfo);
      return { ...state };
    }
    default:
      return { ...state };
  }
};

export default weeklyMonthlyReportRegisterReducer;

const PERFORMANCE_TYPE = "PERFORMANCE";

const isReportError = {
  isCurrentTimeError: false,
  isNextTimeError: false,
  isErrorFormTitle: false,
  isErrorWorkPerformance: false,
};

const reportInfoObject = {
  isError: false,
  formTitle: "",
  lstWorkPerformance: [],
  lstWorkPlan: [],
  note: "",
  lstAttachFile: [],
  error: _.clone(isReportError),
  isCheckAllWorkPerformance: false,
  isCheckAllWorkPlan: false,
};

const arrOfMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function setWeeklyDefaultValue(reportInfo) {
  const currentDate = new Date();
  const mon = currentDate.getDate() - currentDate.getDay() + 1;
  const fri = mon + 4;
  const sun = mon + 6;
  const mondayOfWeek = new Date(_.clone(currentDate).setDate(mon));
  const fridayOfWeek = new Date(_.clone(currentDate).setDate(fri));
  const sundayOfWeek = new Date(_.clone(currentDate).setDate(sun));
  const langCode = localStorage.getItem("langCode");
  langCode === "KR"
    ? (reportInfo.formTitle = `${_.clone(
        currentDate
      ).getFullYear()}년 제${_.clone(
        currentDate
      ).getWeekNumber()}주차 주간업무보고서 (${
        mondayOfWeek.getMonth() + 1
      }월${mondayOfWeek.getDate()}일∼${
        fridayOfWeek.getMonth() + 1
      }월${fridayOfWeek.getDate()}일)`)
    : (reportInfo.formTitle = `Weekly ${_.clone(
        currentDate
      ).getWeekNumber()}, ${_.clone(
        currentDate
      ).getFullYear()} Weekly Report (${
        arrOfMonth[mondayOfWeek.getMonth()]
      } ${mondayOfWeek.getDate()} ∼ ${
        arrOfMonth[fridayOfWeek.getMonth()]
      } ${fridayOfWeek.getDate()})`);

  reportInfo.reportStartdateYYYYMMDD = mondayOfWeek;
  reportInfo.reportEnddateYYYYMMDD = sundayOfWeek;

  const monOfNextWeek = sun + 1;
  const sunOfNextWeek = sun + 7;
  reportInfo.planStartdateYYYYMMDD = new Date(
    _.clone(currentDate).setDate(monOfNextWeek)
  );
  reportInfo.planEnddateYYYYMMDD = new Date(
    _.clone(currentDate).setDate(sunOfNextWeek)
  );
  return reportInfo;
}

function setMonthlyDefaultValue(reportInfo) {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const langCode = localStorage.getItem("langCode");
  langCode === "KR"
    ? (reportInfo.formTitle = `${currentDate.getFullYear()}년 ${
        currentDate.getMonth() + 1
      }월 월간업무보고서 ( ${
        firstDayOfMonth.getMonth() + 1
      }월 ${firstDayOfMonth.getDate()}일 ∼ ${
        lastDayOfMonth.getMonth() + 1
      }월 ${lastDayOfMonth.getDate()}일 )`)
    : (reportInfo.formTitle = `${
        arrOfMonth[currentDate.getMonth()]
      } ${currentDate.getFullYear()} Monthly Report (${
        arrOfMonth[firstDayOfMonth.getMonth()]
      } ${firstDayOfMonth.getDate()} ∼ ${
        arrOfMonth[lastDayOfMonth.getMonth()]
      } ${lastDayOfMonth.getDate()})`);

  reportInfo.reportStartdateYYYYMMDD = firstDayOfMonth;
  reportInfo.reportEnddateYYYYMMDD = lastDayOfMonth;

  const firstDayOfnextMonth = new Date(
    firstDayOfMonth.getFullYear(),
    firstDayOfMonth.getMonth() + 1,
    1
  );
  const lastDayOfNextMonth = new Date(
    lastDayOfMonth.getFullYear(),
    lastDayOfMonth.getMonth() + 2,
    0
  );
  reportInfo.planStartdateYYYYMMDD = firstDayOfnextMonth;
  reportInfo.planEnddateYYYYMMDD = lastDayOfNextMonth;
  return reportInfo;
}
