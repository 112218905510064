import _ from 'lodash';
import * as utilFunMenuCode from './../../../util/findIndexRowsMenuCodeUtil';
import * as types from './groupPermissionSettingActionTypes';
const initialState = {
    listPermissionGroups: [],
    listMenusByPermission: [],
    actionStatus: '',
    permisionGroupBySeq: null,
    permisionGroupDetail: null,
    listLangs: [],
    totalCountPermisisonGroup: 0,
    pageIndexSelection: 0,
    gpsFormSearchData: {}
}
const groupPermissionSettingReducer = (state = initialState, action) => {

    let index = -1;
    switch (action.type) {
        case types.GET_LIST_PERMISSION_GROUPS:
            return {
                ...state,
                listPermissionGroups: action.data.listPermissionGroups,
                totalCountPermisisonGroup: action.data.totalCount,

            };
        case types.GET_LIST_MENUS_BY_PERMISSION:
            return {
                ...state,
                listMenusByPermission: action.items
            }
        case types.INSERT_PERMISSION_GROUP_SETTING:
            return {
                ...state,
            }
        case types.UPDATE_MENUCODE_BY_PERMISSION_GROUP:
            index = utilFunMenuCode.findIndexMenuCode(state.listMenusByPermission, action.item.menuCode);
            let list = state.listMenusByPermission;
            list[index] = action.item;
            return {
                ...state,
                listMenusByPermission: list
            }
        case types.GET_PERMISION_GROUP_DETAIL:
            return {
                ...state,
                permisionGroupDetail: action.permisionGroupDetail
            }
        case types.GET_LANGS:
            return {
                ...state,
                listLangs: action.listLangs
            }
        case types.PAGING:
            return {
                ...state,
                pageIndexSelection: action.pageIndexSelection
            }
        case types.CHANGE_GPS_FORM_SEARCH_DATA:
            state.gpsFormSearchData = _.cloneDeep(action.payload)
            return {
                ...state,
            }
        default: return state
    }
}
export default groupPermissionSettingReducer;