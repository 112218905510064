export const CHECK_IS_NEW = 'CHECK_IS_NEW'
export const CHECK_OPEN = 'CHECK_OPEN'
export const CHECK_DISABLE = 'CHECK_DISABLE'
export const SET_SOCIAL = 'SET_SOCIAL'
export const SET_STAFFNAME = 'SET_STAFFNAME'
export const SET_PARENT_ORG = 'SET_PARENT_ORG'
export const SET_APPROVALINE_NAME = 'SET_APPROVALINE_NAME'
export const SET_APPROVALINE_NAME_ERROR = 'SET_APPROVALINE_NAME_ERROR'
export const SET_SEARCH_SELECT = 'SET_SEARCH_SELECT'
export const SET_VEHICLE_STATUS = 'SET_VEHICLE_STATUS'
export const CHECK_GRAP = 'CHECK_GRAP'