import React, { Fragment } from 'react'
import { Translation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { actFetchMenusRequest } from '../../redux/index'
import { menuJS } from '../../util/menuUtil'

const MenuLink = ({ label, to, writeYn, readYn, param, countLabel }) => {
    return (
        <Route
            path={to}
            children={({ match }) => {
                var active = match ? 'active' : '';
                return (
                    <li className={active}>
                        <Link to={{ pathname: to, state: { title: label, writeYn, readYn, param } }}>
                            <span>{label}</span>{countLabel}
                        </Link>
                    </li>
                );
            }}
        />
    );
};

class Menu extends React.Component {
    state = {}

    componentDidMount() {
        if (localStorage.getItem("authorization")) {
            let apiUrl = "/main/getMenuList.json";
            this.props.fetchMenus(apiUrl);

        }
        //menuJS();
    }

    static getDerivedStateFromProps(props, state) {
        menuJS();
        return null;
    }

    showMenu(menus) {
        var result = null;
        if (menus.length > 0) {
            result = menus.map((menu, index) => {
                let children = menu.children;
                if (children && children.length > 0) {
                    return (
                        <li key={index}>
                            <span id={`ico-menu-${menu.treeIdHier}`} className="link-i">{menu.menuName}</span>
                            <div className="menu-sub">
                                <ul className="sub-menu">
                                    {this.showMenuSub(children)}
                                </ul>
                            </div>
                        </li>

                    );

                } else {
                    return (
                        <MenuLink className="link-a" key={index} label={menu.menuName} to={menu.menuUrl} writeYn={menu.writeYn} readYn={menu.readYn} param={menu.param} />
                    );

                }
            });
        }
        return result;
    }

    showMenuSub(menus) {
        let result = null;
        if (menus.length > 0) {
            result = menus.map((menu, index) => {
                let children = menu.children;
                if (children && children.length > 0) {
                    return (
                        <li className="sub-li" key={index}>
                            <span className="link-title">{menu.menuName}</span>
                            <ul>
                                {this.showMenuItem(children)}
                            </ul>
                        </li>

                    );

                } else {
                    return (
                        <MenuLink className="link-a" key={index} label={menu.menuName} to={menu.menuUrl} writeYn={menu.writeYn} readYn={menu.readYn} param={menu.param} />
                    );

                }
            });
        }
        return result;
    }

    showMenuItem(menus) {
        let result = null;
        if (menus.length > 0) {
            result = menus.map((menu, index) => {
                return (
                    ! (this.props.userInfo?.superAdminYn === 'Y' && ['05-01-06', '05-01-05'].includes(menu.treeIdHier))
                    &&<MenuLink className="link-a" key={index}
                        label={`${menu.menuName}`}
                        countLabel={` ${menu.countNumber && menu.countNumber !== 0 ? `(${menu.countNumber})` : ''}`}
                        to={menu.menuUrl} writeYn={menu.writeYn} readYn={menu.readYn} param={menu.param} />
                );
            });
        }
        return result;
    }

    handleClose(e) {
        this.props.handleClose(e);
    }

    render() {
        var { menus } = this.props;
        return (
            <Fragment>
                <div className={`wrapper-menu menu-wrap ${this.props.menuClass}`}>
                    {this.props.menuClass === '' ? null : <span onClick={(e) => this.handleClose(e)} className="menu-mask control-menu d-sm-none"><i className="fas fa-times"></i></span>}
                    <div className="menu-inner">
                        <ul className="menu">
                            {this.showMenu(menus)}
                        </ul>

                    </div>
                </div>
                {this.props.menuClass === '' ? null : <span onClick={(e) => this.handleClose(e)} className="menu-mask control-menu d-sm-none"><Translation>{(t) => <> {t('common.btn.close.label')} </>}</Translation></span>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        menus: state.menus,
        userInfo: state.authentication.userInfo
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchMenus: (apiUrl) => {
            dispatch(actFetchMenusRequest(apiUrl))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)