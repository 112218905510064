import React, { lazy } from 'react';

const GeneralFormComponent = lazy(() => import('./components/Approval/ApprovalRegistration/FormComponent/GeneralFormComponent'));
const KpsLogin = lazy(() => import('./components/auth/KpsLogin'));
const KpsLogout = lazy(() => import('./components/auth/KpsLogout'));
const Home = lazy(() => import('./components/Home'));
const AfterProgress = lazy(() => import('./containers/Approval/Approval/AfterProgress'));
const AllDocuments = lazy(() => import('./containers/Approval/Approval/AllDocuments'));
const Approved = lazy(() => import('./containers/Approval/Approval/Approved'));
const BeforeProgress = lazy(() => import('./containers/Approval/Approval/BeforeProgress'));
const Execute = lazy(() => import('./containers/Approval/Approval/Execute'));
const InProgress = lazy(() => import('./containers/Approval/Approval/InProgress'));
const Observe = lazy(() => import('./containers/Approval/Approval/Observe'));
const Rejected = lazy(() => import('./containers/Approval/Approval/Rejected'));
const ApprovalLineRegister = lazy(() => import('./containers/Approval/ApprovalLineManagement/ApprovalLineRegister'));
const AllReceivedApproval = lazy(() => import('./containers/Approval/SubmitAndReceive/AllReceivedApproval'));
const Submit = lazy(() => import('./containers/Approval/SubmitAndReceive/Submit'));
const Temporary = lazy(() => import('./containers/Approval/SubmitAndReceive/Temporary'));
const Campus = lazy(() => import('./containers/business/workplaceRegisterPage'));
const AttendanceStatistics = lazy(() => import('./containers/BusinessSupport/AttendanceManagement/AttendanceStatistics/AttendanceStatistics'));
const AttendanceStatus = lazy(() => import('./containers/BusinessSupport/AttendanceManagement/AttendanceStatus/AttendanceStatus'));
const OvertimeManagement = lazy(() => import('./containers/BusinessSupport/AttendanceManagement/OvertimeManagement'));
const TeamAttendanceStatus = lazy(() => import('./containers/BusinessSupport/AttendanceManagement/TeamAttendance/TeamAttendanceStatus'));
const TeamOvertimeMamangement = lazy(() => import('./containers/BusinessSupport/AttendanceManagement/TeamOvertimeManagement'));
const CorporateCardStatistics = lazy(() => import('./containers/BusinessSupport/CorporateCardManagement/CorporateCardStatistics'));
const VehicleStatus = lazy(() => import('./containers/BusinessSupport/VehicleManagement/VehicleStatus'));
const OrganizationChartPage = lazy(() => import('./containers/DemoPage/OrganizationChart/OrganizationChartDemoPage'));
const Employee = lazy(() => import('./containers/employee/employeeRegisterPage'));
const PersonalAnnualConfirm = lazy(() => import('./containers/MyPage/MyPage/PersonalAnnualConfirm'));
const PersonalAttendanceStatus = lazy(() => import('./containers/MyPage/MyPage/PersonalAttendanceStatus'));
const PersonalInfoModify = lazy(() => import('./containers/MyPage/MyPage/PersonalInfoModify'));
const DailyReportRegister = lazy(() => import('./containers/Schedule/ScheduleManagement/DailyReportRegister/DailyReportRegister'));
const DailyReportStatus = lazy(() => import('./containers/Schedule/ScheduleManagement/DailyReportStatus/DailyReportStatus'));
const MonthlyReportRegister = lazy(() => import('./containers/Schedule/ScheduleManagement/MonthlyReportRegister/MonthlyReportRegister'));
const MonthlyReportStatus = lazy(() => import('./containers/Schedule/ScheduleManagement/MonthlyReportStatus/MonthlyReportStatus'));
const ScheduleStatusView = lazy(() => import('./containers/Schedule/ScheduleManagement/ScheduleStatusView/ScheduleStatusView'));
const WeeklyReportRegister = lazy(() => import('./containers/Schedule/ScheduleManagement/WeeklyReportRegister/WeeklyReportRegister'));
const WeeklyReportStatus = lazy(() => import('./containers/Schedule/ScheduleManagement/WeeklyReportStatus/WeeklyReportStatus'));
const CorporateCardExpenseAccountSetting = lazy(() => import('./containers/SystemManagement/ApprovalSetting/CorporateCardExpenseAccountSetting'));
const CorporateCardRegister = lazy(() => import('./containers/SystemManagement/ApprovalSetting/CorporateCardRegister'));
const DraftFolderManagement = lazy(() => import('./containers/SystemManagement/ApprovalSetting/DraftFolderManagement'));
const DraftFormManagement = lazy(() => import('./containers/SystemManagement/ApprovalSetting/DraftFormManagement'));
const DraftFormRegister = lazy(() => import('./containers/SystemManagement/ApprovalSetting/DraftFormRegister'));
const AnnualLeaveCriteriaSetting = lazy(() => import('./containers/SystemManagement/AttendanceSetting/AnnualLeaveCriteriaSetting'));
const AnnualSettingByEmployee = lazy(() => import('./containers/SystemManagement/AttendanceSetting/AnnualSettingByEmployee/AnnualSettingByEmployee'));
const AttendanceInfoSetting = lazy(() => import('./containers/SystemManagement/AttendanceSetting/AttendanceInfoSetting'));
const VacationTypeSetting = lazy(() => import('./containers/SystemManagement/AttendanceSetting/VacationTypeSetting'));
const CommonCodeRegister = lazy(() => import('./containers/SystemManagement/BasicInformation/CommonCode/CommonCodeRegister'));
const GeneralCodeRegister = lazy(() => import('./containers/SystemManagement/BasicInformation/GeneralCommonCode/GeneralCodeRegister'));
const NationalRegister = lazy(() => import('./containers/SystemManagement/BasicInformation/NationalRegister'));
const TreeCodeRegister = lazy(() => import('./containers/SystemManagement/BasicInformation/TreeCommonCode/TreeCodeRegister'));
const JobDescriptionRegister = lazy(() => import('./containers/SystemManagement/CompanyInfoManagement/JobDescriptionRegister/JobDescriptionRegister'));
const OrganizationRegister = lazy(() => import('./containers/SystemManagement/CompanyInfoManagement/OrganizationRegister'));
const WorkplaceRegister = lazy(() => import('./containers/SystemManagement/CompanyInfoManagement/WorkplaceRegister'));
const EmployeeRegister = lazy(() => import('./containers/SystemManagement/EmployeeInfoManagement/EmployeeRegister'));
const GroupPermissionSetting = lazy(() => import('./containers/SystemManagement/PermissionSetting/GroupPermissionSetting'));
const MenuManagement = lazy(() => import('./containers/SystemManagement/PermissionSetting/MenuManagement/MenuManagement'));
const PermissionGroupRegister = lazy(() => import('./containers/SystemManagement/PermissionSetting/PermissionGroupRegister'));
const HolidayRegister = lazy(() => import('./containers/SystemManagement/ScheduleSetting/HolidayRegister'));
const StartDaySetting = lazy(() => import('./containers/SystemManagement/ScheduleSetting/StartDaySetting'));
const NoticeBox = lazy(() => import('./containers/BusinessSupport/NoticeBox/NoticeBox/NoticeBox'));
const NoticeForm = lazy(() => import('./containers/BusinessSupport/NoticeBox/NoticeForm/NoticeForm'));
const NoticeBoxManagement = lazy(() => import('./containers/SystemManagement/NoticeBoxManagement/NoticeBoxManagement'));
const IndividualFormContainer = lazy(() => import('./containers/IndividualForm/IndividualFormContainer'));
const OrganizationChart = lazy(() => import('./containers/MyPage/MyPage/OrganizationChart'));
const ContactList = lazy(() => import('./containers/MyPage/MyPage/ContactList'));
const TransportPage = lazy(() => import('./components/TransportPage'));
const AccessDenied = lazy(() => import('./components/AccessDenied'));
const RedirectDocumentPage = lazy(() => import('./components/RedirectDocumentPage'));

const routes = [
    {
        path: '/',
        exact: true,
        main: () => <TransportPage />
    }, {
        path: '/403',
        exact: true,
        main: () => <AccessDenied />
    },
    {
        path: '/home',
        exact: true,
        main: () => <Home />
    },
    {
        path: '/login/:token?',
        exact: true,
        main: () => <KpsLogin />
    },
    {
        path: '/logout',
        exact: true,
        main: () => <KpsLogout />
    },
    {
        path: '/campus',
        exact: true,
        main: () => <Campus />
    },
    {
        path: '/employee',
        exact: true,
        main: () => <Employee />
    },
    //page updated
    {
        path: '/SystemMgmt/CompanyInfoMgmt/WorkplaceRegister',
        isPrivate: true,
        exact: true,
        main: () => <WorkplaceRegister />
    },
    {
        path: '/SystemMgmt/CompanyInfoMgmt/OrganizationRegister',
        isPrivate: true,
        exact: true,
        main: () => <OrganizationRegister />
    },
    {
        path: '/SystemMgmt/CompanyInfoMgmt/JobDescriptionRegister',
        isPrivate: true,
        exact: true,
        main: () => <JobDescriptionRegister />
    },
    {
        path: '/SystemMgmt/EmployeeInfoMgmt/EmployeeRegister',
        isPrivate: true,
        exact: true,
        main: () => <EmployeeRegister />
    },
    {
        path: '/SystemMgmt/PermissionSetting/MenuManagement',
        isPrivate: true,
        exact: true,
        main: () => <MenuManagement />
    },
    {
        path: '/SystemMgmt/PermissionSetting/GroupPermissionSetting',
        isPrivate: true,
        exact: true,
        main: () => <GroupPermissionSetting />
    },
    {
        path: '/SystemMgmt/PermissionSetting/PermissionGroupRegister',
        isPrivate: true,
        exact: true,
        main: () => <PermissionGroupRegister />
    },
    {
        path: '/SystemMgmt/BasicInformation/CommonCodeRegister',
        isPrivate: true,
        exact: true,
        main: () => <CommonCodeRegister />
    },
    {
        path: '/SystemMgmt/BasicInformation/NationalRegister',
        isPrivate: true,
        exact: true,
        main: () => <NationalRegister />
    },
    {
        path: '/SystemMgmt/BasicInformation/GeneralCodeRegister',
        isPrivate: true,
        exact: true,
        main: () => <GeneralCodeRegister />
    },
    {
        path: '/SystemMgmt/BasicInformation/TreeCodeRegister',
        isPrivate: true,
        exact: true,
        main: () => <TreeCodeRegister />
    },
    {
        path: '/SystemMgmt/ApprovalSetting/DraftFolderManagement',
        isPrivate: true,
        exact: true,
        main: () => <DraftFolderManagement />
    },
    {
        path: '/SystemMgmt/ApprovalSetting/DraftFormManagement',
        isPrivate: true,
        exact: true,
        main: () => <DraftFormManagement />
    },
    {
        path: '/SystemMgmt/ApprovalSetting/DraftFormRegister',
        isPrivate: true,
        exact: true,
        main: () => <DraftFormRegister />
    },
    {
        path: '/SystemMgmt/ApprovalSetting/CorporateCardExpenseAccountSetting',
        isPrivate: true,
        exact: true,
        main: () => <CorporateCardExpenseAccountSetting />
    },
    {
        path: '/SystemMgmt/ApprovalSetting/CorporateCardRegister',
        isPrivate: true,
        exact: true,
        main: () => <CorporateCardRegister />
    },
    {
        path: '/SystemMgmt/ScheduleSetting/StartDaySetting',
        isPrivate: true,
        exact: true,
        main: () => <StartDaySetting />
    },
    {
        path: '/SystemMgmt/ScheduleSetting/HolidayRegister',
        isPrivate: true,
        exact: true,
        main: () => <HolidayRegister />
    },
    {
        path: '/SystemMgmt/AttendanceSetting/AttendanceInfoSetting',
        isPrivate: true,
        exact: true,
        main: () => <AttendanceInfoSetting />
    },
    {
        path: '/SystemMgmt/AttendanceSetting/VacationTypeSetting',
        isPrivate: true,
        exact: true,
        main: () => <VacationTypeSetting />
    },
    {
        path: '/SystemMgmt/AttendanceSetting/AnnualLeaveCriteriaSetting',
        isPrivate: true,
        exact: true,
        main: () => <AnnualLeaveCriteriaSetting />
    },
    {
        path: '/SystemMgmt/AttendanceSetting/AnnualSettingByEmployee',
        isPrivate: true,
        exact: true,
        main: () => <AnnualSettingByEmployee />
    },
    {
        path: '/SystemMgmt/NoticeSetting/NoticeSetting',
        isPrivate: true,
        exact: true,
        main: () => <NoticeBoxManagement />
    },
    {
        path: '/MyPage/MyPage/PersonalInfoModify',
        exact: true,
        main: () => <PersonalInfoModify />
    },
    {
        path: '/MyPage/MyPage/PersonalAttendanceStatus',
        exact: true,
        main: () => <PersonalAttendanceStatus />
    },
    {
        path: '/MyPage/MyPage/PersonalAnnualConfirm',
        exact: true,
        main: () => <PersonalAnnualConfirm />
    },
    {
        path: '/MyPage/MyPage/OrganizationChart',
        main: () => <OrganizationChart />
    },
    {
        path: '/MyPage/MyPage/ContactList',
        main: () => <ContactList />
    },
    {
        path: '/Schedule/Schedulemgmt/ScheduleStatusView',
        exact: true,
        main: () => <ScheduleStatusView />
    },
    {
        path: '/Schedule/Schedulemgmt/DailyReportRegister',
        exact: true,
        main: () => <DailyReportRegister />
    },
    {
        path: '/Schedule/Schedulemgmt/DailyReportStatus',
        exact: true,
        main: () => <DailyReportStatus />
    },
    {
        path: '/Schedule/Schedulemgmt/WeeklyReportRegister',
        exact: true,
        main: () => <WeeklyReportRegister />
    },
    {
        path: '/Schedule/Schedulemgmt/WeeklyReportStatus',
        exact: true,
        main: () => <WeeklyReportStatus />
    },
    {
        path: '/Schedule/Schedulemgmt/MonthlyReportRegister',
        exact: true,
        main: () => <MonthlyReportRegister />
    },
    {
        path: '/Schedule/Schedulemgmt/MonthlyReportStatus',
        exact: true,
        main: () => <MonthlyReportStatus />
    },
    {
        path: '/Approval/ApprovalLineMgmt/ApprovalLineRegister',
        exact: true,
        main: () => <ApprovalLineRegister />
    },
    {
        path: '/Approval/ApprovalRegistration/:folderCode',
        exact: true,
        main: () => <GeneralFormComponent />
    },
    {
        path: '/Approval/SubmitAndReceive/Submit/:remoteFormTypeTreeId?/:remoteSystemFormSeq?/:remoteUserFormSeq?',
        exact: true,
        main: () => <Submit />
    },
    {
        path: '/Approval/SubmitAndReceive/Temporary',
        exact: true,
        main: () => <Temporary />
    },
    {
        path: '/Approval/SubmitAndReceive/AllReceivedApproval/:remoteFormTypeTreeId?/:remoteSystemFormSeq?/:remoteUserFormSeq?',
        exact: true,
        main: () => <AllReceivedApproval />
    },
    {
        path: '/Approval/SubmitAndReceive/Rejected/:remoteFormTypeTreeId?/:remoteSystemFormSeq?/:remoteUserFormSeq?',
        exact: true,
        main: () => <RedirectDocumentPage />
    },
    {
        path: '/Approval/Approval/InProgress/:remoteFormTypeTreeId?/:remoteSystemFormSeq?/:remoteUserFormSeq?',
        exact: true,
        main: () => <InProgress />
    },
    {
        path: '/Approval/Approval/BeforeProgress',
        exact: true,
        main: () => <BeforeProgress />
    },
    {
        path: '/Approval/Approval/AfterProgress',
        exact: true,
        main: () => <AfterProgress />
    },
    {
        path: '/Approval/Approval/Rejected/:remoteFormTypeTreeId?/:remoteSystemFormSeq?/:remoteUserFormSeq?',
        exact: true,
        main: () => <Rejected />
    },
    {
        path: '/Approval/Approval/Approved',
        exact: true,
        main: () => <Approved />
    },
    {
        path: '/Approval/Approval/Execute',
        exact: true,
        main: () => <Execute />
    },
    {
        path: '/Approval/Approval/Observe',
        exact: true,
        main: () => <Observe />
    },
    {
        path: '/Approval/Approval/AllDocuments/:remoteFormTypeTreeId?/:remoteSystemFormSeq?/:remoteUserFormSeq?',
        exact: true,
        main: () => <AllDocuments />
    },
    {
        path: '/BusinessSupport/AttendanceMgmt/AttendanceStatus',
        exact: true,
        main: () => <AttendanceStatus />
    },
    {
        path: '/BusinessSupport/AttendanceMgmt/TeamAttendanceStatus',
        exact: true,
        main: () => <TeamAttendanceStatus />
    },

    {
        path: '/BusinessSupport/AttendanceMgmt/AttendanceStatistics',
        exact: true,
        main: () => <AttendanceStatistics />
    },
    {
        path: '/BusinessSupport/AttendanceMgmt/OvertimeManagement',
        exact: true,
        main: () => <OvertimeManagement />
    },
    {
        path: '/BusinessSupport/AttendanceMgmt/TeamOvertimeMamangement',
        exact: true,
        main: () => <TeamOvertimeMamangement />
    },
    {
        path: '/BusinessSupport/CorporateCardMgmt/CorporateCardStatistics',
        exact: true,
        main: () => <CorporateCardStatistics />
    },
    {
        path: '/BusinessSupport/VehicleMgmt/VehicleStatus',
        exact: true,
        main: () => <VehicleStatus />
    },
    {
        path: '/BusinessSupport/NoticeBox/:noticeBoxCode/:noticeBoxSeq?/:noticeSeq?',
        exact: false,
        main: () => <NoticeBox />
    },
    {
        path: '/BusinessSupport/NoticeBox/NoticeForm/Window/:noticeBoxSeq?',
        exact: true,
        isNewWindow: true,
        main: () => <NoticeForm />
    },
    {
        path: '/DemoPage/OrganizationChartPage',
        exact: true,
        main: () => <OrganizationChartPage />
    },
    {
        path: '/IndividualForm/:formTypeTreeId?/:systemFormSeq?/:userFormSeq?',
        exact: true,
        main: () => <IndividualFormContainer />
    }
];

export default routes;