import axios from 'axios';
import { unMaskPage } from '../util/jqueryFun';
import { URL_BASE } from './config';

export default function apiService(url, method = 'GET', body) {
    if (method === 'GET' && body) {
        return axios.get(url, { params: body });
    } else {
        return axios({
            method: method,
            url: url,
            data: body
        });
    }
};

export const customPostAxiosJson = (url, params, successFunction) => {
    axios.post(url, params)
        .then(response => {
            if (response.data.actionStatus === 'success') {
                successFunction(response.data);
            } else if (response.data.actionStatus === 'deny') {
                if (![window.location.pathname.split('/')[1], window.location.pathname.split('/')[2]].includes('403')) {
                    window.location.href = URL_BASE + '/front/403';
                }
            }
        })
        .catch(error => {
            
        })
        .finally(() => {
            unMaskPage();
        })
}