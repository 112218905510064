import * as types from './overtimeActionTypes'
import _ from 'lodash';
import apiService from '../../../util/apiService'
import {DEFAULT_ORGANIZATION_SEQ_FOR_RANGE, PAGE_SIZE, PAGE_SIZES } from './../../../util/config'
const lstAttendance = [
    // {
    //     isChecked: false,
    //     department: 'IT전략Team',
    //     position: '차장(Team장)',
    //     name: '홍성숙 (Joanna)',
    //     lstCheckTimes: [
    //         {
    //             date: '20210201',
    //             checkIn: '09:00',
    //             checkOut: '18:00'
    //         },
    //         {
    //             date: '20210202',
    //             checkIn: '10:10',
    //             checkOut: '18:00'
    //         },
    //         {
    //             date: '20210205',
    //             checkIn: '10:10',
    //             checkOut: '18:00'
    //         }
    //     ],
    //     totalTime: '02h 30m',
    //     totalAllowance: '10.00'
    // }
]

const currentDate = new Date();
const initialState = {
    isCheckAll: false,
    lstAttendanceState: [],
    lstCompany: [],
    searchOption: {
        state: null,
        company: null,
        startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        endDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
        rangeList: [],
        page : 0,
        pageSize : PAGE_SIZE
    },
    lstAttendance: [],
    headerStartDate: null,
    headerEndDate: null,
    baseCheckIn: '09:00',
    YMSelected: null,
    totalCount :0
}
function convertCurrentDate(dateString) {
    let dd = String(dateString.getDate()).padStart(2, '0');
    let mm = String(dateString.getMonth() + 1).padStart(2, '0');
    let yyyy = dateString.getFullYear();
    let value = yyyy + '-' + mm ;
    return value;
}
 function getDateOfWeek(startDayOfWeek, w, y){
    if(w === undefined || y === undefined) {
        let todayDate = new Date();
        let oneJan = new Date(todayDate.getFullYear(),0,1);
        let numberOfDays = Math.floor((todayDate-oneJan)/(24*60*60*1000));
         w = Math.round((1+numberOfDays)/7);
         y = todayDate.getFullYear();
    }
   
    let d = (1 + (w) * 7);
    let result = new Date (y,0,d);
    let day = new Date(y,0,1);
    let firstDay  = new Date(y,0,1).getDay();
   
    // let week = day.getTime() + 604800000 * (w - 1);
    //if you wish the first is Sunday, change (firstDay-1) to firstDay
    let week = '';
    if(startDayOfWeek?.calendarStartdateCode === 'SU'){
        week = day.getTime() -(3600000 *24 *(firstDay)) + 604800000 * (w);
    }else if(startDayOfWeek?.calendarStartdateCode === 'MO'){
        week = day.getTime() -(3600000 *24 *(firstDay-1)) + 604800000 * (w-1);
    }else if(startDayOfWeek?.calendarStartdateCode === 'TU'){
        week = day.getTime() -(3600000 *24 *(firstDay-2)) + 604800000 * (w-1);
    }else if(startDayOfWeek?.calendarStartdateCode === 'WE'){
        week = day.getTime() -(3600000 *24 *(firstDay-3)) + 604800000 * (w-1);
    }else if(startDayOfWeek?.calendarStartdateCode === 'TH'){
        week = day.getTime() -(3600000 *24 *(firstDay-4)) + 604800000 * (w-1);
    }else if(startDayOfWeek?.calendarStartdateCode === 'FR'){
        week = day.getTime() -(3600000 *24 *(firstDay-5)) + 604800000 * (w-1);
    }else if(startDayOfWeek?.calendarStartdateCode === 'SA'){
        week = day.getTime() -(3600000 *24 *(firstDay-6)) + 604800000 * (w-1);
    }
    let first = new Date(week);
    let last  = new Date(week + 518400000);
    let startDate = convertCurrentDate(new Date(week));
    let endDate = convertCurrentDate(new Date(week + 518400000))
    let YMSelected = 'Hello'
    if(startDate !== endDate){
        let firstY = startDate.slice(0,4);
        let firstM = startDate.slice(5,7);
        let lastY = endDate.slice(0,4);
        let lastM = endDate.slice(5,7);
        if(firstY !== lastY){
            YMSelected = firstY +'-'+firstM +'~' +lastY + '-'+lastM; 
        }else{
            YMSelected = firstY + '-' +firstM +' ~ '+lastM;
        }
    }else{
        // this.setState({
        //     yearMonth : this.convertCurrentDate(result)
        // })
        // YMSelected = convertCurrentDate(result);
        YMSelected = startDate;
    }
    return {first,last, YMSelected}
}
const sendEmail = (value) =>{
    apiService(`/overtimeManagement/sendEmail`, 'POST', value).then(res => {
        // dispatch(actGetStartDaOfWeek(res.data));
    })
}
const overtimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OT_FETCH_ATTENDANCE_STATE: {
            state.lstAttendanceState = action.payload.lstAttendanceState;
            if (!_.isEmpty(state.lstAttendanceState)) {
                state.searchOption.state = state.lstAttendanceState[0].detailCode
            }
            return { ...state };
        }

        case types.OT_SET_START_DAY_OF_WEEK: {
            state.startDayOfWeek = action.payload.startDayOfWeek
            let days = getDateOfWeek(state.startDayOfWeek);
            state.searchOption.startDate = days.first;
            state.YMSelected = days.YMSelected;

            state.headerStartDate = days.first;
            state.searchOption.endDate = days.last;
            state.headerEndDate = days.last;
            return { ...state };
        }

        case types.OT_SET_RANGE_DATE: {
            const {week, year} = action.payload;
            let days = getDateOfWeek(state.startDayOfWeek, week, year );
            state.searchOption.startDate = days.first;
            state.YMSelected = days.YMSelected;

            // state.headerStartDate = days.first;
            state.searchOption.endDate = days.last;
            // state.headerEndDate = days.last;
            return { ...state };
        }

        case types.OT_FETCH_COMPANY_LIST: {
            let { lstCompany } = action.payload;
            state.lstCompany = lstCompany;
            let defaultRangeItem = {};
            const defaultOrganization = _.find(lstCompany, element => element.organizationSeq == localStorage.getItem("organizationSeq"));
            if(defaultOrganization){
                defaultRangeItem = {
                    targetType: "OR",
                    targetSeq: defaultOrganization.organizationSeq,
                    orderSeq: 1,
                    organizationName: defaultOrganization.organizationName
                }
            }
            
            state.searchOption.rangeList = [defaultRangeItem];
            state.searchOption.company = defaultOrganization?.organizationSeq;
            state.searchOption.companyName = defaultOrganization?.organizationName;
            return { ...state };
        }

        case types.OT_UPDATE_ATTENDANCE_REDUCER_STATE: {
            state = action.payload.newState;
            return _.cloneDeep(state);
        }

        case types.OT_CREATE_SEARCH_OPTION_DEFAULT: {
            state.headerStartDate = state.searchOption.startDate;
            state.headerEndDate = state.searchOption.endDate;
            state.lstAttendance = lstAttendance;
            return { ...state };
        }

        case types.OT_SEARCH_OVER_TIME: {
            state.headerStartDate = state.searchOption.startDate;
            state.headerEndDate = state.searchOption.endDate;
            state.lstAttendance = action.items;
            
            return { ...state };
        }
        case types.OT_SEARCH_OVER_TIME_TOTAL: {
            state.totalCount = action.payload
            return {...state}
        }
        case types.OT_SEND_EMAIL : {
            let newState = _.filter(state.lstAttendance,{isChecked:true});
            newState.forEach(item =>{
                sendEmail(item);
            })
            return { ...state };
        }
        default:
            return { ...state };
    }
}

export default overtimeReducer;
