
import _ from 'lodash';
import { formatDate, stringToDate } from '../../../../util/date';
import * as types from './reportStatusType';
import { formatStringDate } from "../../../../util/date";
import * as code from './../../../../util/codeConstant';
import { Translation } from 'react-i18next';
import { PAGE_SIZE, PAGE_SIZES } from './../../../../util/config'

const currentDate = new Date();
const searchOptionDefault = {
    startDate: '',
    endDate: formatDate(currentDate, '-'),
    status: 0,
    searchTarget: 'TT',
    title: '',
    page: 0,
    pageSize: PAGE_SIZE
}

const columns = [
    { title: <Translation>{(t) => <> {t('common.column.title.label')} </>}</Translation>, name: 'title', columnName: 'title', width: '842' },
    { title: <Translation>{(t) => <> {t('common.column.date.label')} </>}</Translation>, name: 'reportDateYmd', columnName: 'reportDateYmd', width: 250, align: 'center' },
    { title: <Translation>{(t) => <> {t('common.column.recentApprover.label')} </>}</Translation>, name: 'employeeName', columnName: 'employeeName', width: 250, align: 'center' },
    { title: <Translation>{(t) => <> {t('common.column.status.label')} </>}</Translation>, name: 'approvalStatusName', columnName: 'approvalStatusName', width: 150, align: 'center' }
]
const initialState = {
    columns: columns,
    reportType: '',
    searchOption: _.cloneDeep(searchOptionDefault),
    pageSizes: PAGE_SIZES,
    lstStatus: [],
    lstSearchType: [],
    lstReport: [],
    performanceTypeMap: [],
    totalRow: 0,
    dailyReportDetail: {},
    periodicReportDetail: {},
    isOpenDailyReportModify: false,
    isOpenPeriodicReportModify: false

};

const reportStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RS_ON_CHANGE_SEARCH_OTPION: {
            state.searchOption = _.cloneDeep(action.payload.searchOption);
            return { ...state }
        }
        case types.RS_FETCH_LIST_FORM_STATUS: {
            let searchOption = _.cloneDeep(searchOptionDefault);
            searchOption.startDate = (state.reportType === code.REPORT_TYPE.MONTHLY)
                ? formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate()), '-')
                : formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()), '-');
            state.columns[1].title = (state.reportType === code.REPORT_TYPE.DAILY)
                ? <Translation>{(t) => <> {t('common.column.date.label')} </>}</Translation>
                : <Translation>{(t) => <> {t('common.column.periodic.label')} </>}</Translation>
            state.totalRow = 0;
            state.searchOption = searchOption;
            let { lstStatus } = action.payload;
            lstStatus.unshift({
                detailCode: 0,
                codeName: <Translation>{(t) => <> {t('empGrid.optAll.label')} </>}</Translation>
            })
            state.lstStatus = lstStatus;
            return _.cloneDeep(state);
        }
        case types.RS_FETCH_LIST_DRAFT_FORM_SEARCH_TYPE: {
            let { lstSearchType } = action.payload;
            lstSearchType = _.remove(lstSearchType, (element) => element.detailCode === 'TT');
            state.lstSearchType = lstSearchType;
            return { ...state }
        }
        case types.RS_FETCH_LIST_DAILY_REPORT_STATUS: {
            let { lstReport } = action.payload;
            state.lstReport = lstReport;
            if (lstReport !== null && lstReport.length !== 0) {
                state.totalRow = lstReport[0].totalRow;
                lstReport.forEach(element => {
                    if (state.reportType === code.REPORT_TYPE.DAILY) {
                        element.reportDateYmd = formatStringDate(element.reportDateYmd, '-', true);
                    } else {
                        element.reportStartdateYYYYMMDD = formatStringDate(element.reportStartdateYYYYMMDD, '-', true);
                        element.reportEnddateYYYYMMDD = formatStringDate(element.reportEnddateYYYYMMDD, '-', true);
                        element.reportDateYmd = element.reportStartdateYYYYMMDD + ' ~ ' + element.reportEnddateYYYYMMDD;
                    }
                });
            }
            return { ...state }
        }
        case types.RS_FETCH_DAILY_REPORT_DETAIL: {
            let { dailyReportDetail } = action.payload;
            dailyReportDetail?.lstDailyReportFormSchedule?.forEach(dailyReportFormSchedule =>
                dailyReportFormSchedule?.lstScheduleDetail?.forEach(scheduleDetail => _.remove(scheduleDetail.lstJobPeriod, period => period.periodCode === null))
            );
            state.dailyReportDetail = dailyReportDetail;
            return { ...state }
        }
        case types.RS_FETCH_PERIODIC_REPORT_DETAIL: {
            let { periodicReportDetail } = action.payload;
            if (!periodicReportDetail) return { ...state }
            periodicReportDetail.reportStartdateYYYYMMDD = stringToDate(periodicReportDetail.reportStartdateYYYYMMDD);
            periodicReportDetail.reportEnddateYYYYMMDD = stringToDate(periodicReportDetail.reportEnddateYYYYMMDD);
            periodicReportDetail.planStartdateYYYYMMDD = stringToDate(periodicReportDetail.planStartdateYYYYMMDD);
            periodicReportDetail.planEnddateYYYYMMDD = stringToDate(periodicReportDetail.planEnddateYYYYMMDD);
            periodicReportDetail?.lstWorkPerformance?.forEach(element => {
                element.deadlineYYYYMMDD = stringToDate(element.deadlineYYYYMMDD);
            })
            periodicReportDetail?.lstWorkPlan?.forEach(element => {
                element.deadlineYYYYMMDD = stringToDate(element.deadlineYYYYMMDD);
            })
            state.periodicReportDetail = periodicReportDetail;
            return { ...state }
        }
        case types.RS_TOGGLE_MODIFY_REPORT_DIALOG: {
            let reportType = action.payload.reportType;
            if (!reportType) {
                reportType = state.reportType;
            }
            if (reportType === code.REPORT_TYPE.DAILY) {
                state.isOpenDailyReportModify = !state.isOpenDailyReportModify;
            } else {
                state.isOpenPeriodicReportModify = !state.isOpenPeriodicReportModify;
            }
            return { ...state }
        }
        case types.RS_SET_REPORT_TYPE: {
            state.reportType = action.payload.reportType;
            return { ...state }
        }
        case types.RS_FETCH_PERFORMANCE_TYPE: {
            let { lstPerformanceType } = action.payload;
            let performanceTypeMap = new Map(lstPerformanceType.map(i => [i.detailCode, i.codeName]));
            state.performanceTypeMap = performanceTypeMap;
            return { ...state }
        }
        case types.RS_UPDATE_REPORT_TYPE_FROM_APPROVAL: {
            state.reportType = action.payload.reportType;
            return { ...state }
        }
        case types.RS_INIT_REPORT_DETAIL_PREVIEW: {
            state.dailyReportDetail = {};
            state.periodicReportDetail = {};
            return { ...state }
        }
        default: return { ...state }
    }
}

export default reportStatusReducer;

