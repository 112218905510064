import * as types from "./tableLeftType"

const initialSate = {}
const getDetailLeftReducer = (state = initialSate, action) =>{
    switch(action.type){
        case types.GET_DETAIL_LEFT: 
            return action.detail
        default: return state
    }
}
export default getDetailLeftReducer;