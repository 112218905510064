import apiService from '../../util/apiService'
import { LANGUAGE } from '../../util/codeConstant'
import { getGeneralCodeByCommonCode } from '../../util/commonCodeSevice'
import * as types from "./tableRightType"

export const actGetDataTableRightRequest = (apiURL, params) => {
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res => {
            dispacth(actGetDataTableRight(res.data.listMenu));
        })
    }
}
export const actGetDataTableRightSortRequest = (apiURL, params) => {
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res => {
            let tableData = res.data.listMenu;
            tableData.sort((a, b) => parseInt(a.parentMenuCode) - parseInt(b.parentMenuCode));
            tableData.forEach((row, index) => {
                const id = index > 0 && row.parentMenuCode !== 0 ? row.parentMenuCode : null;
                row.parentMenuCode = id
            });
            dispacth(actGetDataTableRight(tableData));
        })
    }
}
export const actGetDataTableRight = (items) => {
    return {
        type: types.FETCH_TABLE_RIGHT,
        items
    }
}
export const actInsertDataTableRightRequest = (item, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'POST', item).then(res => {
            dispacth(actInsertDataTableRight(res.data));
        })
    }

}
export const actInsertDataTableRight = (item) => {
    return {
        type: types.INSERT_TABLE_RIGHT,
        item
    }
}
export const actUpdateDataTableRightRequest = (item, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, "PUT", item).then(res => {
            dispacth(actUpdateDataTableRight(res.data));
        })
    }
}
export const actUpdateDataTableRight = (item) => {
    return {
        type: types.UPDATE_TABLE_RIGHT,
        item
    }
}
export const actUpdateMenuCodeDataTableRight = (item) => {
    return {
        type: types.UPDATE_MENUCODE_TABLE_RIGHT,
        item
    }
}
export const actDeleteDataTableRightRequest = (id, urlAPI) => {
    return dispacth => {
        apiService(urlAPI, 'DELETE', null).then(res => {
            dispacth(actDeleteDataTableRight(id));
        })
    }
}
export const actDeleteDataTableRight = (id) => {
    return {
        type: types.DELETE_TABLE_RIGHT,
        id
    }
}
export const actGetRowTableRightRequest = (rows, option) => {
    return dispacth => {
        const indexCon = (item) => item[option.nameID] === option.ID;
        let index = rows.findIndex(indexCon);///findRow.findIndexRows(rows, id);
        let row = rows[index];
        dispacth(actGetRowTableRight(row));
    }
}
export const actGetRowTableRight = (row) => {
    return {
        type: types.GET_ROW_TABLE_RIGHT,
        row
    }
}
export const actGetDeTailRightRequest = (apiURL, params) => {
    return dispacth => {
        return apiService(apiURL, "GET", params).then(res => {
            dispacth(actGetDeTailRight(res.data));
        })
    }
}
export const actGetDeTailRight = (detail) => {
    return {
        type: types.GET_DETAIL_RIGHT,
        detail
    }
}
export const actGetLangugeRightRequest = (callback) => {
    return dispacth => {
        return getGeneralCodeByCommonCode(LANGUAGE).then(res => {
            let data = [...res.data.data];
            let lan = [];
            data.forEach(item => {
                const objLan = {
                    localeCode: 0,
                    langCode: item.detailCode,
                    checked: false,
                    codeName: ""
                }
                lan.push(objLan);
            })
            dispacth(actGetLangugeRight(lan));
            if (callback) {
                callback();
            }
        })
    }
}
export const actGetLangugeRight = (lan) => {
    return {
        type: types.GET_LANGUGE_RIGHT,
        lan
    }
}